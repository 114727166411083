import tagService from '../services/tags'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = []

// toolkit sets up the redux and state
const tagSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        setTags(state, action) {
            return action.payload
        },
        appendTag(state, action){
            state.push(action.payload)
        },
        destroyTag(state, action){
            return state.filter(tag => tag.id !== action.payload)
        },
        changeTag(state, action){
            return state.map(tag => tag.id === action.payload.id ? action.payload : tag)
        }
    }
})

export const { setTags, appendTag, destroyTag, changeTag } = tagSlice.actions

export const createTag = (data, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                const tag = await tagService.create(data)
                dispatch(appendTag(tag))
                dispatch(setSuccessNotification('Tag created successfully'))
            } catch {
                dispatch(setErrorNotification('Error creating tag, if persists please notify developer team'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const initializeTags = () => {
    return async dispatch => {
        try {
            const tags = await tagService.retrieve()
            dispatch(setTags(tags))
        } catch {
            dispatch(setErrorNotification('Error retrieving tags'))
        }
    }
}

export const deleteTag = (id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await tagService.destroy(id)
                dispatch(destroyTag(id))
                dispatch(setSuccessNotification('Tag deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting tag'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updateTag = (id, data, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                const tag = await tagService.update(id, data)
                dispatch(changeTag(tag))
                dispatch(setSuccessNotification('Tag updated successfully'))
            } catch {
                dispatch(setErrorNotification('Update of tag failed'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export default tagSlice.reducer