import favouriteService from '../services/favourites'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'
import { refreshBook } from './booksReducer'
import { refreshCourse } from './coursesReducer'
import { refreshPaper } from './papersReducer'
import { refreshPodcast } from './podcastsReducer'
import { refreshVideo } from './videosReducer'
import { refreshResource } from './resourcesReducer'

const initialState = []

// toolkit sets up the redux and state
const favouriteSlice = createSlice({
    name: 'favourites',
    initialState,
    reducers: {
        setFavourites(state, action) {
            return action.payload
        },
        appendFavourite(state, action){
            state.push(action.payload)
        },
        destroyFavourite(state, action){
            return state.filter(favourite => favourite.id !== action.payload)
        },
        changeFavourite(state, action){
            return state.map(favourite => favourite.id === action.payload.id ? action.payload : favourite)
        }
    }
})

export const { setFavourites, appendFavourite, destroyFavourite, changeFavourite } = favouriteSlice.actions

export const createFavourite = (data, type) => {
    return async dispatch => {
        try {
            const fav = await favouriteService.create(data)
            dispatch(appendFavourite(fav))

            if(type === 'book') {
                dispatch(refreshBook(data.object_id))
            } else if (type === 'course') {
                dispatch(refreshCourse(data.object_id))
            } else if (type === 'paper') {
                dispatch(refreshPaper(data.object_id))
            } else if (type === 'podcast') {
                dispatch(refreshPodcast(data.object_id))
            } else if (type === 'video') {
                dispatch(refreshVideo(data.object_id))
            }
            dispatch(refreshResource(data.object_id, type))

            dispatch(setSuccessNotification('Resource favourited!'))
        } catch {
            dispatch(setErrorNotification('Error favouriting resource'))
        }
    }
}

export const initializeFavourites = () => {
    return async dispatch => {
        try {
            const favs = await favouriteService.retrieve()
            dispatch(setFavourites(favs))
        } catch {
            dispatch(setErrorNotification('Error retrieving favourites'))
        }
    }
}

export const deleteFavourite = (id, type, typeId) => {
    return async dispatch => {
        try {
            await favouriteService.destroy(id)
            dispatch(destroyFavourite(id))

            if(type === 'book') {
                dispatch(refreshBook(typeId))
            } else if (type === 'course') {
                dispatch(refreshCourse(typeId))
            } else if (type === 'paper') {
                dispatch(refreshPaper(typeId))
            } else if (type === 'podcast') {
                dispatch(refreshPodcast(typeId))
            } else if (type === 'video') {
                dispatch(refreshVideo(typeId))
            }
            dispatch(refreshResource(typeId, type))

            dispatch(setSuccessNotification('Favourite removed!'))
        } catch {
            dispatch(setErrorNotification('Error deleting favouriting'))
        }

    }
}

export const updateFavourite = (id, data) => {
    return async dispatch => {
        try {
            const fav = await favouriteService.update(id, data)
            dispatch(changeFavourite(fav))
            dispatch(setSuccessNotification('Favourite updated successfully'))
        } catch {
            dispatch(setErrorNotification('Update of favourite failed'))
        }
    }
}

export const refreshFavourite = (id) => {
    return async dispatch => {
        try {
            const fav = await favouriteService.retrieveSingle(id)
            dispatch(changeFavourite(fav))
        } catch {
            dispatch(setErrorNotification('Favourite could not be refreshed'))
        }
    }
}

export default favouriteSlice.reducer