import React, {useState} from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import useTheme from '@mui/material/styles/useTheme'
import LoginIcon from '@mui/icons-material/Login'
import Collapse from '@mui/material/Collapse'

import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import DropDown from './DropDown'
import SearchBar from './SearchBar'
import MobileNav from './MobileNav'
import { PAGES, SETTINGS, RESOURCES } from './routes'
import { logout } from '../../reducers/userReducer'

import { NavBarStyle } from '../styles'

const NavBar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector(state => state.user)
    const theme = useTheme()
    const medium_screen = theme.breakpoints.values.md

    const [resourceDropdown, setResourceDropdown] = useState(false)
    const [menuDropdown, setMenuDropdown] = useState(false)

    // if md screen or larger open the dropdown
    const dropDownOpen = (handler) => {
        if (window.innerWidth < medium_screen) {
            handler(false)
        } else {
            handler(true)
        }
    }

    const dropDownClose = (handler) => handler(false)

    const toggleDropDown = (handler, val) => handler(!val)

    const logoutHandler = () => {
        dispatch(logout())
        setMenuDropdown(false)
        navigate(PAGES.home.path)
    }

    return (
        <AppBar {...NavBarStyle.appbar.props}  sx={NavBarStyle.appbar.style} >
            <Container {...NavBarStyle.navContainer} >
                <Toolbar disableGutters>
                    <Link to={PAGES.home.path} style={NavBarStyle.reactLink} >
                        <Icon sx={NavBarStyle.navLogo}>
                            <img alt='UofT Logo' src='/static/logo.svg' />
                        </Icon>
                    </Link>
                    <Link to={PAGES.home.path} style={NavBarStyle.reactLink} >
                        <Typography {...NavBarStyle.navHeader.props} noWrap sx={NavBarStyle.navHeader.style}>
                            {PAGES.home.desktop_title}
                        </Typography>
                    </Link>

                    <MobileNav logoutHandler={logoutHandler}/>

                    <Box sx={NavBarStyle.linkContainer}>
                        <Link to={PAGES.about.path} style={NavBarStyle.reactLink} >
                            <Button sx={NavBarStyle.link}>
                                {PAGES.about.title}
                            </Button>
                        </Link>
                        <Link to={PAGES.start.path} style={NavBarStyle.reactLink} >
                            <Button sx={NavBarStyle.link}>
                                {PAGES.start.title}
                            </Button>
                        </Link>
                        <div onMouseEnter={() => dropDownOpen(setResourceDropdown)} onMouseLeave={() => dropDownClose(setResourceDropdown)}>
                            <Link to={PAGES.resources.path} style={NavBarStyle.reactLink} >
                                <Button onClick={() => { dropDownClose(setResourceDropdown) }} sx={NavBarStyle.dropDownLink}>
                                    {PAGES.resources.title} <ArrowDropDownIcon />
                                </Button>
                            </Link>
                            <Box sx={NavBarStyle.dropDownBox}>
                                <Collapse in={resourceDropdown}>
                                    <DropDown links={RESOURCES} handler={setResourceDropdown} logout={false} admin={false}/>
                                </Collapse>
                            </Box>
                        </div>
                    {/*  Adding the Request-Resource link if the user is logged in  */}
                    {user && !user.anon && <Link to={PAGES.request.path} style={NavBarStyle.reactLink} >
                        <Button sx={NavBarStyle.link}>
                            {PAGES.request.title}
                        </Button>
                    </Link>}
                    </Box>


                    <SearchBar />

                    {user && !user.anon?
                        <Box onMouseLeave={() => dropDownClose(setMenuDropdown)} sx={NavBarStyle.userBox}>
                            <Tooltip title='Profile Options'>
                                <Button onClick={() => toggleDropDown(setMenuDropdown, menuDropdown)} sx={NavBarStyle.userText}>
                                    {user.name} <ArrowDropDownIcon />
                                </Button>
                            </Tooltip>
                            <Box sx={NavBarStyle.userDropDown}>
                                <Collapse in={menuDropdown}>
                                    <DropDown links={SETTINGS} handler={setMenuDropdown} logout={true} logoutHandler={logoutHandler} admin={user.staff}/>
                                </Collapse>
                            </Box>
                        </Box>:<Link to={PAGES.login.path} style={NavBarStyle.reactLink} >
                            <Button sx={NavBarStyle.userText}>
                                {PAGES.login.title} <LoginIcon />
                            </Button>
                        </Link>
                    }
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default NavBar
