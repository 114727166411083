import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import LaunchIcon from '@mui/icons-material/Launch'

import PodcastsIcon from '@mui/icons-material/Podcasts'
import StarIcon from '@mui/icons-material/Star'

import { PodcastCardStyle } from '../styles'
import PodcastModal from '../modals/PodcastModal'

const PodcastsCard = (props) => {
    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    // renders 2 cards one for sm screens and other for larger screens
    return (
        <>
            <Card sx={PodcastCardStyle.mobile.card.card} variant={PodcastCardStyle.mobile.card.variant}>
                <Box sx={PodcastCardStyle.mobile.icon.box}>
                    <PodcastsIcon color={PodcastCardStyle.mobile.icon.icon.color} sx={PodcastCardStyle.mobile.icon.icon} />
                </Box>
                <Box sx={PodcastCardStyle.mobile.body.container}>
                    <CardContent sx={PodcastCardStyle.mobile.body.meta}>
                        <Box sx={PodcastCardStyle.mobile.body.text}>
                            <Typography {...PodcastCardStyle.mobile.body.title} >
                                {props.title.length > 40? `${props.title.substring(0, 40)}...` : props.title}
                            </Typography>
                            {props.rating?
                                <Typography {...PodcastCardStyle.mobile.body.rating.props} sx={PodcastCardStyle.mobile.body.rating.style} >
                                    {props.rating}/5.0 <StarIcon />
                                </Typography> :
                                <Typography {...PodcastCardStyle.mobile.body.rating.props} sx={PodcastCardStyle.mobile.body.rating.style} >
                                    No Rating
                                </Typography>
                            }
                        </Box>
                        {props.tags.map(tag => <Chip key={tag} label={tag} sx={PodcastCardStyle.mobile.body.badge} />)}
                    </CardContent>
                    <CardActions>
                        <PodcastModal {...props} />
                        <Button {...PodcastCardStyle.mobile.button.props} sx={PodcastCardStyle.mobile.button.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                    </CardActions>
                </Box>
            </Card>
            <Card sx={PodcastCardStyle.desktop.card.card} variant={PodcastCardStyle.desktop.card.variant}>
                <Box sx={PodcastCardStyle.desktop.icon.box}>
                    <PodcastsIcon color={PodcastCardStyle.desktop.icon.icon.color} sx={PodcastCardStyle.desktop.icon.icon} />
                </Box>
                <CardContent sx={PodcastCardStyle.desktop.body.cardContent}>
                    <Box sx={PodcastCardStyle.desktop.body.ratingContainer}>
                        {props.rating?
                            <Typography sx={PodcastCardStyle.desktop.body.rating.style}>
                                {props.rating}/5.0 <StarIcon />
                            </Typography>:
                            <Typography sx={PodcastCardStyle.desktop.body.rating.style}>
                                Not Rated
                            </Typography>
                        }
                    </Box>
                    <Box>
                        <Typography {...PodcastCardStyle.desktop.body.title.props} sx={PodcastCardStyle.desktop.body.title.style}>
                            {props.title.length > 90? `${props.title.substring(0, 90)}...` : props.title}
                        </Typography>
                    </Box>
                    <Typography {...PodcastCardStyle.desktop.body.audience}>
                        Published: {props.pub_date}
                    </Typography>
                    {props.tags.map(tag => <Chip key={tag} label={tag} sx={PodcastCardStyle.desktop.body.badge} />)}
                </CardContent>
                <CardActions sx={PodcastCardStyle.desktop.buttonContainer.style}>
                    <PodcastModal {...props} />
                    <Button {...PodcastCardStyle.desktop.button.props} sx={PodcastCardStyle.desktop.button.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                </CardActions>
            </Card>
        </>
    )
}

export default PodcastsCard