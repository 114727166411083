import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ArticleIcon from '@mui/icons-material/Article'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import Select from 'react-select'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import LaunchIcon from '@mui/icons-material/Launch'

import { AdminPaperModalStyle } from '../styles'
import { setErrorNotification } from '../../reducers/notificationReducer'
import { initializeResources } from '../../reducers/resourcesReducer'
import { deletePaper, updatePaper } from '../../reducers/papersReducer'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const AdminPaperModal = (props) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    // form state (no need to use redux for form state)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [nameHelper, setNameHelper] = useState('')
    const [author, setAuthor] = useState('')
    const [authorError, setAuthorError] = useState(false)
    const [authorHelper, setAuthorHelper] = useState('')
    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState(false)
    const [linkHelper, setLinkHelper] = useState('')
    const [date, setDate] = useState('')
    const [dateError, setDateError] = useState(false)
    const [dateHelper, setDateHelper] = useState('')
    const [doi, setDoi] = useState('')
    const [doiError, setDoiError] = useState(false)
    const [doiHelper, setDoiHelper] = useState('')
    const [formTags, setFormTags] = useState('')
    const [volume, setVolume] = useState('')
    const [journal, setJournal] = useState('')
    const [pages, setPages] = useState('')
    const [issue, setIssue] = useState('')
    const [paperType, setPaperType] = useState('RS')

    // init fields
    useEffect(() => {
        setName(props.title)
        setAuthor(props.author)
        setLink(props.link)
        setDate(props.pub_date)
        setDoi(props.doi)
        setPaperType(props.paper_type)
        if (props.tags && props.tags.length > 0) setFormTags(props.tags.map(tag => { return {label: tag, value: tag} }))

        if(props.volume) setVolume(props.volume)
        if(props.journal) setJournal(props.journal)
        if(props.pages) setPages(props.pages)
        if(props.issue) setIssue(props.issue)
    }, [])

    // vaidate required fields
    const validate = (val, errorHandler, helperHandler) => {
        if (val.length === 0) {
            errorHandler(true)
            helperHandler('Required Field!')
            return false
        } else {
            errorHandler(false)
            helperHandler('')
            return true
        }
    }

    // reset fields to original values if user wants to
    const reset = () => {
        setName(props.title)
        setAuthor(props.author)
        setLink(props.link)
        setDate(props.date)
        setPaperType(props.paper_type)
        if (props.tags) setFormTags(props.tags.map(tag => { return {label: tag, value: tag} }))

        if(props.volume) setVolume(props.volume)
        else setVolume('')

        if(props.journal) setJournal(props.journal)
        else setJournal('')

        if(props.pages) setPages(props.pages)
        else setPages('')

        if(props.issue) setIssue(props.issue)
        else setIssue('')

        setNameError(false)
        setNameHelper('')
        setAuthorError(false)
        setAuthorHelper('')
        setLinkError(false)
        setLinkHelper('')
        setDateError(false)
        setDateHelper('')
        setDoiError(false)
        setDoiHelper('')
    }

    // delete resource
    const handleReject = () => {
        if (window.confirm('Are you sure? Note this resource cannot be retrieved after rejection.')) {
            dispatch(deletePaper(props.id, user.staff))
            handleClose()
            dispatch(initializeResources())
        }
    }

    // if user clicks approve/disapprove all I need to do is toggle approved state and update fields
    const handleSubmit = () => {
        if (validate(name, setNameError, setNameHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper) && validate(doi, setDoiError, setDoiHelper)) {
            if (window.confirm('Are you sure? Note any changes you have made will be included.')) {
                const data = {
                    ...props,
                    title: name,
                    author: author,
                    link: link,
                    pub_date: date,
                    doi: doi,
                    paper_type: paperType,
                    approved: !props.approved
                }

                if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
                else data.tags = []

                if(volume) data.volume = volume
                if(journal) data.journal = journal
                if(pages) data.pages = pages
                if(issue) data.issue = issue

                dispatch(updatePaper(props.id, data, user.staff))
                handleClose()
                dispatch(initializeResources())
            }
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    // do not change approved state just update fields
    const handleSave = () => {
        if (validate(name, setNameError, setNameHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper) && validate(doi, setDoiError, setDoiHelper)) {
            const data = {
                ...props,
                title: name,
                author: author,
                link: link,
                pub_date: date,
                doi: doi,
                paper_type: paperType,
            }

            if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
            else data.tags = []

            if(volume) data.volume = volume
            if(journal) data.journal = journal
            if(pages) data.pages = pages
            if(issue) data.issue = issue

            dispatch(updatePaper(props.id, data, user.staff))
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        dispatch(initializeResources())
        setOpen(false)
    }

    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <div>
            <Button {...AdminPaperModalStyle.viewButton.props} onClick={()=>handleClickOpen()} sx={AdminPaperModalStyle.viewButton.style}>
                <EditIcon /> &nbsp; Edit
            </Button>
            <BootstrapDialog
                onClose={()=>handleClose()}
                aria-labelledby={`paper-modal-${props.id}`}
                open={open}
                fullWidth
                {...AdminPaperModalStyle.modal}
            >
                <BootstrapDialogTitle id={`paper-modal-${props.id}`} onClose={()=>handleClose()}>
                    <Box sx={AdminPaperModalStyle.titleContainer}>
                        <ArticleIcon sx={AdminPaperModalStyle.icon} />
                        {props.title}
                    </Box>
                </BootstrapDialogTitle>
                    <DialogContent dividers sx={AdminPaperModalStyle.content}>
                        <form onSubmit={handleSubmit} id={`paper-form-${props.id}`}>
                            <Typography {...AdminPaperModalStyle.subheading} gutterBottom>
                                Manage Resource
                            </Typography>
                            {props.proposer && <Typography {...AdminPaperModalStyle.proposerText}>
                                Proposed By: {props.proposer.name} ({props.proposer.email})
                            </Typography>}
                            <Grid container spacing={2} sx={{marginTop: '10px'}}>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="paper-type">Paper Type</FormLabel>
                                            <RadioGroup row aria-labelledby="paper-type" name="paper-types" defaultValue={paperType}>
                                                <FormControlLabel value="RS" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Research" />
                                                <FormControlLabel value="SPD" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Software Package Descriptor" />
                                                <FormControlLabel value="RV" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Review" />
                                                <FormControlLabel value="DD" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Dataset Description" />
                                                <FormControlLabel value="COM" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Perspective/Commentary" />
                                                <FormControlLabel value="ED" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Editorial" />
                                                <FormControlLabel value="SYS" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Systematic Review" />
                                                <FormControlLabel value="TUT" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Tutorial" />
                                                <FormControlLabel value="REP" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Report" />
                                            </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        label="Name"
                                        required
                                        error={nameError}
                                        helperText={nameHelper}
                                        onBlur={()=>validate(name, setNameError, setNameHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={author}
                                        onChange={e => setAuthor(e.target.value)}
                                        label="Author"
                                        required
                                        error={authorError}
                                        helperText={authorHelper}
                                        onBlur={()=>validate(author, setAuthorError, setAuthorHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={link}
                                        type='url'
                                        onChange={e => setLink(e.target.value)}
                                        label="Link to Resource"
                                        required
                                        error={linkError}
                                        helperText={linkHelper}
                                        onBlur={()=>validate(link, setLinkError, setLinkHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={date}
                                        type='date'
                                        onChange={e => setDate(e.target.value)}
                                        label="Publish Date"
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        error={dateError}
                                        helperText={dateHelper}
                                        onBlur={()=>validate(date, setDateError, setDateHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        value={doi}
                                        onChange={e => setDoi(e.target.value)}
                                        label="DOI"
                                        required
                                        error={doiError}
                                        helperText={doiHelper}
                                        onBlur={()=>validate(doi, setDoiError, setDoiHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={volume}
                                        onChange={e => setVolume(e.target.value)}
                                        label="Volume"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={issue}
                                        onChange={e => setIssue(e.target.value)}
                                        label="Issue"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={journal}
                                        onChange={e => setJournal(e.target.value)}
                                        label="Journal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={pages}
                                        onChange={e => setPages(e.target.value)}
                                        label="Pages"
                                        placeholder='Suggested Page Range'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={props.optTags}
                                        value={formTags}
                                        onChange={(value) => setFormTags(value)}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                minHeight: '56px'
                                            })
                                        }}
                                        isOptionDisabled={() => formTags.length >= 5}
                                        placeholder='Select Key Words'
                                    />
                                </Grid>
                            </Grid>
                            <Button {...AdminPaperModalStyle.resetButton} type='button' onClick={() => reset()} sx={{marginTop: '20px'}}>
                                Reset Fields
                            </Button>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button type='button' autoFocus onClick={()=>openResource()}>
                            View Resource&nbsp;<LaunchIcon {...AdminPaperModalStyle.launch}/>
                        </Button>
                        <Button type='button' autoFocus onClick={()=>handleSave()}>
                            Save Changes
                        </Button>
                        <Button type='submit' form={`paper-form-${props.id}`} autoFocus>
                            {props.approved? 'Disapprove':'Approve'}
                        </Button>
                        <Button type='button' autoFocus onClick={()=>handleReject()}>
                            {props.approved? 'Delete':'Reject'}
                        </Button>
                    </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default AdminPaperModal