import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

import { SearchBarStyle } from '../styles'
import { PAGES } from './routes'

import { searchBarQuery } from '../../services/utils.js/query'

// Leave Search Bar Style Here
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#CECFCB', 0.15),
    '&:hover': {
        backgroundColor: alpha('#CECFCB', 0.25),
    },
    marginLeft: 0,
    marginRight: 10,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
            width: '25ch',
            '&:focus': {
                width: '30ch',
            },
        },
    },
}))

const SearchBar = () => {
    const [search, setSearch] = useState('')

    // if hit enter key then search
    const handleSubmit = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            const query = searchBarQuery(search)
            setSearch('')
            navigate(`${PAGES.resources.path}?${query}`)
        }
    }

    const navigate = useNavigate()

    return (
        <Search sx={SearchBarStyle.search}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder='Search…'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                inputProps={{ 'aria-label': 'search' }}
                onKeyUp={(e) => handleSubmit(e)}
            />
        </Search>
    )
}

export default SearchBar