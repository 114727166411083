import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Loading from '../loading/Loading'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Button from '@mui/material/Button'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Grid from '@mui/material/Grid'

import { AdminManage } from '../styles'

import { PAGES } from '../navigation/routes'
import { PAGE_SIZE } from '../websiteConfig'

import { setErrorNotification } from '../../reducers/notificationReducer'
import { setUserOnRefresh } from '../../reducers/userReducer'
import { initializeUsers, updateUser } from '../../reducers/usersReducer'

const ManageAdmin = () => {
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState('')

    const users = useSelector(state => state.users)
    const searchUsers = users.filter(user => !user.staff)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const pageSize = PAGE_SIZE
    const pages =  Math.ceil(searchUsers.length/pageSize)
    const [page, setPage] = useState(1)

    // force user update
    useEffect(() => {
        dispatch(setUserOnRefresh())
    }, [])

    // if user is not staff then force them out should not be here
    useEffect(() => {
        if(user) {
            if (user.staff) {
                setTimeout(() => setLoading(false), 500)
                dispatch(initializeUsers())
            } else {
                dispatch(setErrorNotification('You are not authorized to view this page'))
                navigate(PAGES.home.path)
            }
        }
    }, [user])

    // promote user
    const handlePromote = (u) => {
        if(user.admin) {
            if(window.confirm('Are you sure you want to promote this user?')) {
                const data = {
                    ...u,
                    staff: true
                }

                dispatch(updateUser(data))
            }
        } else {
            dispatch(setErrorNotification('You do not have permission to promote users'))
        }
    }

    // demote user
    const handleDemote = (u) => {
        if(user.admin) {
            if(window.confirm('Are you sure you want to demote this user?')) {
                const data = {
                    ...u,
                    staff: false
                }

                dispatch(updateUser(data))
            }
        } else {
            dispatch(setErrorNotification('You do not have permission to demote users'))
        }
    }

    if(loading) {
        return (
            <Container {...AdminManage.loading.props} sx={AdminManage.loading.style}>
                <Loading />
            </Container>
        )
    }

    return (
        <Container sx={AdminManage.container}>
            <Typography {...AdminManage.title} gutterBottom>
                {PAGES.manage_admin.title}
            </Typography>
            <Typography variant='p' gutterBottom>
                Note all staff can view this page but only the superuser can actually promote or demote. If any other staff attempts to promote or demote you will recieve an error.
                Furthermore, please note that if a superuser account needs to be demoted you should speak to the developer team.
            </Typography>
            <Typography {...AdminManage.subheading} sx={AdminManage.subheadingMT} gutterBottom>Current Admin</Typography>
            <TableContainer component={Paper} sx={AdminManage.subheadingMB}>
                <Table sx={AdminManage.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell {...AdminManage.typeAlign}>Email</TableCell>
                            <TableCell {...AdminManage.columnAlign}>Demote</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.filter(user => user.staff).map(u =>
                            <TableRow
                                key={`${u.id}`}
                                sx={AdminManage.tableRow}
                            >
                                <TableCell {...AdminManage.tableCell.props} sx={AdminManage.tableCell.style}>
                                    {u.name}
                                </TableCell>
                                <TableCell {...AdminManage.typeAlign}>{u.email}</TableCell>
                                <TableCell {...AdminManage.columnAlign}>
                                    {!u.admin ? <Button {...AdminManage.reject.props} sx={AdminManage.reject.style} onClick={()=>handleDemote(u)}>
                                        <ArrowDownwardIcon />&nbsp;Demote
                                    </Button>:<Typography variant='p'>Cannot demote superuser</Typography>}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography {...AdminManage.subheading} gutterBottom>Search Users</Typography>
            <Typography {...AdminManage.subheading2} gutterBottom>Filters</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        sx={{marginBottom: '20px'}}
                        fullWidth
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        label="Filter By Name Or Email"
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={AdminManage.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell {...AdminManage.typeAlign}>Email</TableCell>
                            <TableCell {...AdminManage.columnAlign}>Promote</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchUsers.filter(user => (user.name.includes(filter) || user.email.includes(filter))).slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(u => (
                            <TableRow
                                key={`${u.id}`}
                                sx={AdminManage.tableRow}
                            >
                                <TableCell {...AdminManage.tableCell.props} sx={AdminManage.tableCell.style}>
                                    {u.name}
                                </TableCell>
                                <TableCell {...AdminManage.typeAlign}>{u.email}</TableCell>
                                <TableCell {...AdminManage.columnAlign}>
                                    <Button {...AdminManage.approve.props} sx={AdminManage.approve.style} onClick={()=>handlePromote(u)}>
                                        <ArrowUpwardIcon />&nbsp;Promote
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination sx={AdminManage.pagination} count={pages} page={page} onChange={(event, value) => setPage(value)} />
        </Container>
    )
}

export default ManageAdmin