import axios from 'axios'
import tokenService from './token'

const baseUrl = '/api/accounts/'
const userUrl = '/api/accounts/me/'
const adminUrl = '/api/accounts/admins/'
const passwordResetUrl = '/api/password_reset/'
const passwordResetConfirmUrl = '/api/password_reset/confirm/'
const passwordChangeUrl = '/api/accounts/change_password/'
const emailChangeUrl = '/api/accounts/change_email/'
const activateUrl = '/api/accounts/activate/'

const retrieve = async () => {
    const res = await axios.get(baseUrl, tokenService.getAuthHeader())
    return res.data
}

const retrieveSingle = async (id) => {
    const res = await axios.get(`${baseUrl}${id}/`, tokenService.getAuthHeader())
    return res.data
}

const getCurrent = async () => {
    const res = await axios.get(userUrl, tokenService.getAuthHeader())
    return res.data
}

const getAdmins = async () => {
    const res = await axios.get(adminUrl, tokenService.getAuthHeader())
    return res.data
}

const create = async (obj) => {
    const res = await axios
        .post(baseUrl, obj, tokenService.getAuthHeader())
        .catch(error => error.response)
    return {
        data: res.data,
        status: res.status
    }
}

const update = async (id, obj) => {
    const res = await axios.put(`${baseUrl}${id}/`, obj, tokenService.getAuthHeader())
    return res.data
}

const destroy = async (id, password) => {
    const res = await axios.delete(`${baseUrl}${id}/`, {
        headers: {
            ...tokenService.getAuthHeader()
        },
        data: {
            password: password
        }
    })
    return res
}

const changePassword = async (obj) => {
    const res = await axios.put(passwordChangeUrl, obj, tokenService.getAuthHeader()).catch(error => error.response)
    return {
        data: res.data,
        status: res.status
    }
}

const changeEmail = async (obj) => {
    const res = await axios.put(emailChangeUrl, obj, tokenService.getAuthHeader()).catch(error => error.response)
    return {
        data: res.data,
        status: res.status
    }
}

const passwordReset = async (obj) => {
    const res = await axios.post(`${passwordResetUrl}`, obj, tokenService.getAuthHeader())
    return (res.status === 200)
}

const passwordResetConfirm = async (obj) => {
    const res = await axios.post(`${passwordResetConfirmUrl}`, obj, tokenService.getAuthHeader())
    return (res.status === 200)
}

const activateUser = async (id, token) => {
    const res = await axios.post(`${activateUrl}${id}/${token}/`, tokenService.getAuthHeader())
    return (res.status === 200)
}

export default { retrieve, retrieveSingle, getCurrent, getAdmins, create, update, destroy, changePassword, passwordReset, passwordResetConfirm, changeEmail, activateUser }