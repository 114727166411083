import videoService from '../services/videos'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = null

// toolkit sets up the redux and state
const videoSlice = createSlice({
    name: 'videos',
    initialState,
    reducers: {
        setVideos(state, action) {
            return action.payload
        },
        appendVideo(state, action){
            if (state === null) return state
            state.push(action.payload)
        },
        destroyVideo(state, action){
            if (state === null) return state
            return state.filter(video => video.id !== action.payload)
        },
        changeVideo(state, action){
            if (state === null) return state
            return state.map(video => video.id === action.payload.id ? action.payload : video)
        }
    }
})

export const { setVideos, appendVideo, destroyVideo, changeVideo } = videoSlice.actions

export const createVideo = (data, list, admin) => {
    return async dispatch => {
        try {
            const video = await videoService.create(data)
            await videoService.createChildren(list, video.id)
            if(admin) {
                dispatch(appendVideo(video))
                dispatch(setSuccessNotification('Video created successfully'))
            } else {
                dispatch(setSuccessNotification('Your request has been submitted'))
            }
        } catch {
            if(admin) {
                dispatch(setErrorNotification('Error creating video, if persists please notify developer team'))
            } else {
                dispatch(setErrorNotification('Error submitting request, please try again later'))
            }
        }
    }
}

export const initializeVideos = () => {
    return async dispatch => {
        try {
            const videos = await videoService.retrieve()
            dispatch(setVideos(videos))
        } catch {
            dispatch(setErrorNotification('Error retrieving videos'))
        }
    }
}

export const deleteVideo = (id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await videoService.destroy(id)
                dispatch(destroyVideo(id))
                dispatch(setSuccessNotification('Video deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting video'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const deleteVideoChild = (id, child_id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await videoService.destroyChild(child_id)
                const video = await videoService.retrieveSingle(id)
                dispatch(changeVideo(video))
                dispatch(setSuccessNotification('Sub video deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting sub video'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updateVideo = (id, data, videos, toDelete, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await videos.forEach(async video => {
                    if (video.id) {
                        await videoService.updateChild(video.id, video)
                    } else {
                        await videoService.createChildren([video], id)
                    }
                })

                await toDelete.forEach(async video => {
                    await videoService.destroyChild(video.id)
                })

                const video = await videoService.update(id, data)
                dispatch(changeVideo(video))
                dispatch(setSuccessNotification('Video updated successfully'))
            } catch {
                dispatch(setErrorNotification('Update of video failed'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updateVideoChild = (id, child_id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await videoService.updateChild(child_id)
                const video = await videoService.retrieveSingle(id)
                dispatch(changeVideo(video))
                dispatch(setSuccessNotification('Sub video updated sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error updating sub video'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const searchVideos = (query) => {
    return async dispatch => {
        try {
            const videos = await videoService.retrieve(query)
            dispatch(setVideos(videos))
        } catch {
            dispatch(setErrorNotification('Error fulfilling search'))
        }
    }
}

export const refreshVideo = (id) => {
    return async dispatch => {
        try {
            const video = await videoService.retrieveSingle(id)
            dispatch(changeVideo(video))
        } catch { }
    }
}

export default videoSlice.reducer