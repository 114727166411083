import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SchoolIcon from '@mui/icons-material/School'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import Select from 'react-select'
import LaunchIcon from '@mui/icons-material/Launch'

import { AdminCourseModalStyle } from '../styles'
import { setErrorNotification } from '../../reducers/notificationReducer'
import { initializeResources } from '../../reducers/resourcesReducer'
import { deleteCourse, updateCourse } from '../../reducers/coursesReducer'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const AdminCourseModal = (props) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    // form state (no need to use redux for form state)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [nameHelper, setNameHelper] = useState('')
    const [author, setAuthor] = useState('')
    const [authorError, setAuthorError] = useState(false)
    const [authorHelper, setAuthorHelper] = useState('')
    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState(false)
    const [linkHelper, setLinkHelper] = useState('')
    const [date, setDate] = useState('')
    const [dateError, setDateError] = useState(false)
    const [dateHelper, setDateHelper] = useState('')
    const [formTags, setFormTags] = useState('')
    const [courses, setCourses] = useState([])
    const [toDelete, setToDelete] = useState([])
    const [click, setClick] = useState(1)

    // init fields
    useEffect(() => {
        setName(props.title)
        setAuthor(props.author)
        setLink(props.link)
        setDate(props.pub_date)
        if (props.subcourse_set && props.subcourse_set.length > 0) setCourses(props.subcourse_set.map(course => { return {...course}}))
        if (props.tags && props.tags.length > 0) setFormTags(props.tags.map(tag => { return {label: tag, value: tag} }))
    }, [])

    // vaidate required fields
    const validate = (val, errorHandler, helperHandler) => {
        if (val.length === 0) {
            errorHandler(true)
            helperHandler('Required Field!')
            return false
        } else {
            errorHandler(false)
            helperHandler('')
            return true
        }
    }

    // update the dynamic fields (use the index to find the specific child and name to find the value to update)
    const handleFormChange = (index, event) => {
        let data = [...courses]
        data[index][event.target.name] = event.target.value
        setCourses(data)
    }

    // add dynamic fields
    const addFields = () => {
        let newField = {
            title: '',
            pub_date: '',
            link: '',
            author: ''
        }
        setCourses([...courses, newField])
    }

    // delete dynamic fields
    const removeFields = (index) => {
        let data = [...courses]
        let course = data[index]

        if (course.id) {
            setToDelete(toDelete.concat(course))
        }

        data.splice(index, 1)
        setCourses(data)
    }

    // reset fields to original values if user wants to
    const reset = () => {
        setName(props.title)
        setAuthor(props.author)
        setLink(props.link)
        setDate(props.date)
        if (props.tags) setFormTags(props.tags.map(tag => { return {label: tag, value: tag} }))
        if (props.subcourse_set && props.subcourse_set.length > 0) setCourses(props.subcourse_set.map(course => { return {...course}}))
        else setCourses([])

        setToDelete([])
        setNameError(false)
        setNameHelper('')
        setAuthorError(false)
        setAuthorHelper('')
        setLinkError(false)
        setLinkHelper('')
        setDateError(false)
        setDateHelper('')
    }

    // delete resource
    const handleReject = () => {
        if (window.confirm('Are you sure? Note this resource cannot be retrieved after rejection.')) {
            dispatch(deleteCourse(props.id, user.staff))
            handleClose()
            dispatch(initializeResources())
        }
    }

    // if user clicks approve/disapprove all I need to do is toggle approved state and update fields
    const handleSubmit = (event) => {
        event.preventDefault()

        if (validate(name, setNameError, setNameHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper)) {
            if (click === 1) {
                if (window.confirm('Are you sure? Note any changes you have made will be included.')) {
                    const data = {
                        ...props,
                        title: name,
                        author: author,
                        link: link,
                        pub_date: date,
                        approved: !props.approved
                    }
                    if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
                    else data.tags = []

                    dispatch(updateCourse(props.id, data, courses, toDelete, user.staff))
                    handleClose()
                    dispatch(initializeResources())
                }
            } else {
                handleSave()
            }
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    // do not change approved state just update fields
    const handleSave = () => {
        if (validate(name, setNameError, setNameHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper)) {
            const data = {
                ...props,
                title: name,
                author: author,
                link: link,
                pub_date: date,
            }
            if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
            else data.tags = []

            dispatch(updateCourse(props.id, data, courses, toDelete, user.staff))
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        dispatch(initializeResources())
        setOpen(false)
    }

    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <div>
            <Button {...AdminCourseModalStyle.viewButton.props} onClick={()=>handleClickOpen()} sx={AdminCourseModalStyle.viewButton.style}>
                <EditIcon /> &nbsp; Edit
            </Button>
            <BootstrapDialog
                onClose={()=>handleClose()}
                aria-labelledby={`course-modal-${props.id}`}
                open={open}
                fullWidth
                {...AdminCourseModalStyle.modal}
            >
                <BootstrapDialogTitle id={`course-modal-${props.id}`} onClose={()=>handleClose()}>
                    <Box sx={AdminCourseModalStyle.titleContainer}>
                        <SchoolIcon sx={AdminCourseModalStyle.icon} />
                        {props.title}
                    </Box>
                </BootstrapDialogTitle>
                <DialogContent dividers sx={AdminCourseModalStyle.content}>
                    <form onSubmit={handleSubmit} id={`course-form-${props.id}`}>
                        <Typography {...AdminCourseModalStyle.subheading} gutterBottom>
                            Manage Resource
                        </Typography>
                        {props.proposer && <Typography {...AdminCourseModalStyle.proposerText}>
                            Proposed By: {props.proposer.name} ({props.proposer.email})
                        </Typography>}
                        <Grid container spacing={2} sx={AdminCourseModalStyle.formGrid}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    label="Name"
                                    required
                                    error={nameError}
                                    helperText={nameHelper}
                                    onBlur={()=>validate(name, setNameError, setNameHelper)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    value={author}
                                    onChange={e => setAuthor(e.target.value)}
                                    label="Author"
                                    required
                                    error={authorError}
                                    helperText={authorHelper}
                                    onBlur={()=>validate(author, setAuthorError, setAuthorHelper)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    value={link}
                                    type='url'
                                    onChange={e => setLink(e.target.value)}
                                    label="Link to Resource"
                                    required
                                    error={linkError}
                                    helperText={linkHelper}
                                    onBlur={()=>validate(link, setLinkError, setLinkHelper)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    value={date}
                                    type='date'
                                    onChange={e => setDate(e.target.value)}
                                    label="Publish Date"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    error={dateError}
                                    helperText={dateHelper}
                                    onBlur={()=>validate(date, setDateError, setDateHelper)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={props.optTags}
                                    value={formTags}
                                    onChange={(value) => setFormTags(value)}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            minHeight: '56px'
                                        })
                                    }}
                                    isOptionDisabled={() => formTags.length >= 5}
                                    placeholder='Select Key Words'
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx ={AdminCourseModalStyle.subFormHeading} spacing={2}>
                            <Grid item xs={7} sm={9}>
                                <Typography {...AdminCourseModalStyle.subFormHeadingText} gutterBottom>
                                    Lessons
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sm={3}>
                                <Button {...AdminCourseModalStyle.add.props} sx={AdminCourseModalStyle.add.style} onClick={()=>addFields()}>
                                    Add Lesson&nbsp;&nbsp;&nbsp;<AddCircleIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        {courses.map((course, index) => (
                            <Grid item key={index} xs={12} sx={AdminCourseModalStyle.subFormGrid}>
                                <Grid container spacing={2}>
                                    <Grid item xs={7} sm={9}>
                                        <Typography {...AdminCourseModalStyle.subFormHeadingText} gutterBottom>
                                            Lesson {index+1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={3}>
                                        <Button sx={AdminCourseModalStyle.deleteButton.style} {...AdminCourseModalStyle.deleteButton.props} onClick={()=>removeFields(index)}>
                                            Remove Lesson&nbsp;&nbsp;&nbsp;<DeleteIcon />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            name='title'
                                            required
                                            value={course.title}
                                            onChange={(event)=>handleFormChange(index, event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Author"
                                            name='author'
                                            required
                                            value={course.author}
                                            onChange={(event)=>handleFormChange(index, event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Link"
                                            name='link'
                                            required
                                            type='url'
                                            value={course.link}
                                            onChange={(event)=>handleFormChange(index, event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Publish Date"
                                            name='pub_date'
                                            required
                                            type='date'
                                            InputLabelProps={{ shrink: true }}
                                            value={course.pub_date}
                                            onChange={(event)=>handleFormChange(index, event)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Button {...AdminCourseModalStyle.resetButton} type='button' onClick={() => reset()} sx={{marginTop: '20px'}}>
                            Reset Fields
                        </Button>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button type='button' autoFocus onClick={()=>openResource()}>
                        View Resource&nbsp;<LaunchIcon {...AdminCourseModalStyle.launch}/>
                    </Button>
                    <Button type='submit' form={`course-form-${props.id}`} autoFocus onClick={()=>setClick(2)}>
                        Save Changes
                    </Button>
                    <Button type='submit' form={`course-form-${props.id}`} autoFocus onClick={()=>setClick(1)}>
                        {props.approved? 'Disapprove':'Approve'}
                    </Button>
                    <Button type='button' autoFocus onClick={()=>handleReject()}>
                        {props.approved? 'Delete':'Reject'}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default AdminCourseModal