import courseService from '../services/courses'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = null

// toolkit sets up the redux and state
const courseSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setCourses(state, action) {
            return action.payload
        },
        appendCourse(state, action){
            if (state === null) return state
            state.push(action.payload)
        },
        destroyCourse(state, action){
            if (state === null) return state
            return state.filter(course => course.id !== action.payload)
        },
        changeCourse(state, action){
            if (state === null) return state
            return state.map(course => course.id === action.payload.id ? action.payload : course)
        }
    }
})

export const { setCourses, appendCourse, destroyCourse, changeCourse } = courseSlice.actions

export const createCourse = (data, list, admin) => {
    return async dispatch => {
        try {
            const course = await courseService.create(data)
            await courseService.createChildren(list, course.id)
            if(admin) {
                dispatch(appendCourse(course))
                dispatch(setSuccessNotification('Course created successfully'))
            } else {
                dispatch(setSuccessNotification('Your request has been submitted'))
            }
        } catch {
            if(admin) {
                dispatch(setErrorNotification('Error creating course, if persists please notify developer team'))
            } else {
                dispatch(setErrorNotification('Error submitting request, please try again later'))
            }
        }
    }
}

export const initializeCourses = () => {
    return async dispatch => {
        try {
            const courses = await courseService.retrieve()
            dispatch(setCourses(courses))
        } catch {
            dispatch(setErrorNotification('Error retrieving courses'))
        }
    }
}

export const deleteCourse = (id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await courseService.destroy(id)
                dispatch(destroyCourse(id))
                dispatch(setSuccessNotification('Course deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting course'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const deleteCourseChild = (id, child_id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await courseService.destroyChild(child_id)
                const course = await courseService.retrieveSingle(id)
                dispatch(changeCourse(course))
                dispatch(setSuccessNotification('Sub course deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting sub course'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updateCourse = (id, data, courses, toDelete, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await courses.forEach(async course => {
                    if (course.id) {
                        await courseService.updateChild(course.id, course)
                    } else {
                        await courseService.createChildren([course], id)
                    }
                })

                await toDelete.forEach(async course => {
                    await courseService.destroyChild(course.id)
                })

                const course = await courseService.update(id, data)
                dispatch(changeCourse(course))
                dispatch(setSuccessNotification('Course updated successfully'))
            } catch {
                dispatch(setErrorNotification('Update of course failed'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updateCourseChild = (id, child_id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await courseService.updateChild(child_id)
                const course = await courseService.retrieveSingle(id)
                dispatch(changeCourse(course))
                dispatch(setSuccessNotification('Sub course updated sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error updating sub course'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const searchCourses = (query) => {
    return async dispatch => {
        try {
            const courses = await courseService.retrieve(query)
            dispatch(setCourses(courses))
        } catch {
            dispatch(setErrorNotification('Error fulfilling search'))
        }
    }
}

export const refreshCourse = (id) => {
    return async dispatch => {
        try {
            const course = await courseService.retrieveSingle(id)
            dispatch(changeCourse(course))
        } catch { }
    }
}

export default courseSlice.reducer