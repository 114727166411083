import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import PolicyIcon from '@mui/icons-material/Policy'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import Select from 'react-select'
import LaunchIcon from '@mui/icons-material/Launch'

import { AdminPolicyModalStyle } from '../styles'
import { setErrorNotification } from '../../reducers/notificationReducer'
import { initializeResources } from '../../reducers/resourcesReducer'
import { deletePolicy, updatePolicy } from '../../reducers/policiesReducer'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const AdminPolicyModal = (props) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    // form state (no need to use redux for form state)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [nameHelper, setNameHelper] = useState('')

    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState(false)
    const [titleHelper, setTitleHelper] = useState('')

    const [country, setCountry] = useState('')
    const [countryError, setCountryError] = useState(false)
    const [countryHelper, setCountryHelper] = useState('')

    const [institution, setInstitution] = useState('')
    const [institutionError, setInstitutionError] = useState(false)
    const [institutionHelper, setInstitutionHelper] = useState('')

    const [created, setCreated] = useState('')
    const [createdError, setCreatedError] = useState(false)
    const [createdHelper, setCreatedHelper] = useState('')

    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState(false)
    const [linkHelper, setLinkHelper] = useState('')

    const [approved, setApproved] = useState('')
    const [approvedError, setApprovedError] = useState(false)
    const [approvedHelper, setApprovedHelper] = useState('')

    const [updated, setUpdated] = useState('')
    const [updatedError, setUpdatedError] = useState(false)
    const [updatedHelper, setUpdatedHelper] = useState('')

    const [enacted, setEnacted] = useState('')
    const [enactedError, setEnactedError] = useState(false)
    const [enactedHelper, setEnactedHelper] = useState('')

    const [published, setPublished] = useState('')
    const [publishedError, setPublishedError] = useState(false)
    const [publishedHelper, setPublishedHelper] = useState('')

    const [formTags, setFormTags] = useState('')


    // init fields
    useEffect(() => {
        setTitle(props.title)
        setCountry(props.country)
        setInstitution(props.institution)
        setCreated(props.created)
        setLink(props.link)
        setApproved(props.approved)
        setUpdated(props.updated)
        setEnacted(props.enacted)
        setPublished(props.published)
        if (props.tags && props.tags.length > 0) setFormTags(props.tags.map(tag => { return {label: tag, value: tag} }))
    }, [])

    // vaidate required fields
    const validate = (val, errorHandler, helperHandler) => {
        if (val.length === 0) {
            errorHandler(true)
            helperHandler('Required Field!')
            return false
        } else {
            errorHandler(false)
            helperHandler('')
            return true
        }
    }

    // reset fields to original values if user wants to
    const reset = () => {
        setTitle(props.title)
        setCountry(props.country)
        setInstitution(props.institution)
        setCreated(props.created)
        setLink(props.link)
        setApproved(props.approved)
        setUpdated(props.updated)
        setEnacted(props.enacted)
        setPublished(props.published)
        if (props.tags) setFormTags(props.tags.map(tag => { return {label: tag, value: tag} }))

        setTitleError(false)
        setTitleHelper('')
        setCountryError(false)
        setCountryHelper('')
        setInstitutionError(false)
        setInstitutionHelper('')
        setCreatedError(false)
        setCreatedHelper('')
        setLinkError(false)
        setLinkHelper('')
        setApprovedError(false)
        setApprovedHelper('')
        setUpdatedError(false)
        setUpdatedHelper('')
        setEnactedError(false)
        setEnactedHelper('')
        setPublishedError(false)
        setPublishedHelper('')
    }

    // delete resource
    const handleReject = () => {
        if (window.confirm('Are you sure? Note this resource cannot be retrieved after rejection.')) {
            dispatch(deletePolicy(props.id, user.staff))
            handleClose()
            dispatch(initializeResources())
        }
    }

    // if user clicks approve/disapprove all I need to do is toggle approved state and update fields
    const handleSubmit = () => {
        if (validate(title, setTitleError, setTitleHelper) && validate(country, setCountryError, setCountryHelper) && validate(institution, setInstitutionError, setInstitutionHelper) && validate(created, setCreatedError, setCreatedHelper) && validate(link, setLinkError, setLinkHelper) && validate(approved, setApprovedError, setApprovedHelper) && validate(updated, setUpdatedError, setUpdatedHelper) && validate(enacted, setEnactedError, setEnactedHelper) && validate(published, setPublishedError, setPublishedHelper)) {
            if (window.confirm('Are you sure? Note any changes you have made will be included.')) {
                const data = {
                    ...props,
                    title: title,
                    country: country,
                    institution: institution,
                    created: created,
                    link: link,
                    updated: updated,
                    enacted: enacted,
                    published: published,
                    approved: !props.approved
                }
                if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
                else data.tags = []

                dispatch(updatePolicy(props.id, data, user.staff))
                handleClose()
                dispatch(initializeResources())
            }
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    // do not change approved state just update fields
    const handleSave = () => {
        if (validate(title, setTitleError, setTitleHelper) && validate(country, setCountryError, setCountryHelper) && validate(institution, setInstitutionError, setInstitutionHelper) && validate(created, setCreatedError, setCreatedHelper) && validate(link, setLinkError, setLinkHelper) && validate(approved, setApprovedError, setApprovedHelper) && validate(updated, setUpdatedError, setUpdatedHelper) && validate(enacted, setEnactedError, setEnactedHelper) && validate(published, setPublishedError, setPublishedHelper)) {
            const data = {
                ...props,
                title: title,
                country: country,
                institution: institution,
                created: created,
                link: link,
                updated: updated,
                enacted: enacted,
            }
            if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
            else data.tags = []

            dispatch(updatePolicy(props.id, data, user.staff))
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        dispatch(initializeResources())
        setOpen(false)
    }

    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <div>
            <Button {...AdminPolicyModalStyle.viewButton.props} onClick={()=>handleClickOpen()} sx={AdminPolicyModalStyle.viewButton.style}>
                <EditIcon /> &nbsp; Edit
            </Button>
            <BootstrapDialog
                onClose={()=>handleClose()}
                aria-labelledby={`policy-modal-${props.id}`}
                open={open}
                fullWidth
                {...AdminPolicyModalStyle.modal}
            >
                <BootstrapDialogTitle id={`policy-modal-${props.id}`} onClose={()=>handleClose()}>
                    <Box sx={AdminPolicyModalStyle.titleContainer}>
                        <PolicyIcon sx={AdminPolicyModalStyle.icon} />
                        {props.title}
                    </Box>
                </BootstrapDialogTitle>
                    <DialogContent dividers sx={AdminPolicyModalStyle.content}>
                        <form onSubmit={handleSubmit} id={`policy-form-${props.id}`}>
                            <Typography {...AdminPolicyModalStyle.subheading} gutterBottom>
                                Manage Resource
                            </Typography>
                            {props.proposer && <Typography {...AdminPolicyModalStyle.proposerText}>
                                Proposed By: {props.proposer.name} ({props.proposer.email})
                            </Typography>}

                            <Grid container spacing={2} sx={AdminPolicyModalStyle.grid}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={title}
                                        onChange={(e)=>setTitle(e.target.value)}
                                        label='Title'
                                        required
                                        error={titleError}
                                        helperText={titleHelper}
                                        onBlur={()=>validate(title, setTitleError, setTitleHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={country}
                                        onChange={(e)=>setCountry(e.target.value)}
                                        label='Country'
                                        required
                                        error={countryError}
                                        helperText={countryHelper}
                                        onBlur={()=>validate(country, setCountryError, setCountryHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={institution}
                                        onChange={(e)=>setInstitution(e.target.value)}
                                        label='Institution'
                                        required
                                        error={institutionError}
                                        helperText={institutionHelper}
                                        onBlur={()=>validate(institution, setInstitutionError, setInstitutionHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={created}
                                        onChange={(e)=>setCreated(e.target.value)}
                                        label='Date Created'
                                        required
                                        error={createdError}
                                        helperText={createdHelper}
                                        onBlur={()=>validate(created, setCreatedError, setCreatedHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={link}
                                        onChange={(e)=>setLink(e.target.value)}
                                        label='Link'
                                        required
                                        error={linkError}
                                        helperText={linkHelper}
                                        onBlur={()=>validate(link, setLinkError, setLinkHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={updated}
                                        onChange={(e)=>setUpdated(e.target.value)}
                                        label='Date Updated'
                                        required
                                        error={updatedError}
                                        helperText={updatedHelper}
                                        onBlur={()=>validate(updated, setUpdatedError, setUpdatedHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={enacted}
                                        onChange={(e)=>setEnacted(e.target.value)}
                                        label='Date Enacted'
                                        required
                                        error={enactedError}
                                        helperText={enactedHelper}
                                        onBlur={()=>validate(enacted, setEnactedError, setEnactedHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={published}
                                        onChange={(e)=>setPublished(e.target.value)}
                                        label='Date Published'
                                        required
                                        error={publishedError}
                                        helperText={publishedHelper}
                                        onBlur={()=>validate(published, setPublishedError, setPublishedHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={props.optTags}
                                        value={formTags}
                                        onChange={(tags)=>setFormTags(tags)}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                minHeight: '56px'
                                            })
                                        }}
                                        isOptionDisabled={() => formTags.length >= 5}
                                        placeholder="Select up to 5 tags"
                                    />
                                </Grid>
                            </Grid>
                            <Button {...AdminPolicyModalStyle.resetButton} type='button' onClick={() => reset()} sx={{marginTop: '20px'}}>
                                Reset Fields
                            </Button>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button type='button' autoFocus onClick={()=>openResource()}>
                            View Resource&nbsp;<LaunchIcon {...AdminPolicyModalStyle.launch}/>
                        </Button>
                        <Button type='button' autoFocus onClick={()=>handleSave()}>
                            Save Changes
                        </Button>
                        <Button type='submit' form={`book-form-${props.id}`} autoFocus>
                            {props.approved? 'Disapprove':'Approve'}
                        </Button>
                        <Button type='button' autoFocus onClick={()=>handleReject()}>
                            {props.approved? 'Delete':'Reject'}
                        </Button>
                    </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default AdminPolicyModal