import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Select from 'react-select'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import { RequestFormStyle } from '../styles'
import { setErrorNotification, setSuccessNotification } from '../../reducers/notificationReducer'
import { createBook } from '../../reducers/booksReducer'

import resourceService from '../../services/resources'

const BookForm = ({tags, user}) => {
    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [nameHelper, setNameHelper] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState(false)
    const [descriptionHelper, setDescriptionHelper] = useState('')
    const [bookType, setBookType] = useState('TB')
    const [author, setAuthor] = useState('')
    const [authorError, setAuthorError] = useState(false)
    const [authorHelper, setAuthorHelper] = useState('')
    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState(false)
    const [linkHelper, setLinkHelper] = useState('')
    const [date, setDate] = useState('')
    const [dateError, setDateError] = useState(false)
    const [dateHelper, setDateHelper] = useState('')
    const [formTags, setFormTags] = useState('')
    const [isbn, setIsbn] = useState('')
    const [isbnError, setIsbnError] = useState(false)
    const [isbnHelper, setIsbnHelper] = useState('')
    const [isbnBook, setIsbnBook] = useState('')
    const [isbnBookError, setIsbnBookError] = useState(false)
    const [isbnBookHelper, setIsbnBookHelper] = useState('')

    const validate = (val, errorHandler, helperHandler) => {
        if (val.length === 0) {
            errorHandler(true)
            helperHandler('Required Field!')
            return false
        } else {
            errorHandler(false)
            helperHandler('')
            return true
        }
    }

    const reset = () => {
        setName('')
        setNameError(false)
        setNameHelper('')
        setDescription('')
        setDescriptionError(false)
        setDescriptionHelper('')
        setAuthor('')
        setAuthorError(false)
        setAuthorHelper('')
        setLink('')
        setLinkError(false)
        setLinkHelper('')
        setDate('')
        setDateError(false)
        setDateHelper('')
        setFormTags('')
        setBookType('TB')
        setIsbnBook('')
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (validate(name, setNameError, setNameHelper) && validate(description, descriptionError, descriptionHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper) && validate(isbnBook, setIsbnBookError, setIsbnBookHelper)) {
            const data = {
                title: name,
                description: description,
                author: author,
                link: link,
                pub_date: date,
                isbn: isbnBook,
                book_type: bookType
            }

            if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
            else data.tags = []

            dispatch(createBook(data, user.staff))
            reset()
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    const handleSearch = async () => {
        if (isbn === '') {
            setIsbnError(true)
            setIsbnHelper('Please fill this field first!')
            return
        }
        setIsbnError(false)
        setIsbnHelper('')

        try {
            const res = await resourceService.retrieveBook(isbn)
            setName(res.volumeInfo.title)
            setDescription(res.volumeInfo.description)
            setAuthor(res.volumeInfo.authors.join(', '))
            setDate(res.volumeInfo.publishedDate)
            setLink(res.volumeInfo.infoLink)
            setIsbnBook(isbn)
            dispatch(setSuccessNotification('Book Found'))
        } catch {
            dispatch(setErrorNotification('A book could not be found with that ISBN'))
        }

        setIsbn('')
    }

    return (
        <>
            <Container {...RequestFormStyle.container}>
                <Typography {...RequestFormStyle.title.props} gutterBottom sx={RequestFormStyle.title.style}>
                    Book Form
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="book-type">Book Type</FormLabel>
                                    <RadioGroup row aria-labelledby="book-type" name="book-types" defaultValue={"TB"}>
                                        <FormControlLabel value="TB" control={<Radio onClick={(e) => setBookType(e.target.value)} />} label="Textbook" />
                                        <FormControlLabel value="OT" control={<Radio onClick={(e) => setBookType(e.target.value)} />} label="Other" />
                                    </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={name}
                                onChange={e => setName(e.target.value)}
                                label="Name"
                                required
                                error={nameError}
                                helperText={nameHelper}
                                onBlur={()=>validate(name, setNameError, setNameHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={author}
                                onChange={e => setAuthor(e.target.value)}
                                label="Author(s)"
                                required
                                error={authorError}
                                helperText={authorHelper}
                                onBlur={()=>validate(author, setAuthorError, setAuthorHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={link}
                                type='url'
                                onChange={e => setLink(e.target.value)}
                                label="Link to Resource"
                                required
                                error={linkError}
                                helperText={linkHelper}
                                onBlur={()=>validate(link, setLinkError, setLinkHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={date}
                                type='date'
                                onChange={e => setDate(e.target.value)}
                                label="Publish Date"
                                required
                                InputLabelProps={{ shrink: true }}
                                error={dateError}
                                helperText={dateHelper}
                                onBlur={()=>validate(date, setDateError, setDateHelper)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                label="Description"
                                required
                                error={descriptionError}
                                helperText={descriptionHelper}
                                onBlur={()=>validate(description, setDescriptionError, setDescriptionHelper)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={isbnBook}
                                onChange={e => setIsbnBook(e.target.value)}
                                label="Book ISBN"
                                required
                                error={isbnBookError}
                                helperText={isbnBookHelper}
                                onBlur={()=>validate(isbnBook, setIsbnBookError, setIsbnBookHelper)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={tags}
                                value={formTags}
                                onChange={(value) => setFormTags(value)}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        minHeight: '56px'
                                    })
                                }}
                                isOptionDisabled={() => formTags.length >= 5}
                                placeholder='Select Key Words'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Button sx={RequestFormStyle.button.style} {...RequestFormStyle.button.props} type='submit'>{user.staff? 'Create Book' : 'Request Book'}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
            <Box sx={RequestFormStyle.searchContainer}>
                <Box sx={RequestFormStyle.searchTextContainer}>
                    <Typography {...RequestFormStyle.searchText} gutterBottom>
                        Need help filling fields? You can fill fields automatically by searching with the book ISBN below.
                        Note you will still need to fill the key words.
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={isbn}
                            onChange={e => setIsbn(e.target.value)}
                            label="ISBN"
                            helperText={isbnHelper}
                            error={isbnError}
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Button {...RequestFormStyle.searchButton.props} sx={RequestFormStyle.searchButton.style} fullWidth onClick={()=>handleSearch()}>
                            Search ISBN
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default BookForm