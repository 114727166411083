import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LaunchIcon from '@mui/icons-material/Launch'
import ArticleIcon from '@mui/icons-material/Article'
import StarIcon from '@mui/icons-material/Star'

import { PaperListStyle } from '../styles'
import PaperModal from '../modals/PaperModal'

const PaperList = (props) => {
    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <>
            <Box sx={PaperListStyle.topContainer}>
                <Box sx={PaperListStyle.topContainerIconContainer}>
                    <ArticleIcon sx={PaperListStyle.icon} />
                    <Typography {...PaperListStyle.text} gutterBottom>
                        &nbsp;Published: {props.pub_date}
                    </Typography>
                </Box>
                {props.rating?
                    <Typography {...PaperListStyle.text}>
                        {props.rating}/5.0 <StarIcon />
                    </Typography> :
                    <Typography {...PaperListStyle.text}>
                        No Rating Yet
                    </Typography>
                }
            </Box>
            <Box>
                <Box sx={PaperListStyle.titleContainer}>
                    <Typography {...PaperListStyle.title} gutterBottom>
                        {props.title}
                    </Typography>
                </Box>
                {props.tags.map(tag => <Chip sx={PaperListStyle.chips} key={tag} label={tag} />)}
                <Box sx={PaperListStyle.secondary}>
                    <Typography variant='p'>
                        Author(s): {props.author}
                    </Typography>
                </Box>
                <Box sx={PaperListStyle.secondary}>
                    <Typography variant='p'>
                        Category: {props.paper_type_name}
                    </Typography>
                </Box>
                <Box sx={PaperListStyle.secondary}>
                    <Typography variant='p'>
                        DOI: {props.doi}
                    </Typography>
                </Box>
                <Box sx={PaperListStyle.actions}>
                    <PaperModal {...props} />
                    <Button {...PaperListStyle.view.props} sx={PaperListStyle.view.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                </Box>
            </Box>
            <Divider />
        </>
    )
}

export default PaperList