import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Loading from '../loading/Loading'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { ProfileStyle } from '../styles'
import { updateUser, changeEmail, changeUserPassword, deleteUser } from '../../reducers/userReducer'
import { setErrorNotification } from '../../reducers/notificationReducer'
import { countryList } from './utils/utils'
import { PAGES } from '../navigation/routes'

const Profile = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [nameHelper, setNameHelper] = useState('')
    const [nameError, setNameError] = useState(false)
    const [institution, setInstitution] = useState('')
    const [country, setCountry] = useState('')
    const [email, setEmail] = useState('')
    const [emailHelper, setEmailHelper] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [passwordHelper, setPasswordHelper] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [changePassword, setChangePassword] = useState('')
    const [changePasswordError, setChangePasswordError] = useState(false)
    const [changePasswordHelper, setChangePasswordHelper] = useState('')
    const [changePasswordVisible, setChangePasswordVisible] = useState(false)
    const [confirmChangePassword, setConfirmChangePassword] = useState('')
    const [confirmChangePasswordError, setConfirmChangePasswordError] = useState(false)
    const [confirmChangePasswordHelper, setConfirmChangePasswordHelper] = useState('')
    const [confirmChangePasswordVisible, setConfirmChangePasswordVisible] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [oldPasswordError, setOldPasswordError] = useState(false)
    const [oldPasswordHelper, setOldPasswordHelper] = useState('')
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
    const [deletePassword, setDeletePassword] = useState('')
    const [deletePasswordError, setDeletePasswordError] = useState(false)
    const [deletePasswordHelper, setDeletePasswordHelper] = useState('')
    const [deletePasswordVisible, setDeletePasswordVisible] = useState(false)

    useEffect(() => {
        if(user) {
            if (user.anon) {
                dispatch(setErrorNotification('You must be logged in to view this page'))
                navigate(PAGES.home.path)
            }
            setName(user.name)
            if(user.country) setCountry(user.country)
            if(user.institution) setInstitution(user.institution)
            setEmail(user.email)
        }
    }, [user])

    const validateConfirmChangePassword = () => {
        if((changePassword !== confirmChangePassword && confirmChangePassword.length > 0) || (changePassword.length > 0 && changePassword !== confirmChangePassword)) {
            setConfirmChangePasswordHelper('Passwords Do Not Match!')
            setConfirmChangePasswordError(true)
            return false
        } else {
            setConfirmChangePasswordHelper('')
            setConfirmChangePasswordError(false)
            return true
        }
    }

    const validateChangePassword = () => {
        validateConfirmChangePassword()
        if(changePassword === '') {
            setChangePasswordHelper('Required Field!')
            setChangePasswordError(true)
            return false
        } else if(changePassword.length < 8) {
            setChangePasswordHelper('Minimum Length 8!')
            setChangePasswordError(true)
            return false
        } else {
            setChangePasswordHelper('')
            setChangePasswordError(false)
            return true
        }
    }

    const validateOldPassword = () => {
        if(oldPassword === '') {
            setOldPasswordHelper('Required Field!')
            setOldPasswordError(true)
            return false
        } else {
            setOldPasswordHelper('')
            setOldPasswordError(false)
            return true
        }
    }

    const validatePassword = () => {
        if(password === '') {
            setPasswordHelper('Required Field!')
            setPasswordError(true)
            return false
        } else {
            setPasswordHelper('')
            setPasswordError(false)
            return true
        }
    }

    const validateName = () => {
        if(name === '') {
            setNameHelper('Required Field!')
            setNameError(true)
            return false
        } else {
            setNameHelper('')
            setNameError(false)
            return true
        }
    }

    const validateEmail = () => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(email === '') {
            setEmailHelper('Required Field!')
            setEmailError(true)
            return false
        } else if(!email.match(re)) {
            setEmailHelper('Invalid Email Address')
            setEmailError(true)
            return false
        } else {
            setEmailHelper('')
            setEmailError(false)
            return true
        }
    }

    const validateDeletePassword = () => {
        if(deletePassword === '') {
            setDeletePasswordHelper('Required Field!')
            setDeletePasswordError(true)
            return false
        } else {
            setDeletePasswordHelper('')
            setDeletePasswordError(false)
            return true
        }
    }

    const handleProfileChange = (event) => {
        event.preventDefault()
        if(validateName()) {
            dispatch(updateUser({
                ...user,
                name: name,
                institution: institution,
                country: country
            }))
        } else {
            dispatch(setErrorNotification('Please fix all errors before submitting'))
        }
    }

    const handlePasswordChange = (event) => {
        event.preventDefault()
        if(validateOldPassword() && validateChangePassword() && validateConfirmChangePassword()) {
            dispatch(changeUserPassword({
                old_password: oldPassword,
                new_password: changePassword
            }))
            setOldPassword('')
            setOldPasswordVisible(false)
            setChangePassword('')
            setChangePasswordVisible(false)
            setConfirmChangePassword('')
            setConfirmChangePasswordVisible(false)
        } else {
            dispatch(setErrorNotification('Please fix all errors before submitting'))
        }
    }

    const handleEmailChange = (event) => {
        event.preventDefault()
        if(validateEmail() && validatePassword()) {
            dispatch(changeEmail({
                password: password,
                email: email
            }))
            setPassword('')
            setPasswordVisible(false)
        } else {
            dispatch(setErrorNotification('Please fix all errors before submitting'))
        }
    }

    const handleDelete = (event) => {
        event.preventDefault()
        if(window.confirm('Are you sure? You will not be able to recover this account')) {
            dispatch(deleteUser(user.id, deletePassword))
        } else {
            dispatch(setErrorNotification('Please fix all errors before submitting'))
        }
    }

    return (
        <Container {...ProfileStyle.container.props} sx={ProfileStyle.container.style}>
            {user? <>
            <Box>
                <Typography {...ProfileStyle.title} gutterBottom>
                    Your Profile
                </Typography>
                <Box sx={ProfileStyle.para}>
                    <Typography {...ProfileStyle.paraText} gutterBottom>
                        Edit your profile below. We greatly appreciate users who are able to provide their country and institution.
                        At T-CAIREM, we greatly respect our users' privacy, and guarantee we do our best to ensure
                        user data will never be exposed. Information you provide is strictly used for our own analytical purposes.
                    </Typography>
                </Box>
                <form onSubmit={handleProfileChange}>
                    <Grid container spacing={2} sx={{marginBottom: '10px'}}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={name}
                                variant='outlined'
                                label="Name"
                                onBlur={() => validateName()}
                                onChange={(e) => setName(e.target.value)}
                                helperText={nameHelper}
                                error={nameError}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={institution}
                                variant='outlined'
                                helperText='Your Primary Organization'
                                label="Institution"
                                onChange={(e) => setInstitution(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={country}
                                select
                                label="Country"
                                fullWidth
                                onChange={(e)=>setCountry(e.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {countryList.map(country => <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                {...ProfileStyle.button}
                                type='submit'
                            >
                                Save Changes
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {(window.localStorage.getItem('TCAIREMGoogle') === null) && <>
            <Box>
                <Typography {...ProfileStyle.subheading} gutterBottom>
                    Change Your Email
                </Typography>
                <Box sx={ProfileStyle.para}>
                    <Typography {...ProfileStyle.paraText} gutterBottom>
                        To change your email please submit the form below, a notification email will be sent to your new email upon success.
                        Please enter your password for verification purposes.
                    </Typography>
                </Box>
                <form onSubmit={handleEmailChange}>
                    <Grid container spacing={2} sx={{marginBottom: '10px'}}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={email}
                                variant='outlined'
                                label="Email"
                                onBlur={() => validateEmail()}
                                onChange={(e) => setEmail(e.target.value)}
                                helperText={emailHelper}
                                error={emailError}
                                fullWidth
                                sx={{marginTop: '10px', marginBottom: '10px'}}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={password}
                                variant='outlined'
                                label="Password"
                                onBlur={() => validatePassword()}
                                onChange={(e) => setPassword(e.target.value)}
                                helperText={passwordHelper}
                                error={passwordError}
                                fullWidth
                                sx={{marginTop: '10px', marginBottom: '10px'}}
                                type={passwordVisible? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                                edge="end"
                                            >
                                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type='submit'
                                {...ProfileStyle.button}
                            >
                                Change Email
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box>
                <Typography {...ProfileStyle.subheading} gutterBottom>
                    Change Your Password
                </Typography>
                <Box sx={ProfileStyle.para}>
                    <Typography {...ProfileStyle.paraText} gutterBottom>
                        To change your password please submit the form below, a notification email will be sent upon success.
                        Please enter your old password for verification purposes.
                    </Typography>
                </Box>
                <form onSubmit={handlePasswordChange}>
                    <Grid container spacing={2} sx={{marginBottom: '10px'}}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={oldPassword}
                                variant='outlined'
                                label="Old Password"
                                onBlur={() => validateOldPassword()}
                                onChange={(e) => setOldPassword(e.target.value)}
                                helperText={oldPasswordHelper}
                                error={oldPasswordError}
                                fullWidth
                                sx={{marginTop: '10px', marginBottom: '10px'}}
                                type={oldPasswordVisible? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
                                                edge="end"
                                            >
                                                {oldPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={changePassword}
                                variant='outlined'
                                label="New Password"
                                onBlur={() => validateChangePassword()}
                                onChange={(e) => setChangePassword(e.target.value)}
                                helperText={changePasswordHelper}
                                error={changePasswordError}
                                fullWidth
                                sx={{marginTop: '10px', marginBottom: '10px'}}
                                type={changePasswordVisible? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setChangePasswordVisible(!changePasswordVisible)}
                                                edge="end"
                                            >
                                                {changePasswordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={confirmChangePassword}
                                variant='outlined'
                                label="Confirm New Password"
                                onBlur={() => validateConfirmChangePassword()}
                                onChange={(e) => setConfirmChangePassword(e.target.value)}
                                helperText={confirmChangePasswordHelper}
                                error={confirmChangePasswordError}
                                fullWidth
                                sx={{marginTop: '10px', marginBottom: '10px'}}
                                type={confirmChangePasswordVisible? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setConfirmChangePasswordVisible(!confirmChangePasswordVisible)}
                                                edge="end"
                                            >
                                                {confirmChangePasswordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                {...ProfileStyle.button}
                                type='submit'
                            >
                                Change Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box>
                <Typography {...ProfileStyle.subheading} gutterBottom>
                    Delete Your Account
                </Typography>
                <Box sx={ProfileStyle.para}>
                    <Typography {...ProfileStyle.paraText} gutterBottom>
                        To delete your account click the button below. Note that this action cannot be reversed. Please enter your password for verification purposes.
                    </Typography>
                </Box>
                <form onSubmit={handleDelete}>
                    <Grid container spacing={2} sx={{marginBottom: '10px'}}>
                        {!window.localStorage.getItem('TCAIREMGoogle') && <Grid item xs={12} sm={6}>
                            <TextField
                                value={deletePassword}
                                variant='outlined'
                                label="Password"
                                onBlur={() => validateDeletePassword()}
                                onChange={(e) => setDeletePassword(e.target.value)}
                                helperText={deletePasswordHelper}
                                error={deletePasswordError}
                                fullWidth
                                sx={{marginTop: '10px', marginBottom: '10px'}}
                                type={deletePasswordVisible? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setDeletePasswordVisible(!deletePasswordVisible)}
                                                edge="end"
                                            >
                                                {deletePasswordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                required
                            />
                        </Grid>}
                        <Grid item xs={12}>
                            <Button
                                {...ProfileStyle.deleteButton.props}
                                type='submit'
                                sx={ProfileStyle.deleteButton.style}
                            >
                                Delete Account
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            </>}
            </>:
            <Loading />}
        </Container>
    )
}

export default Profile