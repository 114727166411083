import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { PAGES } from '../navigation/routes'
import MenuItem from '@mui/material/MenuItem'

import { RegisterStyle } from '../styles'
import { setErrorNotification } from '../../reducers/notificationReducer'
import { createUser } from '../../reducers/userReducer'
import { countryList } from './utils/utils'

const SignUp = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [nameHelper, setNameHelper] = useState('')
    const [nameError, setNameError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailHelper, setEmailHelper] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [institution, setInstitution] = useState('')
    const [country, setCountry] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [passwordHelper, setPasswordHelper] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [confirmPasswordHelper, setConfirmPasswordHelper] = useState('')
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

    const validateConfirmPassword = () => {
        if((password !== confirmPassword && confirmPassword.length > 0) || (password.length > 0 && password !== confirmPassword)) {
            setConfirmPasswordHelper('Passwords Do Not Match!')
            setConfirmPasswordError(true)
            return false
        } else {
            setConfirmPasswordHelper('')
            setConfirmPasswordError(false)
            return true
        }
    }

    const validatePassword = () => {
        validateConfirmPassword()
        if(password === '') {
            setPasswordHelper('Required Field!')
            setPasswordError(true)
            return false
        } else if(password.length < 8) {
            setPasswordHelper('Minimum Length 8!')
            setPasswordError(true)
            return false
        } else {
            setPasswordHelper('')
            setPasswordError(false)
            return true
        }
    }

    const validateName = () => {
        if(name === '') {
            setNameHelper('Required Field!')
            setNameError(true)
            return false
        } else {
            setNameHelper('')
            setNameError(false)
            return true
        }
    }

    const validateEmail = () => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(email === '') {
            setEmailHelper('Required Field!')
            setEmailError(true)
            return false
        } else if(!email.match(re)) {
            setEmailHelper('Invalid Email Address')
            setEmailError(true)
            return false
        } else {
            setEmailHelper('')
            setEmailError(false)
            return true
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if(validateConfirmPassword() && validateName() && validateEmail() && validatePassword()) {
            const data = {
                email: email,
                password: password,
                name: name
            }

            if(country) data.country = country
            if(institution) data.institution = institution

            dispatch(createUser(data))
            navigate(PAGES.login.path)
        } else {
            dispatch(setErrorNotification('Please fix all form errors before submission'))
        }
    }

    return (
        <Container sx={RegisterStyle.container.style} {...RegisterStyle.container.props}>
            <Box sx={RegisterStyle.formContainer}>
                <Box>
                    <Typography {...RegisterStyle.title}>
                        Register
                    </Typography>
                </Box>
                <form onSubmit={handleSubmit} style={RegisterStyle.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label='Name'
                                value={name}
                                onBlur={() => validateName()}
                                onChange={(e) => setName(e.target.value)}
                                helperText={nameHelper}
                                error={nameError}
                                required
                                sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label='Email'
                                placeholder='email@example.com'
                                value={email}
                                onBlur={() => validateEmail()}
                                onChange={(e) => setEmail(e.target.value)}
                                helperText={emailHelper}
                                error={emailError}
                                required
                                type='email'
                                sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label='Institution'
                                placeholder='T-CAIREM'
                                value={institution}
                                onChange={(e) => setInstitution(e.target.value)}
                                helperText={'Your Primary Organization (Optional)'}
                                sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={country}
                                select
                                helperText={'(Optional)'}
                                label="Country"
                                onChange={(e)=>setCountry(e.target.value)}
                                sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {countryList.map(country => <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Password'
                                value={password}
                                onBlur={() => validatePassword()}
                                onChange={(e) => setPassword(e.target.value)}
                                helperText={passwordHelper}
                                error={passwordError}
                                required
                                type={passwordVisible? 'text' : 'password'}
                                sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                                edge="end"
                                            >
                                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Confirm Password'
                                value={confirmPassword}
                                onBlur={() => validateConfirmPassword()}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                helperText={confirmPasswordHelper}
                                error={confirmPasswordError}
                                required
                                type={confirmPasswordVisible? 'text' : 'password'}
                                sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                                edge="end"
                                            >
                                                {confirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button sx={RegisterStyle.button.style} {...RegisterStyle.button.props} type='submit'>
                        Register
                    </Button>
                </form>
            </Box>
        </Container>
    )
}

export default SignUp