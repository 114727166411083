import bookService from '../services/books'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = null
// TODO update so that if resource is null then return also need to delted and update at the resources in state too
// toolkit sets up the redux and state
const bookSlice = createSlice({
    name: 'books',
    initialState,
    reducers: {
        setBooks(state, action) {
            return action.payload
        },
        appendBook(state, action){
            if (state === null) return state
            state.push(action.payload)
        },
        destroyBook(state, action){
            if (state === null) return state
            return state.filter(book => book.id !== action.payload)
        },
        changeBook(state, action){
            if (state === null) return state
            return state.map(book => book.id === action.payload.id ? action.payload : book)
        }
    }
})

export const { setBooks, appendBook, destroyBook, changeBook } = bookSlice.actions

export const createBook = (data, admin) => {
    return async dispatch => {
        try {
            const book = await bookService.create(data)
            if(admin) {
                dispatch(appendBook(book))
                dispatch(setSuccessNotification('Book created successfully'))
            } else {
                dispatch(setSuccessNotification('Your request has been submitted'))
            }
        } catch {
            if(admin) {
                dispatch(setErrorNotification('Error creating book, if persists please notify developer team'))
            } else {
                dispatch(setErrorNotification('Error submitting request, please try again later'))
            }
        }
    }
}

export const initializeBooks = () => {
    return async dispatch => {
        try {
            const books = await bookService.retrieve()
            dispatch(setBooks(books))
        } catch {
            dispatch(setErrorNotification('Error retrieving books'))
        }
    }
}

export const deleteBook = (id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await bookService.destroy(id)
                dispatch(destroyBook(id))
                dispatch(setSuccessNotification('Book deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting book'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updateBook = (id, data, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                const book = await bookService.update(id, data)
                dispatch(changeBook(book))
                dispatch(setSuccessNotification('Book updated successfully'))
            } catch {
                dispatch(setErrorNotification('Update of book failed'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const searchBooks = (query) => {
    return async dispatch => {
        try {
            const books = await bookService.retrieve(query)
            dispatch(setBooks(books))
        } catch {
            dispatch(setErrorNotification('Error fulfilling search'))
        }
    }
}

export const refreshBook = (id) => {
    return async dispatch => {
        try {
            const book = await bookService.retrieveSingle(id)
            dispatch(changeBook(book))
        } catch { }
    }
}

export default bookSlice.reducer