import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { LoadingStyle } from '../styles'

const Loading = () => {
    return (
        <Box sx={LoadingStyle.container}>
            <LinearProgress {...LoadingStyle.loader} />
        </Box>
    )
}

export default Loading