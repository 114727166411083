import axios from 'axios'
const testUrl = '/api/accounts/me/'
const baseUrl = '/auth/token/'
const endUrl = '/auth/invalidate-sessions/'

var token = null
var refresh_token = null

const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const setToken = new_token => {
    token = new_token
}

const setRefreshToken = new_refresh_token => {
    refresh_token = new_refresh_token
}

const getToken = () => (token !== null)? `Bearer ${token}` : null

const getAuthHeader = () => {
    const auth = {
        headers: {
            'X-CSRFToken': getCookie('csrftoken')
        }
    }
    if(getToken() !== null) auth.headers.Authorization = getToken()

    return auth
}

const refresh = async () => {
    const data = {
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_REGULAR_LOGIN_CLIENT_ID,
        client_secret: process.env.REACT_APP_REGULAR_LOGIN_CLIENT_SECRET,
        refresh_token: refresh_token
    }

    const res = await axios.post(baseUrl, data, getAuthHeader())
    return res.data
}

const refreshGoogle = async () => {
    const data = {
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_SECRET,
        refresh_token: refresh_token
    }

    const res = await axios.post(baseUrl, data, getAuthHeader())
    return res.data
}

const isTokenValid = async () => {
    try{
        const res = await axios.get(testUrl, getAuthHeader())
        return res.status === 200
    } catch (err) {
        return false
    }
}

const revokeTokens = async () => {
    const data = {
        client_id: process.env.REACT_APP_REGULAR_LOGIN_CLIENT_ID,
    }

    try {
        await axios.post(endUrl, data, getAuthHeader())
        return true
    } catch (err) {
        return false
    }
}

const revokeGoogleTokens = async () => {
    const data = {
        client_id: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
    }

    try {
        await axios.post(endUrl, data, getAuthHeader())
        return true
    } catch (err) {
        return false
    }
}

export default { setToken, setRefreshToken, getToken, getAuthHeader, refresh, isTokenValid, revokeTokens, revokeGoogleTokens, refreshGoogle }