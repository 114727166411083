import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

import { Link } from 'react-router-dom'
import { DropDownStyle } from '../styles'
import { PAGES } from './routes'

const DropDown = ({links, handler, logout, logoutHandler, admin}) => {
    return (
        <Box sx={DropDownStyle.container}>
            <List {...DropDownStyle.list.props} sx={DropDownStyle.list.style}>
                {links.map((link) => {
                    return (
                        <Link key={link.title} to={link.path} style={DropDownStyle.reactLink} >
                            <ListItemButton onClick={() => handler(false)}>
                                <ListItemIcon sx={DropDownStyle.listIcon}>
                                    <link.icon />
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={DropDownStyle.listText} primary={link.title} />
                            </ListItemButton>
                        </Link>
                    )
                })}
                {logout && admin &&
                    <Link to={PAGES.admin_portal.path} style={DropDownStyle.reactLink} >
                        <ListItemButton onClick={() => handler(false)}>
                            <ListItemIcon sx={DropDownStyle.listIcon}>
                                <AdminPanelSettingsIcon />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={DropDownStyle.listText} primary={PAGES.admin_portal.title} />
                        </ListItemButton>
                    </Link>
                }
                {logout && <ListItemButton
                        onClick={() => logoutHandler(false)}
                    >
                        <ListItemIcon sx={DropDownStyle.listIcon}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={DropDownStyle.listText} primary={'Logout'} />
                    </ListItemButton>
                }
            </List>
        </Box>
    )
}

export default DropDown
