import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import DropdownSection from "../dropdowns/startsection";
import {sections} from "../dropdowns/content";
import {Guidelines} from "../dropdowns/guideline";


import {StartStyle} from '../styles'
import Button from "@mui/material/Button";

const Start = () => {
    // const backgroundImage = '/static/landing_image.jpg'
    return (
        <>
            <Box sx={{...StartStyle.HeadContainer}}>
                <Container {...StartStyle.HeadContainerMargins}>
                    <Typography {...StartStyle.HeadTitle} gutterBottom marginTop='60px'>
                        Start Here
                    </Typography>
                    <Typography {...StartStyle.HeadContent} gutterBottom marginTop='40px'>
                        Interested in AI in healthcare, but don't know where to start?
                    </Typography>
                    <Typography {...StartStyle.HeadContent} gutterBottom marginTop='10px'>
                        We've got you covered!
                    </Typography>
                </Container>
            </Box>
            <Box sx={{...StartStyle.SelectionContainer}}>
                <Container {...StartStyle.SelectionContainerMargins}>
                    <Container maxWidth='lg'>
                        <Grid container spacing={2} justifyContent='center'>
                            <Grid item xs={12} sm={5.5} sx={StartStyle.card} margin="0 auto">
                                <Box sx={StartStyle.cardContent}>
                                    <Link href='#learn-contianer' underline='none'>
                                        <LibraryBooksIcon sx={StartStyle.cardIcon}/>
                                        <Typography {...StartStyle.cardTitle} gutterBottom>
                                            Learn
                                        </Typography>
                                        <Typography {...StartStyle.cardContent} gutterBottom>
                                            Start learning about AI in healthcare with our curated resources.
                                        </Typography>
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={5.5} sx={StartStyle.card} margin="0 auto">
                                <Box sx={StartStyle.cardContent}>
                                    <Link href='#contribute-container' underline='none'>
                                        <AddIcon sx={StartStyle.cardIcon}/>
                                        <Typography {...StartStyle.cardTitle} gutterBottom>
                                            Contribute
                                        </Typography>
                                        <Typography {...StartStyle.cardContent} gutterBottom>
                                            Contribute to the Resource Hub by adding new resources.
                                        </Typography>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
            <Box id='learn-contianer' sx={{...StartStyle.SectionContainer}}>
                <Container {...StartStyle.SectionContainerMargins}>
                    <Typography gutterBottom variant='h4' align='center' marginBottom='40px'>
                        Follow the sections below to get started!
                    </Typography>
                    <Container maxWidth='lg' sx={StartStyle.DropdownContainer}>
                        {sections.map((section, index) => (
                            <Box key={index} sx={StartStyle.DropdownSection} mb={1} mt={2}>
                                <DropdownSection title={section.title} content={section.content}/>
                            </Box>
                        ))}
                    </Container>
                </Container>
            </Box>
            <Box id='contribute-container' sx={{...StartStyle.SelectionContainer}}>
                <Container {...StartStyle.SelectionContainerMargins}>
                    <Container maxWidth='lg'>
                        <Typography {...StartStyle.HeadTitle} gutterBottom marginTop='10px' color='black'>
                            Contributing to the Resources Hub
                        </Typography>
                    </Container>
                </Container>
            </Box>
            <Box sx={{...StartStyle.SectionContainer}}>
                <Container {...StartStyle.SectionContainerMargins}>
                    <Container maxWidth='lg'>
                        <Typography gutterBottom variant='h4' align='center' marginBottom='20px'>
                            Thank you for your interest in submitting resources to the Hub!
                        </Typography>
                        <Typography gutterBottom variant='h6' align='center' marginBottom='10px'>
                            Please review our submission guide (link below) before submitting your resource(s):
                        </Typography>
                        <Guidelines/>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <Button href='/request-resource' variant='contained' color='primary' sx={{ padding: '12px 24px', fontSize: '16px' }}>
                                Submit a Resource
                            </Button>
                        </Box>
                    </Container>
                </Container>
            </Box>
        </>
    )
}

export default Start
