import React from 'react';

export const Guidelines = () => (
  <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
    <h2 style={{ marginBottom: '20px', color: '#2c3e50' }}>General Guidelines</h2>
    <ol style={{ marginBottom: '40px' }}>
      <li style={{ marginBottom: '15px' }}>
        T-CAIREM’s Resources Hub is a collection of educational resources for learners and researchers interested in AI and medicine. We welcome submissions of any educational or informative resource, in any format. Please use the most appropriate resource type when submitting your resource.
      </li>
      <li style={{ marginBottom: '15px' }}>
        Please be sure to add all appropriate tags to your resource, and to fill out all fields completely. Improperly completed submissions may be rejected.
      </li>
      <li style={{ marginBottom: '15px' }}>
        With few exceptions, submissions to the hub are evaluated as learning materials, not based on the impact of the research conducted.
      </li>
      <li style={{ marginBottom: '15px' }}>
        Resources are approved manually. If your resources do not appear after several days, it has likely been rejected. Please do not submit a resource more than once.
      </li>
      <li style={{ marginBottom: '15px' }}>
        Approved resources may be removed from the hub at a later date if a more recent resource covering the same subject is submitted.
      </li>
      <li style={{ marginBottom: '15px' }}>
        Resources containing profanities or discriminatory/derogatory material will not be accepted.
      </li>
      <li style={{ marginBottom: '15px' }}>
        If you are unsure under which category to submit a resource, please submit it to the best of your ability, and it will be adjusted by the Hub administrative team if necessary.
      </li>
      <li style={{ marginBottom: '15px' }}>
        If you encounter any technical issues, please email us at <a href="mailto:tcairem.resource.hub@healthdatanexus.ai" style={{ color: '#3498db' }}>tcairem.resource.hub@healthdatanexus.ai</a>.
      </li>
    </ol>

    <h2 style={{ marginBottom: '20px', color: '#2c3e50' }}>Paper-specific Guidelines</h2>
    <p style={{ marginBottom: '20px' }}>
      Papers submitted to the resources hub should fall into one of five categories:
    </p>
    <ul style={{ paddingLeft: '20px', marginBottom: '40px' }}>
      <li style={{ marginBottom: '15px' }}>
        <strong>Direct educational purpose:</strong> These papers are written with the intention of teaching the reader about a general method, or mathematical/legal/ethical principle. If your submission presents a specific result, rather than a general principle to be taught, it may be rejected. In an effort to avoid redundancy, papers in this category may be rejected or removed if a more recent or suitable paper becomes available.
      </li>
      <li style={{ marginBottom: '15px' }}>
        <strong>Overview of technical concepts:</strong> These papers may not be directly framed as an educational resource, but they provide an overview of an important technical concept. In an effort to avoid redundancy, papers in this category may be rejected or removed if a more recent or suitable paper becomes available.
      </li>
      <li style={{ marginBottom: '15px' }}>
        <strong>Guidelines/evaluation criteria:</strong> These papers present guidelines or evaluation criteria with which to assess machine learning algorithms and studies.
      </li>
      <li style={{ marginBottom: '15px' }}>
        <strong>Review articles:</strong> These papers review important concepts in the field of AI and medicine (i.e. AI and prediction of lung toxicity; AI and ethics in the medical field). While not directly educational, they are important for developing an understanding of the state of the field. We will only accept one such paper for a given topic at any given time. If your submission is not the most recent for its subject matter, or the subject matter is not sufficiently broadly relevant, it may be rejected.
      </li>
      <li style={{ marginBottom: '15px' }}>
        <strong>Remarkable examples:</strong> These papers present seminal, high-impact work in the field of AI and medicine. Papers should reflect well-conducted and highly-cited work. Acceptance of any submission is up to the discretion of the Hub administrators.
      </li>
    </ul>
  </div>
);