import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import BookCard from '../cards/BookCard'
import PaperCard from '../cards/PaperCard'
import CourseCard from '../cards/CourseCard'
import VideoCard from '../cards/VideoCard'
import PodcastCard from '../cards/PodcastCard'

import BookList from '../lists/BookList'
import PaperList from '../lists/PaperList'
import CourseList from '../lists/CourseList'
import VideoList from '../lists/VideoList'
import PodcastList from '../lists/PodcastList'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loading from '../loading/Loading'
import IconButton from '@mui/material/IconButton'
import ReorderIcon from '@mui/icons-material/Reorder'
import AppsIcon from '@mui/icons-material/Apps'

import { initializeRatings } from '../../reducers/ratingsReducer'
import { setErrorNotification } from '../../reducers/notificationReducer'
import Pagination from '@mui/material/Pagination'
import { PAGE_SIZE } from '../websiteConfig'
import { PAGES } from '../navigation/routes'
import { SearchStyle } from '../styles'

const Ratings = () => {
    const user = useSelector(state => state.user)
    const ratings = useSelector(state => state.ratings)
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState(true)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const pageSize = PAGE_SIZE
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (user) {
            if (user.anon) {
                dispatch(setErrorNotification('You must be logged in to view this page'))
                navigate(PAGES.home.path)
            }
            dispatch(initializeRatings())
            setTimeout(() => setLoading(false), 500)
        }
    }, [user])

    return (
        <Container sx={SearchStyle.container}>
            <Typography {...SearchStyle.title} gutterBottom>
                Ratings
            </Typography>
            <Box sx={SearchStyle.viewContainer}>
                <IconButton sx={{ color: list? '#002A5C' : '#CECFCB'}} onClick={() => setList(true)}>
                    <ReorderIcon />
                </IconButton>
                <IconButton sx={{ color: !list? '#002A5C' : '#CECFCB'}} onClick={() => setList(false)}>
                    <AppsIcon />
                </IconButton>
            </Box>
            {loading && <Loading />}
            {!loading && ((ratings.length > 0)?
                <>
                    <Grid container spacing={2}>
                        {ratings.slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(resource => {
                            resource = resource.rated_object
                            if(list) {
                                if (resource.type === 'Book') {
                                    return (
                                        <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                            <BookList {...resource}/>
                                        </Grid>
                                    )
                                } else if (resource.type === 'Course') {
                                    return (
                                        <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                            <CourseList {...resource}/>
                                        </Grid>
                                    )
                                } else if (resource.type === 'Paper') {
                                    return (
                                        <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                            <PaperList {...resource}/>
                                        </Grid>
                                    )
                                } else if (resource.type === 'Podcast') {
                                    return (
                                        <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                            <PodcastList {...resource}/>
                                        </Grid>
                                    )
                                } else {
                                    return (
                                        <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                            <VideoList {...resource}/>
                                        </Grid>
                                    )
                                }
                            }

                            if (resource.type === 'Book') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                        <BookCard {...resource}/>
                                    </Grid>
                                )
                            } else if (resource.type === 'Course') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                        <CourseCard {...resource}/>
                                    </Grid>
                                )
                            } else if (resource.type === 'Paper') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                        <PaperCard {...resource}/>
                                    </Grid>
                                )
                            } else if (resource.type === 'Podcast') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                        <PodcastCard {...resource}/>
                                    </Grid>
                                )
                            } else {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                        <VideoCard {...resource}/>
                                    </Grid>
                                )
                            }
                        })}
                    </Grid>
                    <Pagination sx={SearchStyle.pagination} count={Math.ceil(ratings.length/pageSize)} page={page} onChange={(event, value) => setPage(value)} />
                </> :
                <Typography {...SearchStyle.noResources.props} gutterBottom sx={SearchStyle.noResources.style}>
                    No Rated Resources Found
                </Typography>)
            }
        </Container>
    )
}

export default Ratings