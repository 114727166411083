import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import LaunchIcon from '@mui/icons-material/Launch'

import PolicyIcon from '@mui/icons-material/Policy'
import StarIcon from '@mui/icons-material/Star'

import { PolicyCardStyle } from '../styles'
import PolicyModal from '../modals/PolicyModal'

const PolicyCard = (props) => {
    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    // renders 2 cards one for sm screens and other for larger screens
    return (
        <>
            <Card sx={PolicyCardStyle.mobile.card.card} variant={PolicyCardStyle.mobile.card.variant}>
                <Box sx={PolicyCardStyle.mobile.icon.box}>
                    <PolicyIcon color={PolicyCardStyle.mobile.icon.icon.color} sx={PolicyCardStyle.mobile.icon.icon} />
                </Box>
                <Box sx={PolicyCardStyle.mobile.body.container}>
                    <CardContent sx={PolicyCardStyle.mobile.body.meta}>
                        <Box sx={PolicyCardStyle.mobile.body.text}>
                            <Typography {...PolicyCardStyle.mobile.body.title} >
                                {props.title.length > 40? `${props.title.substring(0, 40)}...` : props.title}
                            </Typography>
                            {props.rating?
                                <Typography {...PolicyCardStyle.mobile.body.rating.props} sx={PolicyCardStyle.mobile.body.rating.style} >
                                    {props.rating}/5.0 <StarIcon />
                                </Typography> :
                                <Typography {...PolicyCardStyle.mobile.body.rating.props} sx={PolicyCardStyle.mobile.body.rating.style} >
                                    Not Rated
                                </Typography>
                            }
                        </Box>
                        {props.tags.map(tag => <Chip key={tag} label={tag} sx={PolicyCardStyle.mobile.body.badge} />)}
                    </CardContent>
                    <CardActions sx={PolicyCardStyle.mobile.buttonContainer.style}>
                        <PolicyModal {...props} />
                        <Button {...PolicyCardStyle.mobile.button.props} sx={PolicyCardStyle.mobile.button.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                    </CardActions>
                </Box>
            </Card>
            <Card sx={PolicyCardStyle.desktop.card.card} variant={PolicyCardStyle.desktop.card.variant}>
                <Box sx={PolicyCardStyle.desktop.icon.box}>
                    <PolicyIcon color={PolicyCardStyle.desktop.icon.icon.color} sx={PolicyCardStyle.desktop.icon.icon} />
                </Box>
                <CardContent sx={PolicyCardStyle.desktop.body.cardContent}>
                    <Box sx={PolicyCardStyle.desktop.body.ratingContainer}>
                        {props.rating?
                            <Typography sx={PolicyCardStyle.desktop.body.rating.style}>
                                {props.rating}/5.0 <StarIcon />
                            </Typography>:
                            <Typography sx={PolicyCardStyle.desktop.body.rating.style}>
                                Not Rated
                            </Typography>
                        }
                    </Box>
                    <Box>
                        <Typography {...PolicyCardStyle.desktop.body.title.props} sx={PolicyCardStyle.desktop.body.title.style}>
                            {props.title.length > 90? `${props.title.substring(0, 90)}...` : props.title}
                        </Typography>
                    </Box>
                    <Typography {...PolicyCardStyle.desktop.body.audience}>
                        Published: {props.pub_date}
                    </Typography>
                    {props.tags.map(tag => <Chip key={tag} label={tag} sx={PolicyCardStyle.desktop.body.badge} />)}
                </CardContent>
                <CardActions sx={PolicyCardStyle.desktop.buttonContainer.style}>
                    <PolicyModal {...props} />
                    <Button {...PolicyCardStyle.desktop.button.props} sx={PolicyCardStyle.desktop.button.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                </CardActions>
            </Card>
        </>
    )
}

export default PolicyCard