import axios from 'axios'
import tokenService from './token'

const regularUrl = '/auth/token/'
const googleUrl = '/auth/convert-token/'

const regularLogin = async (creds) => {
    const credentials = {
        ...creds,
        grant_type: 'password',
        client_id: process.env.REACT_APP_REGULAR_LOGIN_CLIENT_ID,
        client_secret: process.env.REACT_APP_REGULAR_LOGIN_CLIENT_SECRET,
    }
    const res = await axios.post(regularUrl, credentials, tokenService.getAuthHeader())
    return res.data
}

const googleLogin = async (creds) => {
    const credentials = {
        ...creds,
        grant_type: 'convert_token',
        client_id: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_SECRET,
        backend: 'google-oauth2'
    }
    const res = await axios.post(googleUrl, credentials, tokenService.getAuthHeader())
    return res.data
}

export default { regularLogin, googleLogin }