import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Loading from '../loading/Loading'
import AdminBookModal from '../modals/AdminBookModal'
import AdminPaperModal from '../modals/AdminPaperModal'
import AdminPolicyModal from "../modals/AdminPolicyModal";
import AdminCourseModal from '../modals/AdminCourseModal'
import AdminVideoModal from '../modals/AdminVideoModal'
import AdminPodcastModal from '../modals/AdminPodcastModal'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'

import { AdminManage } from '../styles'

import { PAGES } from '../navigation/routes'
import { PAGE_SIZE } from '../websiteConfig'

import { setErrorNotification } from '../../reducers/notificationReducer'
import { deleteResource, initializeResources, toggleApproveResource } from '../../reducers/resourcesReducer'
import { setUserOnRefresh } from '../../reducers/userReducer'
import { deleteBook, updateBook } from '../../reducers/booksReducer'
import { deleteCourse, updateCourse } from '../../reducers/coursesReducer'
import { deletePaper, updatePaper } from '../../reducers/papersReducer'
import { deletePolicy, updatePolicy} from "../../reducers/policiesReducer";
import { deletePodcast, updatePodcast } from '../../reducers/podcastsReducer'
import { deleteVideo, updateVideo } from '../../reducers/videosReducer'
import { initializeTags } from '../../reducers/tagsReducers'

const ManageResources = () => {
    const [filter, setFilter] = useState('')
    const [types, setTypes] = useState(['Book', 'Course', 'Paper', 'Podcast', 'Video'])
    const [filteredResources, setFilteredResources] = useState(null)
    const [approvedResources, setApprovedResources] = useState(null)
    const tags = useSelector(state => state.tags)
    const optTags = tags.map(tag => { return {label: tag.name, value: tag.name} })

    const user = useSelector(state => state.user)
    const resources = useSelector(state => state.resources)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const pageSize = PAGE_SIZE
    const [page, setPage] = useState(1)

    // force user update
    useEffect(() => {
        dispatch(setUserOnRefresh())
        dispatch(initializeTags())
    }, [])

    useEffect(() => {
        if(resources) setApprovedResources(resources.filter(resource => resource.approved))
    }, [resources])

    useEffect(() => {
        if(approvedResources) setFilteredResources(approvedResources.filter(resource => (resource.title.toLowerCase().includes(filter.toLowerCase()) && types.includes(resource.type))))
    }, [approvedResources, filter, types])

    // if user is not staff then force them out should not be here
    useEffect(() => {
        if(user) {
            if (user.staff) {
                dispatch(initializeResources())
            } else {
                dispatch(setErrorNotification('You are not authorized to view this page'))
                navigate(PAGES.home.path)
            }
        }
    }, [user])

    // what type of resource to show
    const changeTypes = (type, val) => {
        if (val) {
            setTypes(types.concat(type))
        } else {
            setTypes(types.filter(resType => resType !== type))
        }
    }

    // dis approve resource (set approve to false)
    const handleDisapprove = (resource, type) => {
        if (window.confirm('Are you sure you want to unapprove this resource?')) {
            const data = {
                ...resource,
                approved: false
            }

            // send to appropriate endpoint based off of type
            if (type === 'Book') {
                dispatch(updateBook(resource.id, data, user.staff))
            } else if (type === 'Course') {
                dispatch(updateCourse(resource.id, data, [], [], user.staff))
            } else if (type === 'Paper') {
                dispatch(updatePaper(resource.id, data, user.staff))
            } else if (type === 'Policy') {
                dispatch(updatePolicy(resource.id, data, user.staff))
            } else if (type === 'Podcast') {
                dispatch(updatePodcast(resource.id, data, [], [], user.staff))
            } else if (type === 'Video') {
                dispatch(updateVideo(resource.id, data, [], [], user.staff))
            }
            dispatch(toggleApproveResource(data))
        }
    }

    // delete the resource
    const handleDelete = (resource, type) => {
        if (window.confirm('Are you sure you want to delete this resource? This action cannot be undone.')) {
            // send to appropriate endpoint based off of type
            if (type === 'Book') {
                dispatch(deleteBook(resource.id, user.staff))
            } else if (type === 'Course') {
                dispatch(deleteCourse(resource.id, user.staff))
            } else if (type === 'Paper') {
                dispatch(deletePaper(resource.id, user.staff))
            } else if (type === 'Policy') {
                dispatch(deletePolicy(resource.id, user.staff))
            } else if (type === 'Podcast') {
                dispatch(deletePodcast(resource.id, user.staff))
            } else if (type === 'Video') {
                dispatch(deleteVideo(resource.id, user.staff))
            }
            dispatch(deleteResource(resource.id, type))
        }
    }

    if(filteredResources === null || approvedResources === null) {
        return (
            <Container {...AdminManage.loading.props} sx={AdminManage.loading.style}>
                <Loading />
            </Container>
        )
    }

    return (
        <Container sx={AdminManage.container}>
            <Typography {...AdminManage.title} gutterBottom>
                {PAGES.manage_resources.title}
            </Typography>
            {((approvedResources.length > 0)?
                <>
                    <Typography {...AdminManage.filterTitle} gutterBottom>Filters</Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                sx={{marginBottom: '20px'}}
                                fullWidth
                                value={filter}
                                onChange={e => setFilter(e.target.value)}
                                label="Filter By Title"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>Resource Type</FormLabel>
                            <FormGroup sx={AdminManage.checkbox}>
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Book', e.target.checked)}/>} label="Books" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Course', e.target.checked)}/>} label="Courses" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Paper', e.target.checked)}/>} label="Papers" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Policy', e.target.checked)}/>} label="Policies" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Podcast', e.target.checked)}/>} label="Podcasts" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Video', e.target.checked)}/>} label="Videos" />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table sx={AdminManage.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell {...AdminManage.typeAlign}>Type</TableCell>
                                    <TableCell {...AdminManage.columnAlign}>Edit</TableCell>
                                    <TableCell {...AdminManage.columnAlign}>Unapprove</TableCell>
                                    <TableCell {...AdminManage.columnAlign}>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredResources.slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(resource => (
                                    <TableRow
                                        key={`${resource.type}${resource.id}`}
                                        sx={AdminManage.tableRow}
                                    >
                                        <TableCell {...AdminManage.tableCell.props} sx={AdminManage.tableCell.style}>
                                            {resource.title}
                                        </TableCell>
                                        <TableCell {...AdminManage.typeAlign}>{resource.type}</TableCell>
                                        <TableCell {...AdminManage.columnAlign}>
                                            {resource.type === 'Book' && <AdminBookModal {...resource} optTags={optTags} />}
                                            {resource.type === 'Paper' && <AdminPaperModal {...resource} optTags={optTags} />}
                                            {resource.type === 'Policy' && <AdminPolicyModal {...resource} optTags={optTags} />}
                                            {resource.type === 'Course' && <AdminCourseModal {...resource} optTags={optTags} />}
                                            {resource.type === 'Video' && <AdminVideoModal {...resource} optTags={optTags} />}
                                            {resource.type === 'Podcast' && <AdminPodcastModal {...resource} optTags={optTags} />}
                                        </TableCell>
                                        <TableCell {...AdminManage.columnAlign}>
                                            <Button {...AdminManage.reject.props} sx={AdminManage.reject.style} onClick={()=>handleDisapprove(resource, resource.type)}>
                                                <CloseIcon />&nbsp;Unapprove
                                            </Button>
                                        </TableCell>
                                        <TableCell {...AdminManage.columnAlign}>
                                            <Button {...AdminManage.reject.props} sx={AdminManage.reject.style} onClick={()=>handleDelete(resource, resource.type)}>
                                                <DeleteForeverIcon />&nbsp;Delete
                                            </Button>
                                        </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination sx={AdminManage.pagination} count={Math.ceil(filteredResources.length/pageSize)} page={page} onChange={(event, value) => setPage(value)} />
                </> :
                <Box sx={AdminManage.noResources}>
                    <QuestionMarkIcon sx={AdminManage.noResourceIcon} />
                    <Typography {...AdminManage.noResourceText} gutterBottom>
                        There are no resources to manage
                    </Typography>
                </Box>
                )
            }
        </Container>
    )
}

export default ManageResources