import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LaunchIcon from '@mui/icons-material/Launch'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StarIcon from '@mui/icons-material/Star'

import { VideoListStyle } from '../styles'
import VideoModal from '../modals/VideoModal'

const VideoList = (props) => {
    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <>
            <Box sx={VideoListStyle.topContainer}>
                <Box sx={VideoListStyle.topContainerIconContainer}>
                    <PlayArrowIcon sx={VideoListStyle.icon} />
                    <Typography {...VideoListStyle.text} gutterBottom>
                        &nbsp;Published: {props.pub_date}
                    </Typography>
                </Box>
                {props.rating?
                    <Typography {...VideoListStyle.text}>
                        {props.rating}/5.0 <StarIcon />
                    </Typography> :
                    <Typography {...VideoListStyle.text}>
                        No Rating Yet
                    </Typography>
                }
            </Box>
            <Box>
                <Box sx={VideoListStyle.titleContainer}>
                    <Typography {...VideoListStyle.title} gutterBottom>
                        {props.title}
                    </Typography>
                </Box>
                {props.tags.map(tag => <Chip sx={VideoListStyle.chips} key={tag} label={tag} />)}
                <Box sx={VideoListStyle.secondary}>
                    <Typography {...VideoListStyle.text}>
                        Channel: {props.author}
                    </Typography>
                </Box>
                <Box sx={VideoListStyle.actions}>
                    <VideoModal {...props} />
                    <Button {...VideoListStyle.view.props} sx={VideoListStyle.view.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                </Box>
            </Box>
            <Divider />
        </>
    )
}

export default VideoList