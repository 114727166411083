import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { setSuccessNotification } from '../../reducers/notificationReducer'
import useTheme from '@mui/material/styles/useTheme'

const SuccessNotification = () => {
    const theme = useTheme()
    const small_screen = theme.breakpoints.values.sm
    const notification = useSelector(state => state.notifications.successNotification)
    const dispatch = useDispatch()

    // if on mobile notif on bottom right otehr wise top center
    useEffect(() => {
        if(notification) {
            if (window.innerWidth > small_screen) {
                toast.success(notification , {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else {
                toast.success(notification , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
            dispatch(setSuccessNotification(null))
        }
    }, [notification])

    return (
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    )
}

export default SuccessNotification