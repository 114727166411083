import paperService from '../services/papers'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = null

// toolkit sets up the redux and state
const paperSlice = createSlice({
    name: 'papers',
    initialState,
    reducers: {
        setPapers(state, action) {
            return action.payload
        },
        appendPaper(state, action){
            if (state === null) return state
            state.push(action.payload)
        },
        destroyPaper(state, action){
            if (state === null) return state
            return state.filter(paper => paper.id !== action.payload)
        },
        changePaper(state, action){
            if (state === null) return state
            return state.map(paper => paper.id === action.payload.id ? action.payload : paper)
        }
    }
})

export const { setPapers, appendPaper, destroyPaper, changePaper } = paperSlice.actions

export const createPaper = (data, admin) => {
    return async dispatch => {
        try {
            const paper = await paperService.create(data)
            if(admin) {
                dispatch(appendPaper(paper))
                dispatch(setSuccessNotification('Paper created successfully'))
            } else {
                dispatch(setSuccessNotification('Your request has been submitted'))
            }
        } catch {
            if(admin) {
                dispatch(setErrorNotification('Error creating paper, if persists please notify developer team'))
            } else {
                dispatch(setErrorNotification('Error submitting request, please try again later'))
            }
        }
    }
}

export const initializePapers = () => {
    return async dispatch => {
        try {
            const papers = await paperService.retrieve()
            dispatch(setPapers(papers))
        } catch {
            dispatch(setErrorNotification('Error retrieving papers'))
        }
    }
}

export const deletePaper = (id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await paperService.destroy(id)
                dispatch(destroyPaper(id))
                dispatch(setSuccessNotification('Paper deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting paper'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updatePaper = (id, data, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                const paper = await paperService.update(id, data)
                dispatch(changePaper(paper))
                dispatch(setSuccessNotification('Paper updated successfully'))
            } catch {
                dispatch(setErrorNotification('Update of paper failed'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const searchPapers = (query) => {
    return async dispatch => {
        try {
            const papers = await paperService.retrieve(query)
            dispatch(setPapers(papers))
        } catch {
            dispatch(setErrorNotification('Error fulfilling search'))
        }
    }
}

export const refreshPaper = (id) => {
    return async dispatch => {
        try {
            const paper = await paperService.retrieveSingle(id)
            dispatch(changePaper(paper))
        } catch { }
    }
}

export default paperSlice.reducer