import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'

import { RequestFormStyle } from '../styles'
import Select from 'react-select'
import { setErrorNotification } from '../../reducers/notificationReducer'
import { createVideo } from '../../reducers/videosReducer'

const VideoForm = ({tags, user}) => {
    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [nameHelper, setNameHelper] = useState('')
    const [author, setAuthor] = useState('')
    const [authorError, setAuthorError] = useState(false)
    const [authorHelper, setAuthorHelper] = useState('')
    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState(false)
    const [linkHelper, setLinkHelper] = useState('')
    const [date, setDate] = useState('')
    const [dateError, setDateError] = useState(false)
    const [dateHelper, setDateHelper] = useState('')
    const [formTags, setFormTags] = useState('')
    const [videos, setVideos] = useState([])

    const validate = (val, errorHandler, helperHandler) => {
        if (val.length === 0) {
            errorHandler(true)
            helperHandler('Required Field!')
            return false
        } else {
            errorHandler(false)
            helperHandler('')
            return true
        }
    }

    const handleFormChange = (index, event) => {
        let data = [...videos]
        data[index][event.target.name] = event.target.value
        setVideos(data)
    }

    const addFields = () => {
        let newField = {
            title: '',
            pub_date: '',
            link: '',
            author: ''
        }
        setVideos([...videos, newField])
    }

    const removeFields = (index) => {
        let data = [...videos]
        data.splice(index, 1)
        setVideos(data)
    }

    const reset = () => {
        setName('')
        setNameError(false)
        setNameHelper('')
        setAuthor('')
        setAuthorError(false)
        setAuthorHelper('')
        setLink('')
        setLinkError(false)
        setLinkHelper('')
        setDate('')
        setDateError(false)
        setDateHelper('')
        setFormTags('')
        setVideos([])
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (validate(name, setNameError, setNameHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper)) {
            const data = {
                title: name,
                author: author,
                link: link,
                pub_date: date
            }

            if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
            else data.tags = []

            dispatch(createVideo(data, videos, user.staff))
            reset()
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    return (
        <Container {...RequestFormStyle.container}>
            <Typography {...RequestFormStyle.title.props} gutterBottom sx={RequestFormStyle.title.style}>
                Video Form
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            value={name}
                            onChange={e => setName(e.target.value)}
                            label="Name"
                            required
                            error={nameError}
                            helperText={nameHelper}
                            onBlur={()=>validate(name, setNameError, setNameHelper)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            value={author}
                            onChange={e => setAuthor(e.target.value)}
                            label="Channel"
                            required
                            error={authorError}
                            helperText={authorHelper}
                            onBlur={()=>validate(author, setAuthorError, setAuthorHelper)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            value={link}
                            type='url'
                            onChange={e => setLink(e.target.value)}
                            label="Link to Resource"
                            required
                            error={linkError}
                            helperText={linkHelper}
                            onBlur={()=>validate(link, setLinkError, setLinkHelper)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            value={date}
                            type='date'
                            onChange={e => setDate(e.target.value)}
                            label="Publish Date"
                            required
                            InputLabelProps={{ shrink: true }}
                            error={dateError}
                            helperText={dateHelper}
                            onBlur={()=>validate(date, setDateError, setDateHelper)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={tags}
                            value={formTags}
                            onChange={(value) => setFormTags(value)}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    minHeight: '56px'
                                })
                            }}
                            isOptionDisabled={() => formTags.length >= 5}
                            placeholder='Select Key Words'
                        />
                    </Grid>
                    <Grid item xs={7} sm={9}>
                        <Typography variant='h4' gutterBottom>
                            Playlist
                        </Typography>
                    </Grid>
                    <Grid item xs={5} sm={3}>
                        <Button variant='contained' sx={{width: '100%'}} onClick={()=>addFields()}>
                            Add Video&nbsp;&nbsp;&nbsp;<AddCircleIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='p' gutterBottom>
                            You can add links to each individual video here if this is a playlist.
                            Otherwise you can leave this empty.
                        </Typography>
                    </Grid>
                    {videos.map((video, index) => (
                        <Grid item key={index} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <Typography variant='h5' gutterBottom>
                                        Video {index+1}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button sx={{backgroundColor: '#D22742', width: '100%', '&:hover': {backgroundColor: '#851829'}}} variant='contained' onClick={()=>removeFields(index)}>
                                        Remove Video&nbsp;&nbsp;&nbsp;<DeleteIcon />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        name='title'
                                        required
                                        value={video.title}
                                        onChange={(event)=>handleFormChange(index, event)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Author"
                                        name='author'
                                        required
                                        value={video.author}
                                        onChange={(event)=>handleFormChange(index, event)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Link"
                                        name='link'
                                        required
                                        type='url'
                                        value={video.link}
                                        onChange={(event)=>handleFormChange(index, event)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Publish Date"
                                        name='pub_date'
                                        required
                                        type='date'
                                        InputLabelProps={{ shrink: true }}
                                        value={video.pub_date}
                                        onChange={(event)=>handleFormChange(index, event)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <Button sx={RequestFormStyle.button.style} {...RequestFormStyle.button.props} type='submit'>{user.staff? 'Create Video' : 'Request Video'}</Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default VideoForm