import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StartStyle } from '../styles';

const DropdownSection = ({ title, content }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    return (
        <Box sx={StartStyle.DropdownSection}>
            <Box display="flex" justifyContent="space-between" alignItems="center" onClick={toggleDropdown} style={{cursor: 'pointer'}} marginRight='20px'>
                <Typography variant="h6" gutterBottom marginLeft='20px' marginTop='10px'>
                    {title}
                </Typography>
                <ExpandMoreIcon style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} />
            </Box>
            <ol>
            {isOpen && content.map((item, index) => (
                <Box key={index} mb={2}>
                    <li key={index} style={{marginBottom: '10px', paddingLeft: '20px'}}>
                        <Link href={item.url} underline="always" target="_blank" rel="noopener">
                            <Typography variant="h6" color="primary">
                                {item.title}
                            </Typography>
                        </Link>
                        <Typography variant="h7" color="textSecondary">
                            {item.description}
                        </Typography>
                    </li>
                </Box>
            ))}
            </ol>
        </Box>
    );
};

export default DropdownSection;