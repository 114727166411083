import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LaunchIcon from '@mui/icons-material/Launch'
import PodcastsIcon from '@mui/icons-material/Podcasts'
import StarIcon from '@mui/icons-material/Star'

import { PodcastListStyle } from '../styles'
import PodcastModal from '../modals/PodcastModal'

const PodcastList = (props) => {
    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <>
            <Box sx={PodcastListStyle.topContainer}>
                <Box sx={PodcastListStyle.topContainerIconContainer}>
                    <PodcastsIcon sx={PodcastListStyle.icon} />
                    <Typography {...PodcastListStyle.text} gutterBottom>
                        &nbsp;Published: {props.pub_date}
                    </Typography>
                </Box>
                {props.rating?
                    <Typography {...PodcastListStyle.text}>
                        {props.rating}/5.0 <StarIcon />
                    </Typography> :
                    <Typography {...PodcastListStyle.text}>
                        No Rating Yet
                    </Typography>
                }
            </Box>
            <Box>
                <Box sx={PodcastListStyle.titleContainer}>
                    <Typography {...PodcastListStyle.title} gutterBottom>
                        {props.title}
                    </Typography>
                </Box>
                <Box sx={PodcastListStyle.titleContainer}>
                    <Typography {...PodcastListStyle.description} gutterBottom>
                        {props.description}
                    </Typography>
                </Box>
                {props.tags.map(tag => <Chip sx={PodcastListStyle.chips} key={tag} label={tag} />)}
                <Box sx={PodcastListStyle.secondary}>
                    <Typography {...PodcastListStyle.text}>
                        Series: {props.author}
                    </Typography>
                </Box>
                <Box sx={PodcastListStyle.actions}>
                    <PodcastModal {...props} />
                    <Button {...PodcastListStyle.view.props} sx={PodcastListStyle.view.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                </Box>
            </Box>
            <Divider />
        </>
    )
}

export default PodcastList