import loginService from '../services/login'
import tokenService from '../services/token'
import userService from '../services/user'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = null

// toolkit sets up the redux and state
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action) {
            return action.payload
        }
    }
})

export const { setUser } = userSlice.actions

export const login = (data) => {
    return async dispatch => {
        try {
            const tokens = await loginService.regularLogin(data)
            tokenService.setToken(tokens.access_token)
            tokenService.setRefreshToken(tokens.refresh_token)
            window.localStorage.setItem('TCAIREMUser', JSON.stringify(tokens))

            const user = await userService.getCurrent()
            dispatch(setUser(user))
            dispatch(setSuccessNotification('Logged in successfully'))
        } catch(e) {
            dispatch(setErrorNotification('Invalid username or password'))
        }
    }
}

export const googleLogin = (data) => {
    return async dispatch => {
        try {
            const tokens = await loginService.googleLogin(data)
            tokenService.setToken(tokens.access_token)
            tokenService.setRefreshToken(tokens.refresh_token)
            window.localStorage.setItem('TCAIREMUser', JSON.stringify(tokens))
            window.localStorage.setItem('TCAIREMGoogle', true)

            const user = await userService.getCurrent()
            dispatch(setUser(user))
            dispatch(setSuccessNotification('Logged in successfully'))
        } catch {
            dispatch(setErrorNotification('Error logging in, please try again later'))
        }
    }
}

export const logout = (expired=false) => {
    return async dispatch => {
        try {
            if(window.localStorage.getItem('TCAIREMGoogle')) {
                window.localStorage.removeItem('TCAIREMGoogle')
                await tokenService.revokeGoogleTokens()
            } else {
                await tokenService.revokeTokens()
            }
            window.localStorage.removeItem('TCAIREMUser')
            tokenService.setRefreshToken(null)
            tokenService.setToken(null)
            if(!expired) {
                dispatch(setSuccessNotification('Logged out successfully'))
            }
            window.localStorage.setItem('TCAIREMUser', JSON.stringify({ anon: true }))
            dispatch(setUser({ anon: true }))
        } catch (err) {
            window.localStorage.removeItem('TCAIREMUser')
            tokenService.setRefreshToken(null)
            tokenService.setToken(null)
            if(!expired) {
                dispatch(setSuccessNotification('Logged out successfully'))
            }
            window.localStorage.setItem('TCAIREMUser', JSON.stringify({ anon: true }))
            dispatch(setUser({ anon: true }))
        }
    }
}

export const setUserOnRefresh = () => {
    return async dispatch => {
        const tokens = window.localStorage.getItem('TCAIREMUser')
        const data = JSON.parse(tokens)

        if(tokens && !data.anon) {
            tokenService.setToken(data.access_token)
            tokenService.setRefreshToken(data.refresh_token)

            const valid = await tokenService.isTokenValid()

            if(!valid) {
                try {
                    let tokens = null

                    if(window.localStorage.getItem('TCAIREMGoogle')) {
                        tokens = await tokenService.refreshGoogle()
                    } else {
                        tokens = await tokenService.refresh()
                    }
                    tokenService.setToken(tokens.access_token)
                    tokenService.setRefreshToken(tokens.refresh_token)
                    window.localStorage.setItem('TCAIREMUser', JSON.stringify(tokens))
                    const user = await userService.getCurrent()
                    dispatch(setUser(user))
                } catch {
                    dispatch(setErrorNotification('Session has expired'))
                    dispatch(logout(true))
                }
            } else {
                const user = await userService.getCurrent()
                dispatch(setUser(user))
            }
        } else {
            window.localStorage.setItem('TCAIREMUser', JSON.stringify({ anon: true }))
            dispatch(setUser({ anon: true }))
        }
    }
}

export const createUser = (data) => {
    return async dispatch => {
        try {
            const res = await userService.create(data)
            if(res.status === 400) {
                if (res.data.password) {
                    res.data.password.password.forEach(message => {
                        dispatch(setErrorNotification(message))
                    })
                } else {
                    dispatch(setErrorNotification('Error creating account, please try again later!'))
                }
            } else {
                dispatch(setSuccessNotification('Account created successfully'))
            }
        } catch {
            dispatch(setErrorNotification('Error creating account, please try again later!'))
        }
    }
}

export const updateUser = (data) => {
    return async dispatch => {
        try {
            const user = await userService.update(data.id, data)
            dispatch(setUser(user))
            dispatch(setSuccessNotification('Profile updated'))
        } catch {
            dispatch(setErrorNotification('Error updating profile'))
        }
    }
}

export const changeUserPassword = (data) => {
    return async dispatch => {
        try {
            const res = await userService.changePassword(data)

            if(res.status === 400) {
                if (res.data.password) {
                    res.data.password.password.forEach(message => {
                        dispatch(setErrorNotification(message))
                    })
                } else if (res.data.old_password) {
                    dispatch(setErrorNotification('Old password is not correct, please try again'))
                } else {
                    dispatch(setErrorNotification('Error updating password, please try again later'))
                }
            } else {
                dispatch(setSuccessNotification('Password updated successfully'))
            }
        } catch {
            dispatch(setErrorNotification('Error updating password, please try again later'))
        }
    }
}

export const changeEmail = (data) => {
    return async dispatch => {
        try {
            const res = await userService.changeEmail(data)

            if(res.status === 400) {
                if (res.data.password) {
                    dispatch(setErrorNotification('Password is not correct, please try again'))
                } else if(res.data.email) {
                    dispatch(setErrorNotification('The email you specified is already in use!'))
                } else {
                    dispatch(setErrorNotification('Error updating email, please try again later'))
                }
            } else {
                dispatch(setSuccessNotification('Email updated successfully'))
            }
        } catch {
            dispatch(setErrorNotification('Error updating email, please try again later'))
        }
    }
}

export const resetPassword = (data) => {
    return async dispatch => {
        try {
            const res = await userService.passwordReset(data)

            if(res) {
                dispatch(setSuccessNotification('Please check your email for further instructions'))
            } else {
                dispatch(setErrorNotification('Email not recognized'))
            }
        } catch {
            dispatch(setErrorNotification('Email not recognized'))
        }
    }
}

export const resetPasswordConfirm = (data) => {
    return async dispatch => {
        try {
            const res = await userService.passwordResetConfirm(data)

            if(res) {
                dispatch(setSuccessNotification('Password reset successfully'))
            } else {
                dispatch(setErrorNotification('Invalid token for password reset'))
            }
        } catch {
            dispatch(setErrorNotification('Error resetting password, please try again later'))
        }
    }
}

export const deleteUser = (id, password) => {
    return async dispatch => {
        try {
            await userService.destroy(id, password)
            dispatch(setSuccessNotification('Your account was deleted!'))
            dispatch(logout())
            window.location.reload()
        } catch {
            dispatch(setErrorNotification('There was an error deleting your account, please verify your password is correct'))
        }
    }
}

export const activateUser = (id, token) => {
    return async dispatch => {
        try {
            const res = await userService.activateUser(id, token)

            if(res) {
                dispatch(setSuccessNotification('Account activated!'))
            } else {
                dispatch(setErrorNotification('Invalid activation link'))
            }
        } catch {
            dispatch(setErrorNotification('Error activating account, please try again later'))
        }
    }
}

export default userSlice.reducer