import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import { AboutStyle } from '../styles'
import TeamCard from '../cards/TeamCard'

const About = () => {
    return (
        <>
            <Box sx={AboutStyle.eduMessContainer}>
                    <Container {...AboutStyle.eduMessContainerMargins}>
                        <Grid sx={AboutStyle.eduGrid} container spacing={1}>
                            <Grid item md={6} sx={AboutStyle.eduGridMess}>
                                <Typography {...AboutStyle.title} gutterBottom>
                                    Education Lead's Message
                                </Typography>
                                <Typography{...AboutStyle.eduText} gutterBottom>
                                    Artificial intelligence (AI) is changing faster than ever, especially with recent developments in
                                    generative and large language models. As a result, thousands of scientific papers at the intersection
                                    of AI and health are published each year. As part of our mission to create a leading education program
                                    in applied AI for medical and health science professionals, we have collated a series of research papers,
                                    books, podcasts, tutorials, and more. These resources have been specially curated because we believe
                                    them to be, particularly impactful, approachable, or thought-provoking. We deliberately chose this
                                    approach to help you separate signal from noise and create various resources to accommodate various
                                    backgrounds. We also welcome users to recommend additions to Hub to ensure we stay abreast of ongoing
                                    developments and the latest breakthroughs.

                                    This is truly an exciting time to be leveraging technology to make groundbreaking discoveries, better
                                    care for patients, and improve the performance of health systems.

                                    Best wishes on your learning journey,

                                    Laura
                                </Typography>
                            </Grid>
                            <Grid item md={6} sx={AboutStyle.imageContainer}>
                                <figure style={AboutStyle.imageAlign}>
                                    <img src='/static/laura.svg' style={AboutStyle.image}/>
                                    <figcaption>
                                        <Typography {...AboutStyle.caption} gutterBottom>
                                            Laura C. Rosella, PhD, MHSc <br/>Education Lead at T-CAIREM
                                        </Typography>
                                    </figcaption>
                                </figure>
                            </Grid>
                        </Grid>
                    </Container>
            </Box>
            <Box sx={AboutStyle.teamContainer}>
                <Container {...AboutStyle.eduMessContainerMargins}>
                    <Typography {...AboutStyle.title} gutterBottom>
                        Our Current Team
                    </Typography>
                    <Container maxWidth='lg'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} sx={AboutStyle.card}>
                                <TeamCard name='Gemma Postill' title='Education Trainee Co-Lead' linkedin='https://www.linkedin.com/in/gemma-p-80481987/' email='gemmapostill@gmail.com' image='/static/gemma.png' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={AboutStyle.card}>
                                <TeamCard name='Abhishek Moturu' title='Education Trainee Co-Lead' linkedin='https://www.linkedin.com/in/abhishek-moturu/' email='abhishek.moturu@mail.utoronto.ca' image='/static/abhi.png' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={AboutStyle.card}>
                                <TeamCard name='Julie Midroni' title='Education Trainee Affiliate' linkedin='https://www.linkedin.com/in/juliemidroni/' email='julie.midroni@mail.utoronto.ca' image='/static/julie.png' />
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
            <Box sx={AboutStyle.previousTeamContainer}>
                <Container {...AboutStyle.eduMessContainerMargins}>
                    <Typography {...AboutStyle.title} gutterBottom>
                        Previous Team Members
                    </Typography>
                    <Container maxWidth='lg'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} sx={AboutStyle.card}>
                                <TeamCard name='Felipe Morgado' title='Education Lead' linkedin='https://www.linkedin.com/in/felipe-p-morgado/' email='felipe.morgado@mail.utoronto.ca' image='/static/felipe.png' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={AboutStyle.card}>
                                <TeamCard name='Vinyas Harish' title='Education Lead' linkedin='https://www.linkedin.com/in/vinyash/' email='v.harish@mail.utoronto.ca' image='/static/vin.png' />
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
            <Box sx={AboutStyle.developersContainer}>
                <Container {...AboutStyle.eduMessContainerMargins}>
                    <Typography {...AboutStyle.title} gutterBottom>
                        Developers
                    </Typography>
                    <Container maxWidth='lg'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} sx={AboutStyle.card}>
                                <TeamCard name='Rutvik Solanki' title='Software Engineer' email='rutvik.solanki@utoronto.ca' linkedin='https://www.linkedin.com/in/connectwithrutvik/' image='/static/rutvik.jpeg' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={AboutStyle.card}>
                                <TeamCard name='Saad Markod' title='Developer' email='saad.markod@mail.utoronto.ca' linkedin='https://www.linkedin.com/in/saad-makrod/' image='/static/saad.png' />
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
            <Box sx={AboutStyle.contactContainer}>
                <Container {...AboutStyle.eduMessContainerMargins}>
                    <Typography {...AboutStyle.title} gutterBottom>
                        Contact Us
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography {...AboutStyle.contactText} gutterBottom>
                                Contact Us
                            </Typography>
                            <Link href={'mailto:tcairem.comms@utoronto.ca'} target='_blank' rel="noreferrer" underline='none'>
                                tcairem.comms@utoronto.ca
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography {...AboutStyle.contactText} gutterBottom>
                                Twitter
                            </Typography>
                            <Typography {...AboutStyle.mail} gutterBottom>
                                @UofT_TCAIREM
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography {...AboutStyle.contactText} gutterBottom>
                                Website
                            </Typography>
                            <Link href={'https://tcairem.utoronto.ca/'} target='_blank' rel="noreferrer" underline='none'>
                                https://tcairem.utoronto.ca/
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography {...AboutStyle.contactText} gutterBottom>
                                Mailing Address
                            </Typography>
                            <Typography {...AboutStyle.mail} gutterBottom>
                                Temerty Faculty of Medicine <br/>1 King's College Circle <br/>Medical Sciences Building<br/>Room 2111<br/>Toronto, ON. M5S 1A8
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default About
