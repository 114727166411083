import { configureStore } from '@reduxjs/toolkit'
import booksReducer from './reducers/booksReducer'
import coursesReducer from './reducers/coursesReducer'
import favouritesReducer from './reducers/favouritesReducer'
import notificationReducer from './reducers/notificationReducer'
import papersReducer from './reducers/papersReducer'
import podcastsReducer from './reducers/podcastsReducer'
import policiesReducer from "./reducers/policiesReducer";
import ratingsReducer from './reducers/ratingsReducer'
import resourcesReducer from './reducers/resourcesReducer'
import tagsReducers from './reducers/tagsReducers'
import userReducer from './reducers/userReducer'
import usersReducer from './reducers/usersReducer'
import videosReducer from './reducers/videosReducer'

// create store for redux
const store = configureStore({
    reducer: {
        books: booksReducer,
        courses: coursesReducer,
        favourites: favouritesReducer,
        notifications: notificationReducer,
        papers: papersReducer,
        policies: policiesReducer,
        podcasts: podcastsReducer,
        ratings: ratingsReducer,
        resources: resourcesReducer,
        tags: tagsReducers,
        user: userReducer,
        users: usersReducer,
        videos: videosReducer
    }
})

export default store