import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LaunchIcon from '@mui/icons-material/Launch'
import PolicyIcon from '@mui/icons-material/Policy'
import StarIcon from '@mui/icons-material/Star'

import { PolicyListStyle } from '../styles'
import PolicyModal from '../modals/PolicyModal'

const PolicyList = (props) => {
    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <>
            <Box sx={PolicyListStyle.topContainer}>
                <Box sx={PolicyListStyle.topContainerIconContainer}>
                    <PolicyIcon sx={PolicyListStyle.icon} />
                    <Typography {...PolicyListStyle.text} gutterBottom>
                        &nbsp;Published: {props.pub_date}
                    </Typography>
                </Box>
                {props.rating?
                    <Typography {...PolicyListStyle.text}>
                        {props.rating}/5.0 <StarIcon />
                    </Typography> :
                    <Typography {...PolicyListStyle.text}>
                        No Rating Yet
                    </Typography>
                }
            </Box>
            <Box>
                <Box sx={PolicyListStyle.titleContainer}>
                    <Typography {...PolicyListStyle.title} gutterBottom>
                        {props.title}
                    </Typography>
                </Box>
                {props.tags.map(tag => <Chip sx={PolicyListStyle.chips} key={tag} label={tag} />)}
                <Box sx={PolicyListStyle.secondary}>
                    <Typography variant='p'>
                        Country: {props.country}
                    </Typography>
                </Box>
                <Box sx={PolicyListStyle.secondary}>
                    <Typography variant='p'>
                        Date Enacted: {props.enacted}
                    </Typography>
                </Box>
                <Box sx={PolicyListStyle.secondary}>
                    <Typography variant='p'>
                        Date Last Updated: {props.updated}
                    </Typography>
                </Box>
                <Box sx={PolicyListStyle.actions}>
                    <PolicyModal {...props} />
                    <Button {...PolicyListStyle.view.props} sx={PolicyListStyle.view.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                </Box>
            </Box>
            <Divider />
        </>
    )
}

export default PolicyList