import policyService from "../services/policies";
import { createSlice } from "@reduxjs/toolkit";
import { setSuccessNotification, setErrorNotification} from "./notificationReducer";

const initialState = null;

const policySlice = createSlice({
    name: 'policies',
    initialState,
    reducers: {
        setPolicies(state, action) {
            return action.payload
        },
        appendPolicy(state, action){
            if (state === null) return state
            state.push(action.payload)
        },
        destroyPolicy(state, action){
            if (state === null) return state
            return state.filter(policy => policy.id !== action.payload)
        },
        changePolicy(state, action){
            if (state === null) return state
            return state.map(policy => policy.id === action.payload.id ? action.payload : policy)
        }
    }
})

export const { setPolicies, appendPolicy, destroyPolicy, changePolicy } = policySlice.actions

export const createPolicy = (data, admin) => {
    return async dispatch => {
        try {
            const policy = await policyService.create(data)
            if(admin) {
                dispatch(appendPolicy(policy))
                dispatch(setSuccessNotification('Policy created successfully'))
            } else {
                dispatch(setSuccessNotification('Your request has been submitted'))
            }
        } catch {
            if(admin) {
                dispatch(setErrorNotification('Error creating policy, if persists please notify developer team'))
            } else {
                dispatch(setErrorNotification('Error submitting request, please try again later'))
            }
        }
    }
}

export const initializePolicies = () => {
    return async dispatch => {
        try {
            const policies = await policyService.retrieve()
            dispatch(setPolicies(policies))
        } catch {
            dispatch(setErrorNotification('Error retrieving policies'))
        }
    }
}

export const deletePolicy = (id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await policyService.destroy(id)
                dispatch(destroyPolicy(id))
                dispatch(setSuccessNotification('Policy deleted successfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting policy, if persists please notify developer team'))
            }
        } else {
            dispatch(setErrorNotification('You do not have permission to delete policies'))
        }
    }
}

export const updatePolicy = (id, data, admin) => {
    return async dispatch => {
        try {
            const policy = await policyService.update(id, data)
            dispatch(changePolicy(policy))
            dispatch(setSuccessNotification('Policy updated successfully'))
        } catch {
            if(admin) {
                dispatch(setErrorNotification('Error updating policy, if persists please notify developer team'))
            } else {
                dispatch(setErrorNotification('Error submitting request, please try again later'))
            }
        }
    }
}

export const searchPolicies = (query) => {
    return async dispatch => {
        try {
            const policies = await policyService.retrieve(query)
            dispatch(setPolicies(policies))
        } catch {
            dispatch(setErrorNotification('Error fulfilling search'))
        }
    }
}

export const refreshPolicy = (id) => {
    return async dispatch => {
        try {
            const policy = await policyService.retrieveSingle(id)
            dispatch(changePolicy(policy))
        }
        catch {
            dispatch(setErrorNotification('Error refreshing policy'))
        }
    }
}

export default policySlice.reducer