import React from 'react'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { NotFoundStyle } from '../styles'

const NotFound = () => {
    return (
        <Container sx={NotFoundStyle.container}>
            <Typography {...NotFoundStyle.title} gutterBottom>
                404
            </Typography>
            <Box sx={NotFoundStyle.imageContainer}>
                <QuestionMarkIcon sx={NotFoundStyle.icon}/>
                <Typography {...NotFoundStyle.iconText} gutterBottom>
                    Sorry the page you were looking for does not exist
                </Typography>
            </Box>
        </Container>
    )
}

export default NotFound