import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'

import PodcastCard from '../cards/PodcastCard'
import PodcastList from '../lists/PodcastList'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loading from '../loading/Loading'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ReorderIcon from '@mui/icons-material/Reorder'
import AppsIcon from '@mui/icons-material/Apps'
import MenuItem from '@mui/material/MenuItem'

import Select from 'react-select'

import { initializePodcasts, searchPodcasts } from '../../reducers/podcastsReducer'
import { initializeTags } from '../../reducers/tagsReducers'
import Pagination from '@mui/material/Pagination'

import { buildQuery, buildSearchQuery } from '../../services/utils.js/query'
import { PAGES } from '../navigation/routes'
import { PAGE_SIZE } from '../websiteConfig'
import { SearchStyle } from '../styles'

const Podcasts = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const [resourcesToShow, setResourcesToShow] = useState(null)
    const [list, setList] = useState(true)
    const [resourcesSorted, setResourcesSorted] = useState(null)
    const [sortRes, setSortRes] = useState(1)

    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [title, setTitle] = useState('')
    const [formTags, setFormTags] = useState('')

    const user = useSelector(state => state.user)
    const podcasts = useSelector(state => state.podcasts)
    const tags = useSelector(state => state.tags)
    const tagNames = tags.map(tag => { return { value: tag.name, label: tag.name }})
    const dispatch = useDispatch()

    const pageSize = PAGE_SIZE
    const [page, setPage] = useState(1)

    useEffect(() => {
        if(searchParams.toString()) {
            let formTagsValue = searchParams.get('tags')
            let startValue = searchParams.get('start_date')
            let endValue = searchParams.get('end_date')
            let titleValue = searchParams.get('title')

            if(formTagsValue) setFormTags(formTagsValue.split(',').map(tag => { return {label: tag, value: tag} }))
            if(startValue) setStart(startValue)
            if(endValue) setEnd(endValue)
            if(titleValue) setTitle(titleValue)

            const query = buildSearchQuery(startValue, endValue, titleValue, formTagsValue)

            dispatch(searchPodcasts(query))
        } else {
            dispatch(initializePodcasts())
        }
        dispatch(initializeTags())
        setPage(1)
    }, [searchParams, user])

    useEffect(() => {
        if(resourcesSorted !== null) {
            const r = (user && user.staff) ? resourcesSorted.filter(resource => resource.approved) : resourcesSorted
            setResourcesToShow(r)
            setPage(1)
        }
    }, [resourcesSorted])

    useEffect(() => {
        if(podcasts !== null) {
            const r = [...podcasts]
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return 1
                if (y.rating === null) return -1
                return y.rating - x.rating
            }))
        }
    }, [podcasts])

    const handleSort = (event) => {
        const r = [...podcasts]
        setSortRes(event.target.value)

        if (event.target.value == 1) {
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return 1
                if (y.rating === null) return -1
                return y.rating - x.rating
            }))
        } else if (event.target.value == 2) {
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return -1
                if (y.rating === null) return 1
                return x.rating - y.rating
            }))
        } else if (event.target.value == 3) {
            setResourcesSorted(r.sort((x, y) => new Date(x.pub_date) - new Date(y.pub_date)))
        } else {
            setResourcesSorted(r.sort((x, y) => new Date(y.pub_date) - new Date(x.pub_date)))
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        let formTagsValue = null
        let startValue = null
        let endValue = null
        let titleValue = null
        if(formTags && formTags.length > 0) formTagsValue = formTags.map(tag => tag.value)
        if(start) startValue = start
        if(end) endValue = end
        if(title) titleValue = title

        const query = buildQuery(startValue, endValue, titleValue, formTagsValue)
        const currentQuery = buildSearchQuery(searchParams.get('start_date'), searchParams.get('end_date'), searchParams.get('title'), searchParams.get('tags'))

        if(query !== currentQuery) {
            setResourcesToShow(null)
            navigate(`${PAGES.podcasts.path}?${query}`)
        }
    }

    return (
        <Container sx={SearchStyle.container}>
            <Typography {...SearchStyle.title} gutterBottom >
                Podcasts
            </Typography>
            <form style={SearchStyle.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            sx={{width: '100%'}}
                            variant='outlined'
                            label='Resource Title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{width: '100%'}}
                                    variant='outlined'
                                    type='date'
                                    label='Start Date'
                                    InputLabelProps={{ shrink: true }}
                                    value={start}
                                    onChange={(e) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{width: '100%'}}
                                    variant='outlined'
                                    type='date'
                                    label='End Date'
                                    InputLabelProps={{ shrink: true }}
                                    value={end}
                                    onChange={(e) => setEnd(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={tagNames}
                            value={formTags}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    minHeight: '56px'
                                })
                            }}
                            onChange={(value) => setFormTags(value)}
                            placeholder='Select Key Words'
                        />
                    </Grid>
                </Grid>
                <Box sx={SearchStyle.buttonLocation}>
                    <Button
                        variant="contained"
                        disabled={resourcesToShow === null}
                        type='submit'
                    >
                    {resourcesToShow === null ?
                        <CircularProgress
                        size={24}
                        sx={{
                            color: '#FFF',
                        }}
                        /> : <>Search</>
                    }
                    </Button>
                </Box>
            </form>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', paddingLeft: '50%', width: '50%'}}>
                <TextField
                        value={sortRes}
                        select
                        label="Sort By"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleSort}
                        sx={{width: '50%'}}
                    >
                    <MenuItem value={1}>Rating Ascending</MenuItem>
                    <MenuItem value={2}>Rating Descending</MenuItem>
                    <MenuItem value={3}>Date Ascending</MenuItem>
                    <MenuItem value={4}>Date Descending</MenuItem>
                </TextField>
                <Box sx={SearchStyle.viewContainer}>
                    <IconButton sx={{ color: list? '#002A5C' : '#CECFCB'}} onClick={() => setList(true)}>
                        <ReorderIcon />
                    </IconButton>
                    <IconButton sx={{ color: !list? '#002A5C' : '#CECFCB'}} onClick={() => setList(false)}>
                        <AppsIcon />
                    </IconButton>
                </Box>
            </Box>
            {resourcesToShow === null && <Loading />}
            {resourcesToShow !== null && ((resourcesToShow.length > 0)?
                <>
                    <Grid container spacing={2}>
                        {list? resourcesToShow.slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(podcast =>
                            <Grid key={podcast.id} item xs={12} >
                                <PodcastList {...podcast}/>
                            </Grid>
                        ) : resourcesToShow.slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(podcast =>
                            <Grid key={podcast.id} item xs={12} md={4} sx={SearchStyle.card}>
                                <PodcastCard {...podcast}/>
                            </Grid>
                        )}
                    </Grid>
                    <Pagination sx={SearchStyle.pagination} count={Math.ceil(resourcesToShow.length/pageSize)} page={page} onChange={(event, value) => setPage(value)} />
                </> :
                <Typography {...SearchStyle.noResources.props} gutterBottom sx={SearchStyle.noResources.style}>
                    No Resources Found
                </Typography>)
            }
            <Box sx={{textAlign: 'center', padding: '10px'}}>
                <Typography variant='overline' component='p'>
                    All resources provided are strictly owned and distributed by the creators indicated on the resource
                </Typography>
            </Box>
        </Container>
    )
}

export default Podcasts