import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import { PAGES } from '../navigation/routes'
import Loading from '../loading/Loading'

import { setErrorNotification } from '../../reducers/notificationReducer'
import { activateUser } from '../../reducers/userReducer'
import { ActivateStyle } from '../styles'

const Activate = () => {
    const user = useSelector(state => state.user)

    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    // send request to server to activate then redirect to home page
    useEffect(() => {
        if (searchParams.get('token') && searchParams.get('id')) {
            dispatch(activateUser(searchParams.get('id'), searchParams.get('token')))
            navigate(PAGES.home.path)
        } else {
            dispatch(setErrorNotification('Invalid activation link!'))
            navigate(PAGES.home.path)
        }
    }, [])

    if(user && (!user.anon || user.id != searchParams.get('id'))) {
        return (
            <Navigate to={PAGES.home.path} />
        )
    }

    return (
        <Container {...ActivateStyle.container.props} sx={ActivateStyle.container.style} >
            <Loading />
        </Container>
    )
}

export default Activate