export const buildQuery = (start_date=null, end_date=null, title=null, tags=null) => {
    let query = ''

    if(start_date) query = `start_date=${start_date}`

    if(end_date) query += (query === '')? `end_date=${end_date}` : `&end_date=${end_date}`

    if(title) query += (query === '')? `title=${title}` : `&title=${title}`

    if(tags) query += (query === '')? `tags=${tags.join(',')}` : `&tags=${tags.join(',')}`

    return query
}

export const buildSearchQuery = (start_date=null, end_date=null, title=null, tags=null) => {
    let query = ''

    if(start_date) query = `start_date=${start_date}`

    if(end_date) query += (query === '')? `end_date=${end_date}` : `&end_date=${end_date}`

    if(title) query += (query === '')? `title=${title}` : `&title=${title}`

    if(tags) query += (query === '')? `tags=${tags}` : `&tags=${tags}`

    return query
}

export const searchBarQuery = (search=null) => {
    let query = ''
    if(search) query = `search=${search}`
    return query
}