import React, {useEffect} from 'react'
import { useDispatch } from 'react-redux'
import './App.css'

import NavBar from './components/navigation/NavBar'
import About from './components/pages/About'
import Books from './components/pages/Books'
import Courses from './components/pages/Courses'
import Favourites from './components/pages/Favourites'
import Home from './components/pages/Home'
import Start from './components/pages/Start'
import Login from './components/pages/Login'
import ForgotPassword from './components/pages/ForgotPassword'
import Papers from './components/pages/Papers'
import Policies from "./components/pages/Policies";
import Podcasts from './components/pages/Podcasts'
import Profile from './components/pages/Profile'
import Ratings from './components/pages/Ratings'
import Request from './components/pages/Request'
import Resources from './components/pages/Resources'
import Videos from './components/pages/Videos'
import SignUp from './components/pages/SignUp'
import NotFound from './components/pages/NotFound'
import Admin from './components/admin/Admin'
import ManageAdmin from './components/admin/ManageAdmin'
import ManageRequests from './components/admin/ManageRequests'
import ManageResources from './components/admin/ManageResources'
import ConfirmForgotPassword from './components/pages/ConfirmForgotPassword'
import Activate from './components/pages/Activate'
import ManageTags from './components/admin/ManageTags'

import SuccessNotification from './components/notifications/SuccessNotification'
import ErrorNotification from './components/notifications/ErrorNotification'

import { PAGES } from './components/navigation/routes'

import { ThemeProvider } from '@mui/material/styles'
import { getTheme } from './components/styles'

import { Routes, Route } from 'react-router-dom'
import Footer from './components/footer/Footer'

import { setUserOnRefresh } from './reducers/userReducer'

// ScrollToTop is a wrapper for the router to make the page go to the top when swapping to new component
// router used to do this for you weird that it doesn't anymore
import ScrollToTop from './components/navigation/ScrollToTop'

const App = () => {
    const theme = getTheme('light')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setUserOnRefresh())
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <NavBar />
            <SuccessNotification />
            <ErrorNotification />
            <ScrollToTop>
                <Routes>
                    <Route path='*' element={<NotFound />} />
                    <Route path={PAGES.home.path} element={<Home />} />
                    <Route path={PAGES.about.path} element={<About />} />
                    <Route path={PAGES.start.path} element={<Start />} />
                    <Route path={PAGES.resources.path} element={<Resources />} />
                    <Route path={PAGES.login.path} element={<Login />} />
                    <Route path={PAGES.forgot_password.path} element={<ForgotPassword />} />
                    <Route path={PAGES.confirm_forgot_password.path} element={<ConfirmForgotPassword />} />
                    <Route path={PAGES.sign_up.path} element={<SignUp />} />
                    <Route path={PAGES.activate.path} element={<Activate />} />
                    <Route path={PAGES.books.path} element={<Books />} />
                    <Route path={PAGES.courses.path} element={<Courses />} />
                    <Route path={PAGES.podcasts.path} element={<Podcasts />} />
                    <Route path={PAGES.papers.path} element={<Papers />} />
                    <Route path={PAGES.policies.path} element={<Policies />} />
                    <Route path={PAGES.videos.path} element={<Videos />} />
                    <Route path={PAGES.profile.path} element={<Profile />} />
                    <Route path={PAGES.favourites.path} element={<Favourites />} />
                    <Route path={PAGES.ratings.path} element={<Ratings />} />
                    <Route path={PAGES.request.path} element={<Request />} />
                    <Route path={PAGES.admin_portal.path} element={<Admin />} />
                    <Route path={PAGES.manage_admin.path} element={<ManageAdmin />} />
                    <Route path={PAGES.manage_requests.path} element={<ManageRequests />} />
                    <Route path={PAGES.manage_resources.path} element={<ManageResources />} />
                    <Route path={PAGES.manage_tags.path} element={<ManageTags />} />
                </Routes>
            </ScrollToTop>
            <Footer />
        </ThemeProvider>
    )
}

export default App
