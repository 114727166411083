import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    successNotification: null,
    errorNotification: null
}

// toolkit sets up the redux and state
const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setSuccessNotification(state, action) {
            return {
                ...state,
                successNotification: action.payload
            }
        },
        setErrorNotification(state, action) {
            return {
                ...state,
                errorNotification: action.payload
            }
        }
    }
})

export const { setSuccessNotification, setErrorNotification } = notificationSlice.actions
export default notificationSlice.reducer