import {createTheme} from '@mui/material/styles'

const GREY = '#CECFCB'
const NAVY = '#002A5C'
const RED = '#D22742'
const RED_ACCENT = '#FBD1D1'
const GREEN = '#B7E481'
const GREEN_ACCENT = '#E1FBD1'
const PURPLE = '#9F6DDF'
const PURPLE_ACCENT = '#E6D1FB'
const YELLOW = '#9D7C08'
const YELLOW_ACCENT = '#FBF9D1'
const BROWN = '#8B572A'
const BROWN_ACCENT = '#f6bba3'
const BLUE = '#008BB0'
const BLUE_ACCENT = '#D1D5FB'
const BLACK = '#000'
const WHITE = '#FFF'
const APPROVE_GREEN = '#32A852'
const APPROVE_GREEN_HOVER = '#1D6330'
const REJECT_RED = '#851829'
const SECTION_GREY = '#EFEFEF'
const PREVIOUS_TEAM_GREY = '#F5F5F5'
const DIVIDER_GREY = '#00000066'

export const getTheme = (mode) => {
    if (mode === 'light') {
        return createTheme({
            palette: {
                mode: 'light',
                primary: {
                    main: NAVY
                },
            },
            typography: {
                fontFamily: [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    'Open Sans',
                    'Fira Sans',
                    'Roboto',
                    "'Segoe UI'",
                    "'Helvetica Neue'",
                    'Arial',
                    'sans-serif',
                    "'Apple Color Emoji'",
                    "'Segoe UI Emoji'",
                    "'Segoe UI Symbol'",
                ].join(','),
            },
        })
    } else {
        return null
    }
}

const BaseCardStyle = {
    mobile: {
        card: {
            card: {
                display: {
                    xs: 'flex',
                    md: 'none'
                },
                width: '100%',
                boxShadow: 1,
                borderRadius: '20px',
                transition: 'ease 0.5s',
                '&:hover': {
                    boxShadow: 3,
                }
            },
            variant: 'outlined'
        },
        icon: {
            icon: {
                fontSize: 'large',
                color: '',
                backgroundColor: '',
                borderRadius: '100px',
                padding: '10px',
                width: '10vw',
                height: '10vw',
                maxWidth: 50,
                maxHeight: 50,
            },
            box: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '20%'
            }
        },
        body: {
            container: {
                display: 'flex',
                flexDirection: 'column',
                width: '75%'
            },
            meta: {
                flex: '1 0 auto'
            },
            text: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            title: {
                component: 'div',
                textOverflow: 'ellipsis',
                variant: 'h6',
                fontWeight: 'bold',
            },
            rating: {
                props: {
                    component: 'div',
                    variant: 'p',
                    fontWeight: 'bold',
                },
                style: {
                    display: 'flex',
                    alignItems: 'center'
                }
            },
            audience: {
                variant: 'subtitle1',
                color: 'text.secondary',
                component: 'div'
            },
            badge: {
                margin: '2px',
                fontWeight: 'bold'
            }
        },
        button: {
            props: {
                variant: 'outlined'
            },
            style: {
                marginRight: '10px'
            }
        },
        buttonContainer: {
            style: {
                marginBottom: '10px'
            }
        }
    },
    desktop: {
        card: {
            card: {
                display: {
                    xs: 'none',
                    md: 'inline-block'
                },
                boxShadow: 1,
                borderRadius: '20px',
                width: '320px',
                height: '530px',
                transition: 'ease 0.5s',
                '&:hover': {
                    boxShadow: 3
                }
            },
            variant: 'outlined'
        },
        icon: {
            icon: {
                fontSize: 'large',
                color: '',
                backgroundColor: '',
                borderRadius: '100px',
                padding: '10px',
                marginTop: '10px',
                height: '100px',
                width: '100px',
                margin: '15px 0 10px 0',
            },
            box: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        body: {
            cardContent: {
                paddingBottom: '0px',
                height: '300px'
            },
            container: {
                display: 'flex',
                flexDirection: 'column',
                width: '75%'
            },
            meta: {
                flex: '1 0 auto'
            },
            title: {
                props: {
                    component: 'div',
                    variant: 'h6',
                    fontWeight: 'bold'
                },
                style: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'no-wrap'
                }
            },
            ratingContainer: {
                display: 'flex',
                width: '100%',
                justifyContent: 'end'
            },
            rating: {
                props: {
                    component: 'div',
                    variant: 'p',
                    fontWeight: 'bold',
                },
                style: {
                    display: 'flex',
                    alignItems: 'center'
                }
            },
            audience: {
                variant: 'subtitle1',
                color: 'text.secondary',
                component: 'div'
            },
            badge: {
                margin: '2px',
                fontWeight: 'bold'
            }
        },
        button: {
            props: {
                variant: 'outlined'
            },
            style: {
                marginRight: '10px'
            }
        },
        buttonContainer: {
            style: {
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'center'
            }
        }
    }
}

export const BookCardStyle = structuredClone(BaseCardStyle)
BookCardStyle.mobile.icon.icon.color = RED
BookCardStyle.mobile.icon.icon.backgroundColor = RED_ACCENT
BookCardStyle.desktop.icon.icon.color = RED
BookCardStyle.desktop.icon.icon.backgroundColor = RED_ACCENT

export const PolicyCardStyle = structuredClone(BaseCardStyle)
PolicyCardStyle.mobile.icon.icon.color = BROWN
PolicyCardStyle.mobile.icon.icon.backgroundColor = BROWN_ACCENT
PolicyCardStyle.desktop.icon.icon.color = BROWN
PolicyCardStyle.desktop.icon.icon.backgroundColor = BROWN_ACCENT

export const PaperCardStyle = structuredClone(BaseCardStyle)
PaperCardStyle.mobile.icon.icon.color = GREEN
PaperCardStyle.mobile.icon.icon.backgroundColor = GREEN_ACCENT
PaperCardStyle.desktop.icon.icon.color = GREEN
PaperCardStyle.desktop.icon.icon.backgroundColor = GREEN_ACCENT

export const PodcastCardStyle = structuredClone(BaseCardStyle)
PodcastCardStyle.mobile.icon.icon.color = BLUE
PodcastCardStyle.mobile.icon.icon.backgroundColor = BLUE_ACCENT
PodcastCardStyle.desktop.icon.icon.color = BLUE
PodcastCardStyle.desktop.icon.icon.backgroundColor = BLUE_ACCENT

export const CourseCardStyle = structuredClone(BaseCardStyle)
CourseCardStyle.mobile.icon.icon.color = PURPLE
CourseCardStyle.mobile.icon.icon.backgroundColor = PURPLE_ACCENT
CourseCardStyle.desktop.icon.icon.color = PURPLE
CourseCardStyle.desktop.icon.icon.backgroundColor = PURPLE_ACCENT

export const VideoCardStyle = structuredClone(BaseCardStyle)
VideoCardStyle.mobile.icon.icon.color = YELLOW
VideoCardStyle.mobile.icon.icon.backgroundColor = YELLOW_ACCENT
VideoCardStyle.desktop.icon.icon.color = YELLOW
VideoCardStyle.desktop.icon.icon.backgroundColor = YELLOW_ACCENT

export const TeamCardStyle = {
    mobile: {
        card: {
            card: {
                display: {
                    xs: 'flex',
                    sm: 'none'
                },
                borderRadius: '20px',
                transition: 'ease 0.5s',
                border: `solid 2px ${GREY}`,
                paddingLeft: '20px',
                width: '100%',
                height: '155px',
                '&:hover': {
                    borderColor: NAVY,
                    marginTop: '-10px',
                    marginBottom: '10px'
                }
            },
            variant: 'outlined'
        },
        icon: {
            icon: {
                borderRadius: '50%',
                padding: '10px',
                width: '80px',
                height: '80px',
            },
            box: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '20%'
            }
        },
        body: {
            container: {
                display: 'flex',
                flexDirection: 'column',
                width: '75%'
            },
            meta: {
                flex: '1 0 auto'
            },
            text: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            title: {
                component: 'div',
                variant: 'h5',
                fontWeight: 'bold'
            },
            tcairemtitle: {
                props: {
                    variant: 'subtitle1',
                    color: 'text.secondary',
                    component: 'div'
                },
                style: {
                    marginBottom: '10px'
                }
            },
            link: {
                props: {
                    target: '_blank',
                    rel: 'noreferrer',
                    underline: 'none'
                },
                style: {
                    color: WHITE,
                    backgroundColor: NAVY,
                    padding: '12px 6px 3px 6px',
                    marginRight: '10px',
                    borderRadius: '5px',
                    transition: 'ease 0.5s',
                    '&:hover': {
                        backgroundColor: BLUE
                    }
                }
            }
        }
    },
    desktop: {
        card: {
            card: {
                display: {
                    xs: 'none',
                    sm: 'inline-block'
                },
                width: '200px',
                height: '320px',
                borderRadius: '20px',
                transition: 'ease 0.5s',
                border: `solid 2px ${GREY}`,
                textAlign: 'center',
                padding: '30px',
                '&:hover': {
                    borderColor: NAVY,
                    marginTop: '-10px',
                    marginBottom: '10px'
                }
            },
            variant: 'outlined'
        },
        icon: {
            icon: {
                borderRadius: '50%',
                padding: '10px',
                marginTop: '10px',
                height: '140px',
                width: '140px'
            },
            box: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        body: {
            container: {
                display: 'flex',
                flexDirection: 'column',
                width: '75%'
            },
            meta: {
                flex: '1 0 auto'
            },
            text: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            },
            title: {
                props: {
                    component: 'div',
                    variant: 'h6',
                    fontWeight: 'bold'
                },
                style: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'no-wrap'
                }
            },
            tcairemtitle: {
                props: {
                    variant: 'subtitle1',
                    color: 'text.secondary',
                    component: 'div'
                },
                style: {
                    height: '56px'
                }
            },
            linkContainer: {
                display: 'flex',
                justifyContent: 'center'
            },
            link: {
                props: {
                    target: '_blank',
                    rel: 'noreferrer',
                    underline: 'none'
                },
                style: {
                    color: WHITE,
                    backgroundColor: NAVY,
                    padding: '6px 6px 3px 6px',
                    borderRadius: '5px',
                    marginRight: '10px',
                    transition: 'ease 0.5s',
                    '&:hover': {
                        backgroundColor: BLUE
                    }
                }
            }
        }
    }
}

export const NavBarStyle = {
    appbar: {
        props: {
            position: 'fixed'
        },
        style: {
            backgroundColor: WHITE,
            color: NAVY
        }
    },
    navContainer: {
        maxWidth: 'xl'
    },
    reactLink: {
        textDecoration: 'none'
    },
    navLogo: {
        display: {
            xs: 'none',
            md: 'flex',
            width: '50px',
            height: '50px'
        },
        mr: 1
    },
    navHeader: {
        props: {
            variant: 'h6'
        },
        style: {
            mr: 2,
            display: {
                xs: 'none',
                md: 'flex'
            },
            fontWeight: 'bold',
            color: NAVY,
            textDecoration: 'none',
        }
    },
    linkContainer: {
        flexGrow: 1,
        display: {
            xs: 'none',
            md: 'flex'
        }
    },
    link: {
        my: 2,
        color: NAVY,
        display: 'block',
        fontWeight: 'bold'
    },
    dropDownLink: {
        my: 2,
        color: NAVY,
        display: 'flex',
        alignContent: 'center',
        fontWeight: 'bold'
    },
    dropDownBox: {
        position: 'absolute',
        top: '67px'
    },
    userBox: {
        display: {
            xs: 'none',
            md: 'flex'
        }
    },
    userText: {
        my: 2,
        color: NAVY,
        fontWeight: 'bold'
    },
    userDropDown: {
        position: 'absolute',
        top: '67px',
        right: '-20px'
    }
}

export const DropDownStyle = {
    container: {
        bgcolor: WHITE,
        boxShadow: 3,
        borderTop: 'none'
    },
    list: {
        props: {
            component: 'nav',
        },
        style: {
            width: '100%',
            p: 0
        }
    },
    reactLink: {
        textDecoration: 'none',
        color: BLUE_ACCENT
    },
    listIcon: {
        color: NAVY
    },
    listText: {
        fontWeight: 'bold',
        color: NAVY
    }
}

export const SearchBarStyle = {
    search: {
        display: {
            xs: 'none',
            md: 'flex'
        }
    }
}

export const MobileNavStyle = {
    navIconBox: {
        flexGrow: 1,
        display: {
            xs: 'flex',
            md: 'none'
        }
    },
    navIcon: {
        size: 'large',
        color: 'inherit'
    },
    headerContainer: {
        flexGrow: 1,
        display: {
            xs: 'flex',
            md: 'none'
        },
        alignItems: 'center'
    },
    reactLink: {
        textDecoration: 'none',
        color: BLUE_ACCENT
    },
    headerIcon: {
        display: {
            xs: 'flex',
            md: 'none',
            width: '30px',
            height: '30px'
        },
        mr: 1
    },
    header: {
        props: {
            variant: 'h5',
        },
        style: {
            mr: 2,
            display: {
                xs: 'flex',
                md: 'none'
            },
            flexGrow: 1,
            fontWeight: 'bold',
            color: NAVY,
            textDecoration: 'none',
            fontSize: '18px'
        },
    },
    dropDownXS: {
        width: '110vw',
        position: 'absolute',
        top: '48px',
        left: '-25px'
    },
    dropDownS: {
        width: '110vw',
        position: 'absolute',
        top: '55px',
        left: '-25px'
    },
    listContainer: {
        width: '110vw',
        display: {
            xs: 'flex',
            md: 'none'
        },
        backgroundColor: WHITE
    },
    list: {
        props: {
            component: 'nav'
        },
        style: {
            width: '100%',
            fontWeight: 'bold',
            p: 0
        }
    },
    listButton: {
        paddingLeft: 4.5
    },
    listIcon: {
        color: NAVY
    },
    listText: {
        fontWeight: 'bold',
        color: NAVY
    }
}

export const FooterStyle = {
    container: {
        paddingTop: '20px',
        paddingBottom: '20px',
        borderTop: `solid 2px ${GREY}`
    },
    margins: {
        maxWidth: 'xl'
    },
    links: {
        style: {
            color: WHITE,
            backgroundColor: NAVY,
            padding: '12px 6px 3px 6px',
            borderRadius: '5px',
            transition: 'ease 0.5s',
            '&:hover': {
                backgroundColor: BLUE
            }
        },
        props: {
            target: '_blank',
            rel: 'noreferrer',
            underline: 'none'
        }
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    para: {
        props: {
            variant: 'p',
            component: 'div'
        },
        style: {
            display: {
                xs: 'none',
                md: 'flex'
            }
        }
    },
    linkContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        flexDirection: 'column'
    },
    hideLink: {
        display: {
            xs: 'none',
            md: 'inline'
        }
    },
    temertyContainer: {
        variant: 'p',
        component: 'div',
        fontWeight: 'bold'
    },
    temertyColor: {
        color: NAVY
    },
    medicineColor: {
        color: BLUE
    }
}

export const AdminStyle = {
    loading: {
        props: {
            maxWidth: 'md'
        },
        style: {
            marginTop: '100px',
            minHeight: '80vh',
            marginBottom: '40px'
        }
    },
    container: {
        props: {
            maxWidth: 'lg'
        },
        style: {
            marginTop: '100px',
            textAlign: 'center'
        }
    },
    title: {
        variant: 'h3'
    },
    secondary: {
        marginBottom: '20px'
    },
    secondaryText: {
        variant: 'p',
        component: 'div'
    },
    navLink: {
        textDecoration: 'none'
    },
    navContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        height: '40px',
        color: WHITE,
        backgroundColor: NAVY,
        fontWeight: 'bold',
        padding: '10px',
        marginRight: '10px',
        marginBottom: '10px',
        transition: 'ease 0.5s',
        '&:hover': {
            backgroundColor: BLUE
        }
    },
    analytics: {
        props: {
            maxWidth: 'lg'
        },
        style: {
            marginTop: '40px'
        }
    },
    analyticsTitle: {
        style: {
            width: '100%',
            textAlign: 'center'
        },
        props: {
            variant: 'h3'
        }
    },
    analyticsGrid: {
        marginTop: '20px',
        marginBottom: '40px'
    },
    analyticsNumContainer: {
        minHeight: '150px'
    },
    analyticsNum: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'ease 0.5s',
        boxShadow: '0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2)',
        borderRadius: '10px',
        '&:hover': {
            boxShadow: '0  5px 20px rgba(154,160,185,0.5), 0 15px 60px rgba(166,173,201,0.2)'
        }
    },
    analyticsNumText: {
        variant: 'h5'
    },
    graphContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'ease 0.5s',
        padding: '10px 0px 10px 0px',
        boxShadow: '0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2)',
        borderRadius: '10px',
        '&:hover': {
            boxShadow: '0  5px 20px rgba(154,160,185,0.5), 0 15px 60px rgba(166,173,201,0.2)'
        }
    },
    rating: {
        width: '100%',
        height: '100%',
        padding: '10px 5px 10px 5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'ease 0.5s',
        boxShadow: '0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2)',
        borderRadius: '10px',
        '&:hover': {
            boxShadow: '0  5px 20px rgba(154,160,185,0.5), 0 15px 60px rgba(166,173,201,0.2)'
        }
    },
    ratingTitle: {
        variant: 'h4'
    },
    ratingCardContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    noRatingTitle: {
        variant: 'h5'
    }
}

export const AdminManage = {
    loading: {
        props: {
            maxWidth: 'md'
        },
        style: {
            marginTop: '100px',
            minHeight: '80vh',
            marginBottom: '40px'
        }
    },
    container: {
        marginTop: '100px',
        minHeight: '70vh',
        marginBottom: '40px'
    },
    title: {
        variant: 'h3'
    },
    filterTitle: {
        variant: 'h5'
    },
    checkbox: {
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'row'
    },
    table: {
        minWidth: 650
    },
    typeAlign: {
        align: 'left'
    },
    columnAlign: {
        align: 'center'
    },
    tableRow: {
        '&:last-child td, &:last-child th': {
            border: 0
        }
    },
    approve: {
        style: {
            backgroundColor: APPROVE_GREEN,
            '&:hover': {
                backgroundColor: APPROVE_GREEN_HOVER
            },
            color: WHITE
        },
        props: {
            variant: 'contained'
        }
    },
    reject: {
        style: {
            backgroundColor: RED,
            '&:hover': {
                backgroundColor: REJECT_RED
            },
            color: WHITE
        },
        props: {
            variant: 'contained'
        }
    },
    pagination: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    noResources: {
        textAlign: 'center',
        width: '100%',
        marginTop: '40px'
    },
    noResourceIcon: {
        width: '30vh',
        height: '30vh',
        backgroundColor: BLUE_ACCENT,
        color: NAVY,
        borderRadius: '100%',
        marginBottom: '40px'
    },
    noResourceText: {
        variant: 'h4',
        component: 'div'
    },
    tableCell: {
        props: {
            component: 'th',
            scope: 'row',
        },
        style: {
            maxWidth: '200px'
        }
    },
    subheading: {
        variant: 'h4'
    },
    subheading2: {
        variant: 'h5'
    },
    subheadingMT: {
        marginTop: '20px'
    },
    subheadingMB: {
        marginBottom: '20px'
    },
    tagForm: {
        width: '100%',
        marginBottom: '20px'
    }
}

const BaseListStyle = {
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    topContainerIconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        color: '',
        backgroundColor: '',
        borderRadius: '100px',
        padding: '10px'
    },
    text: {
        variant: 'p'
    },
    titleContainer: {
        marginTop: '10px'
    },
    title: {
        variant: 'h5'
    },
    description: {
        variant: 'p'
    },
    chips: {
        margin: '5px 10px 5px 0px'
    },
    secondary: {
        margin: '5px 0px 10px 0px'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        margin: '5px 0px 10px 0px'
    },
    view: {
        props: {
            variant: 'outlined'
        },
        style: {
            marginRight: '5px'
        }
    }
}

export const BookListStyle = structuredClone(BaseListStyle)
BookListStyle.icon.color = RED
BookListStyle.icon.backgroundColor = RED_ACCENT

export const PolicyListStyle = structuredClone(BaseListStyle)
PolicyListStyle.icon.color = BROWN
PolicyListStyle.icon.backgroundColor = BROWN_ACCENT

export const CourseListStyle = structuredClone(BaseListStyle)
CourseListStyle.icon.color = PURPLE
CourseListStyle.icon.backgroundColor = PURPLE_ACCENT

export const PaperListStyle = structuredClone(BaseListStyle)
PaperListStyle.icon.color = GREEN
PaperListStyle.icon.backgroundColor = GREEN_ACCENT

export const PodcastListStyle = structuredClone(BaseListStyle)
PodcastListStyle.icon.color = BLUE
PodcastListStyle.icon.backgroundColor = BLUE_ACCENT

export const VideoListStyle = structuredClone(BaseListStyle)
VideoListStyle.icon.color = YELLOW
VideoListStyle.icon.backgroundColor = YELLOW_ACCENT

export const LoadingStyle = {
    container: {
        width: '100%',
        marginBottom: '40vh'
    },
    loader: {
        color: 'primary'
    }
}

export const AboutStyle = {
    eduMessContainer: {
        marginTop: '80px',
        paddingTop: '20px',
        paddingBottom: '40px'
    },
    eduMessContainerMargins: {
        maxWidth: 'xl'
    },
    eduGrid: {
        width: '100%',
        height: '100%'
    },
    eduGridMess: {
        height: '100%',
        width: '100%'
    },
    title: {
        variant: 'h2',
        component: 'div',
        textAlign: 'center',
    },
    eduText: {
        variant: 'p',
        component: 'div'
    },
    imageContainer: {
        display: {
            xs: 'none',
            md: 'flex'
        },
        alignItems: 'center',
        justifyContent: 'center'
    },
    imageAlign: {
        textAlign: 'center'
    },
    image: {
        width: '400px'
    },
    caption: {
        variant: 'caption',
        component: 'div'
    },
    teamContainer: {
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: SECTION_GREY
    },
    previousTeamContainer: {
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: PREVIOUS_TEAM_GREY
    },
    developersContainer: {
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: SECTION_GREY
    },
    card: {
        display: 'flex',
        justifyContent: 'center'
    },
    contactContainer: {
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    contactText: {
        variant: 'p',
        fontWeight: 'bold',
        component: 'div'
    },
    mail: {
        variant: 'p',
        component: 'div'
    }
}

export const StartStyle = {
    HeadContainer: {
        backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(/static/landing_image.jpg)',
        backgroundSize: 'cover',
        height: '35vh',
        backgroundPosition: 'center',
        marginTop: '4.2rem', // 90px
        paddingTop: '1.25rem', // 20px
        paddingBottom: '2.5rem', // 40px
        backgroundRepeat: 'no-repeat',
    },
    HeadContainerMargins: {
        maxWidth: 'xl'
    },
    HeadTitle: {
        variant: 'h2',
        component: 'div',
        textAlign: 'left',
        color: WHITE,
        width: '80%',
        margin: 'auto',
    },
    HeadContent: {
        variant: 'h5',
        component: 'div',
        textAlign: 'left',
        width: '80%',
        margin: 'auto',
        color: WHITE,
    },
    SelectionContainer: {
        paddingTop: '4rem', // 40px
        paddingBottom: '3rem', // 40px
        backgroundColor: SECTION_GREY,
    },
    SelectionContainerMargins: {
        maxWidth: 'xl'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        backgroundColor: '#fff',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    cardContent: {
        textAlign: 'center',
        fontSize: '20px',
    },
    cardIcon: {
        fontSize: '120px',
        color: '#3f51b5',
        marginBottom: '20px',
    },
    cardTitle: {
        fontSize: '40px',
        fontWeight: 'bold',
        color: '#333',
    },
    cardLink: {
        fontSize: '16px',
        color: '#3f51b5',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    SelectionText: {
        marginBottom: '1.25rem',
        fontWeight: 'bold',
        fontSize: '1.25rem'
    },
    SelectionIcon: {
        fontSize: '900%',
        color: NAVY
    },
    SectionContainer: {
        padding: '80px 0',
        backgroundColor: '#f5f5f5',
    },
    SectionContainerMargins: {
        margin: '0 auto',
        maxWidth: 'lg',
    },
    SectionContent: {
        fontSize: '18px',
        fontWeight: '400',
        color: '#333',
        marginBottom: '20px',
    },
    DropdownContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        '@media (min-width: 600px)': {
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
    },
    DropdownSection: {
        flex: '1 1 calc(100% - 20px)',
        backgroundColor: '#fff',
        color: '#333',
        fontSize: '18px',
        fontStyle: 'bold',
        padding: '5px',
        borderRadius: '8px',
        '@media (min-width: 600px)': {
            flex: '1 1 calc(100% - 20px)',
        },
        '@media (min-width: 960px)': {
            flex: '1 1 calc(100% - 20px)',
        },
    },
    Link: {
        textDecoration: 'underline',
        color: NAVY
    }
}

export const ActivateStyle = {
    container: {
        props: {
            maxWidth: 'md'
        },
        style: {
            marginTop: '100px',
            minHeight: '80vh',
            marginBottom: '40px'
        }
    }
}

// src/styles.js
export const containerStyle = {
    padding: '20px',
};

export const dropdownHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '5px',
};

export const dropdownContentStyle = {
    padding: '10px',
    borderLeft: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
};

export const dropdownContainerStyle = {
    marginBottom: '10px',
};

export const SearchStyle = {
    container: {
        marginTop: '90px',
        minHeight: '80vh'
    },
    title: {
        variant: 'h3',
        component: 'div'
    },
    form: {
        marginBottom: '20px'
    },
    buttonLocation: {
        display: {
            xs: 'inline-block',
            sm: 'flex'
        },
        justifyContent: 'end',
        marginTop: '10px'
    },
    filterHeadingContainer: {
        marginTop: '10px'
    },
    filterHeading: {
        variant: 'p',
        fontWeight: 'bold',
        component: 'div'
    },
    filtersContainer: {
        marginBottom: '15px'
    },
    groupsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    formGroup: {
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'row'
    },
    viewContainer: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        width: '100%',
        margin: '10px 0px 10px 0px'
    },
    pagination: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    card: {
        display: 'flex',
        justifyContent: 'center'
    },
    noResources: {
        props: {
            variant: 'h5',
            component: 'div'
        },
        style: {
            textAlign: 'center',
            marginBottom: '24vh'
        }
    },
    verticalFormGroup: {
        display: 'flex',
        flexDirection: 'column'
    }
}

export const ForgotStyle = {
    loading: {
        marginTop: '90px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        height: '100vh'
    },
    margins: {
        maxWidth: 'xl'
    },
    title: {
        style: {
            width: '100%',
            textAlign: 'center',
            marginBottom: '10px',
            marginTop: '10px'
        },
        props: {
            variant: 'h3',
            component: 'div'
        }
    },
    googleContainer: {
        width: '100%',
        marginTop: '20px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    google: {
        props: {
            variant: 'outlined'
        },
        style: {
            marginTop: '10px',
            marginBottom: '10px'
        }
    },
    divider: {
        color: DIVIDER_GREY
    },
    secondary: {
        style: {
            width: '100%',
            textAlign: 'center',
            marginBottom: '40px',
            marginTop: '10px'
        },
        props: {
            variant: 'p',
            component: 'div'
        }
    },
    form: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    button: {
        style: {
            width: '40%',
            marginTop: '10px',
            marginBottom: '10px'
        },
        props: {
            variant: 'contained'
        }
    },
    register: {
        width: '100%',
        textAlign: 'left',
        marginBottom: '20px'
    },
    registerText: {
        props: {
            variant: 'p',
            component: 'span',
            fontWeight: 'bold'
        },
        style: {
            color: BLACK
        }
    },
    registerLink: {
        textDecoration: 'none',
        width: '100%'
    },
    createAccount: {
        style: {
            color: BLUE
        },
        props: {
            variant: 'p',
            component: 'span',
            fontWeight: 'bold'
        }
    },
    imageSection: {
        display: {
            xs: 'none',
            md: 'flex'
        },
        backgroundColor: BLUE_ACCENT,
        height: '100%'
    },
    imageSectionContainer: {
        style: {
            display: 'flex',
            height: '100%',
            alignItems: 'center'
        },
        props: {
            maxWidth: 'xl'
        }
    },
    image: {
        width: '300px',
        height: '300px'
    },
    imageText: {
        style: {
            width: '400px',
            color: NAVY
        },
        props: {
            fontWeight: 'bold',
            variant: 'h3',
            component: 'span'
        }
    },
    forgotPasswordText: {
        style: {
            color: BLUE,
            width: '100%',
            textAlign: 'right'
        },
        props: {
            variant: 'p',
            component: 'div',
            fontWeight: 'bold'
        }
    },
    forgotLink: {
        textDecoration: 'none',
        color: BLUE
    }
}

export const HomeStyle = {
    landingContainer: {
        display: {
            xs: 'none',
            lg: 'flex'
        },
        height: '100vh',
        overflowX: 'hidden'
    },
    landingGrid: {
        width: '100%',
        height: '100%'
    },
    landingTextContainer: {
        height: '100%',
        width: '100%',
        padding: '80px',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        flexDirection: 'column !important'
    },
    lgTitle: {
        style: {
            display: {
                lg: 'inline',
                xl: 'none'
            }
        },
        props: {
            variant: 'h2',
            component: 'div'
        }
    },
    xlTitle: {
        style: {
            display: {
                lg: 'none',
                xl: 'inline'
            }
        },
        props: {
            variant: 'h1',
            component: 'div'
        }
    },
    tcairemWrap: {
        whiteSpace: 'nowrap'
    },
    landingText: {
        variant: 'h5',
        component: 'div'
    },
    mobileTextContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    mobileText: {
        style: {
            color: WHITE,
            paddingLeft: '40px',
            width: '50vw'
        },
        props: {
            fontWeight: 'bold',
            variant: 'h2',
            component: 'div'
        }
    },
    aboutContainer: {
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: SECTION_GREY
    },
    margins: {
        maxWidth: 'xl'
    },
    aboutGrid: {
        width: '100%',
        height: '100%'
    },
    aboutTitle: {
        variant: 'h2',
        component: 'div'
    },
    aboutParagraph: {
        variant: 'p',
        component: 'div'
    },
    learnButton: {
        display: {
            xs: 'block',
            sm: 'flex'
        },
        marginTop: '10px'
    },
    link: {
        style: {
            display: {
                xs: 'inline-block',
                sm: 'flex'
            },
            textDecoration: 'none',
            borderRadius: '20px',
            color: WHITE,
            backgroundColor: NAVY,
            fontWeight: 'bold',
            padding: '10px',
            marginRight: '10px',
            marginBottom: '10px',
            transition: 'ease 0.5s',
            '&:hover': {
                backgroundColor: BLUE
            }
        },
        props: {
            target: '_blank',
            rel: 'noreferrer',
            underline: 'none'
        }
    },
    aboutImageContainer: {
        display: {
            xs: 'none',
            lg: 'flex'
        },
        alignItems: 'center',
        justifyContent: 'center'
    },
    aboutImage: {
        width: '300px',
        height: '300px'
    },
    aboutImageTextContainer: {
        display: {
            xs: 'none',
            lg: 'flex'
        },
        color: NAVY
    },
    aboutImageText: {
        variant: 'h3',
        componenet: 'div'
    }
}

export const NotFoundStyle = {
    container: {
        marginTop: '100px',
        minHeight: '70vh',
        marginBottom: '40px',
        textAlign: 'center'
    },
    title: {
        variant: 'h1'
    },
    imageContainer: {
        textAlign: 'center',
        width: '100%',
        marginTop: '40px'
    },
    icon: {
        width: '30vh',
        height: '30vh',
        backgroundColor: BLUE_ACCENT,
        color: NAVY,
        borderRadius: '100%',
        marginBottom: '40px'
    },
    iconText: {
        variant: 'h4',
        component: 'div'
    }
}

export const ProfileStyle = {
    container: {
        style: {
            marginTop: '100px',
            marginBottom: '40px'
        },
        props: {
            maxWidth: 'md'
        }
    },
    title: {
        variant: 'h3'
    },
    para: {
        marginBottom: '20px'
    },
    paraText: {
        variant: 'p',
        component: 'span'
    },
    subheading: {
        variant: 'h4'
    },
    button: {
        variant: 'contained'
    },
    deleteButton: {
        style: {
            backgroundColor: RED,
            '&:hover': {
                backgroundColor: REJECT_RED
            }
        },
        props: {
            variant: 'contained'
        }
    }
}

export const RequestStyle = {
    container: {
        style: {
            marginTop: '100px',
            marginBottom: '40px'
        },
        props: {
            maxWidth: 'lg'
        }
    },
    title: {
        variant: 'h3'
    },
    para: {
        marginBottom: '20px'
    },
    paraText: {
        variant: 'p',
        fontWeight: 'bold'
    }
}

export const RegisterStyle = {
    container: {
        style: {
            marginTop: '120px',
            width: '100%',
            marginBottom: '80px'
        },
        props: {
            maxWidth: 'md'
        }
    },
    formContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        variant: 'h3',
        component: 'div'
    },
    form: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        style: {
            width: '40%',
            marginTop: '10px',
            marginBottom: '10px'
        },
        props: {
            variant: 'contained'
        }
    }
}

export const RequestFormStyle = {
    container: {
        maxWidth: 'md'
    },
    title: {
        style: {
            textAlign: 'center',
            width: '100%'
        },
        props: {
            variant: 'h4'
        }
    },
    button: {
        style: {
            width: '100%'
        },
        props: {
            variant: 'contained'
        }
    },
    searchContainer: {
        marginTop: '30px',
        marginBottom: '20px'
    },
    searchTextContainer: {
        marginBottom: '20px'
    },
    searchText: {
        variant: 'p',
        component: 'div'
    },
    searchButton: {
        props: {
            variant: 'contained'
        },
        style: {
            height: '56px'
        }
    }
}

const BaseAdminModalStyle = {
    viewButton: {
        props: {
            variant: 'contained'
        },
        style: {
            minWidth: '100px'
        }
    },
    modal: {
        maxWidth: 'md'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: '10px',
        color: '',
        backgroundColor: '',
        borderRadius: '50px',
        padding: '5px'
    },
    content: {
        minHeight: '50vh'
    },
    subheading: {
        variant: 'h5'
    },
    proposerText: {
        variant: 'p'
    },
    formGrid: {
        marginTop: '10px'
    },
    resetButton: {
        variant: 'contained'
    },
    launch: {
        fontSize: 'small'
    },
    subFormHeading: {
        marginBottom: '20px',
        marginTop: '20px'
    },
    subFormHeadingText: {
        variant: 'h5'
    },
    add: {
        props: {
            variant: 'contained'
        },
        style: {
            width: '100%'
        }
    },
    subFormGrid: {
        marginBottom: '20px'
    },
    deleteButton: {
        props: {
            variant: 'contained'
        },
        style: {
            backgroundColor: RED,
            width: '100%',
            '&:hover': {
                backgroundColor: REJECT_RED
            }
        }
    }
}

export const AdminBookModalStyle = structuredClone(BaseAdminModalStyle)
AdminBookModalStyle.icon.color = RED
AdminBookModalStyle.icon.backgroundColor = RED_ACCENT

export const AdminCourseModalStyle = structuredClone(BaseAdminModalStyle)
AdminCourseModalStyle.icon.color = PURPLE
AdminCourseModalStyle.icon.backgroundColor = PURPLE_ACCENT

export const AdminPaperModalStyle = structuredClone(BaseAdminModalStyle)
AdminPaperModalStyle.icon.color = GREEN
AdminPaperModalStyle.icon.backgroundColor = GREEN_ACCENT

export const AdminPolicyModalStyle = structuredClone(BaseAdminModalStyle)
AdminPolicyModalStyle.icon.color = BROWN
AdminPolicyModalStyle.icon.backgroundColor = BROWN_ACCENT

export const AdminPodcastModalStyle = structuredClone(BaseAdminModalStyle)
AdminPodcastModalStyle.icon.color = BLUE
AdminPodcastModalStyle.icon.backgroundColor = BLUE_ACCENT

export const AdminVideoModalStyle = structuredClone(BaseAdminModalStyle)
AdminVideoModalStyle.icon.color = YELLOW
AdminVideoModalStyle.icon.backgroundColor = YELLOW_ACCENT

const BaseModalStyle = {
    viewButton: {
        props: {
            variant: 'outlined'
        },
        style: {
            marginRight: '10px'
        }
    },
    modal: {
        maxWidth: 'md'
    },
    headingContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: '10px',
        color: '',
        backgroundColor: '',
        borderRadius: '50px',
        padding: '5px'
    },
    content: {
        display: {
            xs: 'none',
            sm: 'flex'
        },
        justifyContent: 'space-between',
        alignItems: 'top'
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'top',
        flexDirection: 'column',
        width: '300px'
    },
    dates: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        height: '40px'
    },
    heading: {
        variant: 'p',
        fontWeight: 'bold'
    },
    subheading: {
        paddingLeft: '3rem',
    },
    text: {
        variant: 'p'
    },
    ratingContainer: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'
    },
    ratingText: {
        display: 'flex',
        alignItems: 'center'
    },
    favColour: {
        color: NAVY
    },
    mobileContainer: {
        display: {
            xs: 'flex',
            sm: 'none'
        },
        flexDirection: 'column'
    },
    mobileRating: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    mobileDate: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center'
    },
    additionalInfo: {
        marginBottom: '10px'
    },
    tags: {
        margin: '2px'
    },
    mobileRate: {
        display: {
            xs: 'block',
            sm: 'none'
        }
    },
    paperSpacing: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}

export const BookModalStyle = structuredClone(BaseModalStyle)
BookModalStyle.icon.color = RED
BookModalStyle.icon.backgroundColor = RED_ACCENT

export const CourseModalStyle = structuredClone(BaseModalStyle)
CourseModalStyle.icon.color = PURPLE
CourseModalStyle.icon.backgroundColor = PURPLE_ACCENT
CourseModalStyle.avatar = {
    backgroundColor: 'transparent'
}
CourseModalStyle.avatarIcon = {
    color: PURPLE,
    backgroundColor: PURPLE_ACCENT,
    borderRadius: '50px',
    padding: '5px'
}

export const PaperModalStyle = structuredClone(BaseModalStyle)
PaperModalStyle.icon.color = GREEN
PaperModalStyle.icon.backgroundColor = GREEN_ACCENT

export const PolicyModalStyle = structuredClone(BaseModalStyle)
PolicyModalStyle.icon.color = BROWN
PolicyModalStyle.icon.backgroundColor = BROWN_ACCENT

export const PodcastModalStyle = structuredClone(BaseModalStyle)
PodcastModalStyle.icon.color = BLUE
PodcastModalStyle.icon.backgroundColor = BLUE_ACCENT
PodcastModalStyle.avatar = {
    backgroundColor: 'transparent'
}
PodcastModalStyle.avatarIcon = {
    color: BLUE,
    backgroundColor: BLUE_ACCENT,
    borderRadius: '50px',
    padding: '5px'
}

export const VideoModalStyle = structuredClone(BaseModalStyle)
VideoModalStyle.icon.color = YELLOW
VideoModalStyle.icon.backgroundColor = YELLOW_ACCENT
VideoModalStyle.avatar = {
    backgroundColor: 'transparent'
}
VideoModalStyle.avatarIcon = {
    color: YELLOW,
    backgroundColor: YELLOW_ACCENT,
    borderRadius: '50px',
    padding: '5px'
}
