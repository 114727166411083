import axios from 'axios'
import tokenService from './token'

const baseUrl = '/api/videos/'
const childBaseUrl = '/api/videos/series/'

const retrieve = async (query='') => {
    const res = await axios.get(`${baseUrl}?${query}`, tokenService.getAuthHeader())
    return res.data
}

const retrieveSingle = async (id) => {
    const res = await axios.get(`${baseUrl}${id}/`, tokenService.getAuthHeader())
    return res.data
}

const create = async (obj) => {
    const res = await axios.post(baseUrl, obj, tokenService.getAuthHeader())
    return res.data
}

const createChildren = async (list, id) => {
    let res_list = []

    await list.forEach(async obj => {
        const data = {
            ...obj,
            parent: id
        }
        const res = await axios.post(childBaseUrl, data, tokenService.getAuthHeader())
        res_list.push(res.data)
    })

    return res_list
}

const update = async (id, obj) => {
    const res = await axios.put(`${baseUrl}${id}/`, obj, tokenService.getAuthHeader())
    return res.data
}

const updateChild = async (id, obj) => {
    const res = await axios.put(`${childBaseUrl}${id}/`, obj, tokenService.getAuthHeader())
    return res.data
}

const destroy = async (id) => {
    const res = await axios.delete(`${baseUrl}${id}/`, tokenService.getAuthHeader())
    return res
}

const destroyChild = async (id) => {
    const res = await axios.delete(`${childBaseUrl}${id}/`, tokenService.getAuthHeader())
    return res.data
}

export default { retrieve, retrieveSingle, create, createChildren, update, updateChild, destroy, destroyChild }