import ratingService from '../services/ratings'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'
import { refreshBook } from './booksReducer'
import { refreshCourse } from './coursesReducer'
import { refreshPaper } from './papersReducer'
import { refreshPolicy } from './policiesReducer'
import { refreshPodcast } from './podcastsReducer'
import { refreshVideo } from './videosReducer'
import { refreshResource } from './resourcesReducer'
import { refreshFavourite } from './favouritesReducer'

const initialState = []

// toolkit sets up the redux and state
const ratingSlice = createSlice({
    name: 'ratings',
    initialState,
    reducers: {
        setRatings(state, action) {
            return action.payload
        },
        appendRating(state, action){
            state.push(action.payload)
        },
        destroyRating(state, action){
            return state.filter(rating => rating.id !== action.payload)
        },
        changeRating(state, action){
            return state.map(rating => rating.id === action.payload.id ? action.payload : rating)
        }
    }
})

export const { setRatings, appendRating, destroyRating, changeRating } = ratingSlice.actions

export const createRating = (data, type, id, fav) => {
    return async dispatch => {
        try {
            const rating = await ratingService.create(data)
            dispatch(appendRating(rating))

            if(type === 'book') {
                dispatch(refreshBook(id))
            } else if (type === 'course') {
                dispatch(refreshCourse(id))
            } else if (type === 'paper') {
                dispatch(refreshPaper(id))
            } else if (type === 'policy') {
                dispatch(refreshPolicy(id))
            } else if (type === 'podcast') {
                dispatch(refreshPodcast(id))
            } else if (type === 'video') {
                dispatch(refreshVideo(id))
            }
            dispatch(refreshResource(id, type))
            if(fav !== null) dispatch(refreshFavourite(fav))

            dispatch(setSuccessNotification('Resource rated successfully'))
        } catch {
            dispatch(setErrorNotification('Error when rating resource, please try again later'))
        }
    }
}

export const initializeRatings = () => {
    return async dispatch => {
        try {
            const ratings = await ratingService.retrieve()
            dispatch(setRatings(ratings))
        } catch {
            dispatch(setErrorNotification('Error retrieving ratings'))
        }
    }
}

export const deleteRating = (id) => {
    return async dispatch => {
        try {
            await ratingService.destroy(id)
            dispatch(destroyRating(id))
            dispatch(setSuccessNotification('Rating deleted sucessfully'))
        } catch {
            dispatch(setErrorNotification('Error deleting rating'))
        }
    }
}

export const updateRating = (id, data, type, typeId, fav) => {
    return async dispatch => {
        try {
            const rating = await ratingService.update(id, data)
            dispatch(changeRating(rating))

            if(type === 'book') {
                dispatch(refreshBook(typeId))
            } else if (type === 'course') {
                dispatch(refreshCourse(typeId))
            } else if (type === 'paper') {
                dispatch(refreshPaper(typeId))
            } else if (type === 'policy') {
                dispatch(refreshPolicy(typeId))
            } else if (type === 'podcast') {
                dispatch(refreshPodcast(typeId))
            } else if (type === 'video') {
                dispatch(refreshVideo(typeId))
            }
            dispatch(refreshResource(typeId, type))
            if(fav !== null) dispatch(refreshFavourite(fav))

            dispatch(setSuccessNotification('Rating updated successfully'))
        } catch {
            dispatch(setErrorNotification('Update of rating failed'))
        }
    }
}

export default ratingSlice.reducer