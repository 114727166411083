import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { PAGES } from '../navigation/routes'
import { ForgotStyle } from '../styles'

import { setErrorNotification } from '../../reducers/notificationReducer'
import { resetPassword } from '../../reducers/userReducer'

const ForgotPassword = () => {
    const user = useSelector(state => state.user)
    const [email, setEmail] = useState('')
    const [emailHelper, setEmailHelper] = useState('')
    const [emailError, setEmailError] = useState(false)
    const dispatch = useDispatch()

    const validateEmail = () => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(email === '') {
            setEmailHelper('Required Field!')
            setEmailError(true)
            return false
        } else if(!email.match(re)) {
            setEmailHelper('Invalid Email Address')
            setEmailError(true)
            return false
        } else {
            setEmailHelper('')
            setEmailError(false)
            return true
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if(validateEmail()) {
            dispatch(resetPassword({email: email}))
            setEmail('')
            setEmailHelper('')
            setEmailError(false)
        } else {
            dispatch(setErrorNotification('Please fix the form errors before submission'))
        }
    }

    if(user && !user.anon) {
        return (
            <Navigate to={PAGES.home.path} />
        )
    }

    return (
        <Grid container spacing={2} sx={ForgotStyle.container}>
            <Grid item xs={12} md={5} lg={6}>
                <Container {...ForgotStyle.margins}>
                    <Typography sx={ForgotStyle.title.style} {...ForgotStyle.title.props} gutterBottom>
                        {PAGES.forgot_password.title}?
                    </Typography>
                    <Typography sx={ForgotStyle.secondary.style} {...ForgotStyle.secondary.props} gutterBottom>
                        Enter your email here and we will get back to you with further instructions
                    </Typography>
                    <form onSubmit={handleSubmit} style={ForgotStyle.form}>
                        <TextField
                            label='Email'
                            placeholder='email@example.com'
                            value={email}
                            onBlur={() => validateEmail()}
                            onChange={(e) => setEmail(e.target.value)}
                            helperText={emailHelper}
                            error={emailError}
                            required
                            type='email'
                            sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                            InputLabelProps={{ required: false }}
                        />
                        <Button sx={ForgotStyle.button.style} {...ForgotStyle.button.props} type='submit'>Send Email</Button>
                    </form>
                    <Box sx={ForgotStyle.register}>
                        <Typography {...ForgotStyle.registerText.props} sx={ForgotStyle.registerText.style}>
                            Not Registered Yet?&nbsp;
                        </Typography>
                        <Link to={PAGES.sign_up.path} style={ForgotStyle.registerLink}>
                            <Typography {...ForgotStyle.createAccount.props} sx={ForgotStyle.createAccount.style}>
                                Create an Account
                            </Typography>
                        </Link>
                    </Box>
                </Container>
            </Grid>
            <Grid item md={7} lg={6} sx={ForgotStyle.imageSection}>
                <Container {...ForgotStyle.imageSectionContainer.props} sx={ForgotStyle.imageSectionContainer.style}>
                    <img src='/static/logo.svg' style={ForgotStyle.image}/>
                    <Typography {...ForgotStyle.imageText.props} sx={ForgotStyle.imageText.style} gutterBottom>
                        Temerty Centre for Artificial Intelligence Research and Education in Medicine
                    </Typography>
                </Container>
            </Grid>
        </Grid>
    )
}

export default ForgotPassword