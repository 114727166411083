import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'

import BookForm from '../requests/BookForm'
import CourseForm from '../requests/CourseForm'
import PaperForm from '../requests/PaperForm'
import PolicyForm from '../requests/PolicyForm'
import PodcastForm from '../requests/PodcastForm'
import VideoForm from '../requests/VideoForm'

import { RequestStyle } from '../styles'
import { initializeTags } from '../../reducers/tagsReducers'
import { setErrorNotification } from '../../reducers/notificationReducer'
import { PAGES } from '../navigation/routes'

const Request = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tags = useSelector(state => state.tags)
    const user = useSelector(state => state.user)
    const tagNames = tags.map(tag => { return { value: tag.name, label: tag.name }})

    const [type, setType] = useState('book')

    useEffect(() => {
        if(user) {
            if (user.anon) {
                dispatch(setErrorNotification('You must be logged in to view this page'))
                navigate(PAGES.home.path)
            }

            dispatch(initializeTags())
        }
    }, [user])

    return (
        <Container {...RequestStyle.container.props} sx={RequestStyle.container.style}>
            {(user && user.staff)?
                <Typography {...RequestStyle.title} gutterBottom>
                    Create Resource
                </Typography> :
                <Typography {...RequestStyle.title} gutterBottom>
                    Request Resource
                </Typography>
            }
            {user && user.staff && <Box sx={RequestStyle.para}>
                <Typography {...RequestStyle.paraText} gutterBottom>
                    As you are an admin user, please be aware that any resource you create here will be automatically approved and visible to all.
                </Typography>
            </Box>}
            <Box>
                <Typography {...RequestStyle.paraText} gutterBottom>
                    Please review the <Link href={"https://tcairemresources.ca/start-here/#contribute-container"}>submission guidelines</Link> before proceeding with your submission
                </Typography>
            </Box>
            <Box>
                <TextField
                    value={type}
                    select
                    helperText={'Select the type of resource you would like to request'}
                    label="Resource Type"
                    onChange={(e)=>setType(e.target.value)}
                    sx={{width: '300px', marginTop: '10px', marginBottom: '10px'}}
                >
                    <MenuItem value="book">
                        Book
                    </MenuItem>
                    <MenuItem value="course">
                        Course
                    </MenuItem>
                    <MenuItem value="paper">
                        Paper
                    </MenuItem>
                    <MenuItem value="policy">
                        Policy
                    </MenuItem>
                    <MenuItem value="podcast">
                        Podcast
                    </MenuItem>
                    <MenuItem value="video">
                        Video
                    </MenuItem>
                </TextField>
            </Box>
            {user && <Box>
                {(type === 'book') && <BookForm tags={tagNames} user={user} /> }
                {(type === 'paper') && <PaperForm tags={tagNames} user={user} /> }
                {(type === 'policy') && <PolicyForm tags={tagNames} user={user} /> }
                {(type === 'course') && <CourseForm tags={tagNames} user={user} /> }
                {(type === 'podcast') && <PodcastForm tags={tagNames} user={user} /> }
                {(type === 'video') && <VideoForm tags={tagNames} user={user} /> }
            </Box>}
        </Container>
    )
}

export default Request