import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import LaunchIcon from '@mui/icons-material/Launch'
import Typography from '@mui/material/Typography'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import StarIcon from '@mui/icons-material/Star'
import Box from '@mui/material/Box'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import Chip from '@mui/material/Chip'
import RateModal from './RateModal'

import { PolicyModalStyle } from '../styles'

import { createFavourite, deleteFavourite } from '../../reducers/favouritesReducer'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const PolicyModal = (props) => {
    const favourites = useSelector(state => state.favourites)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [favourite, setFavourite] = useState(false)

    useEffect(() => {
        // idea is if that this resource is favourited then it will show in either the props or be in the last element of the
        // favourites list since whne pushed into list push places it in the end
        // this avoids traversing the whole list to check if favourited
        if(favourites !== null && (favourites.length > 0 && favourites[favourites.length - 1].favourite.id === props.id) || props.favourite) {
            setFavourite(true)
        } else {
            setFavourite(false)
        }
    }, [favourites, user, props.favourite])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    // favourite resource
    const handleFavourite = async () => {
        if(!favourite) {
            dispatch(createFavourite({
                object_id: props.id,
                content_type: props.content_type
            }, 'policy'))
        } else {
            dispatch(deleteFavourite(props.favourite, 'policy', props.id))
        }
    }

    return (
        <div>
            <Button {...PolicyModalStyle.viewButton.props} sx={PolicyModalStyle.viewButton.style} onClick={()=>handleClickOpen()}>
                More Info
            </Button>
            <BootstrapDialog
                onClose={()=>handleClose()}
                aria-labelledby={`policy-modal-${props.id}`}
                open={open}
                fullWidth
                {...PolicyModalStyle.modal}
            >
                <BootstrapDialogTitle id={`policy-modal-${props.id}`} onClose={()=>handleClose()}>
                    <Box sx={PolicyModalStyle.headingContainer}>
                        <MenuBookIcon sx={PolicyModalStyle.icon}/>
                        {props.title}
                    </Box>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={PolicyModalStyle.content}>
                        <Box sx={PolicyModalStyle.dateContainer}>
                            <Box sx={PolicyModalStyle.dates}>
                                <Typography {...PolicyModalStyle.heading}>
                                    Posted:&nbsp;
                                </Typography>
                                <Typography {...PolicyModalStyle.text}>
                                    {props.updated.split('T')[0]}
                                </Typography>
                            </Box>
                            <Box sx={PolicyModalStyle.dates}>
                                <Typography {...PolicyModalStyle.heading}>
                                    Published:&nbsp;
                                </Typography>
                                <Typography {...PolicyModalStyle.text}>
                                    {props.pub_date}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={PolicyModalStyle.ratingContainer}>
                                {props.rating?
                                    <Typography sx={PolicyModalStyle.ratingText}>
                                        {props.rating}/5.0 <StarIcon />
                                    </Typography> :
                                    <Typography sx={PolicyModalStyle.ratingText}>
                                        No Rating <StarIcon />
                                    </Typography>
                                }
                                {user && !user.anon && <IconButton onClick={()=>handleFavourite()}>
                                    {favourite? <BookmarkIcon sx={PolicyModalStyle.favColour} /> : <BookmarkBorderIcon sx={PolicyModalStyle.favColour} />}
                                </IconButton>}
                            </Box>
                            {user && !user.anon && <Box sx={PolicyModalStyle.ratingContainer}>
                                <RateModal type={'policy'} rated_by_user={props.rated_by_user} content_type={props.content_type} id={props.id} favourite={props.favourite}/>
                            </Box>}
                        </Box>
                    </Box>
                    <Box sx={PolicyModalStyle.mobileContainer}>
                        <Box sx={PolicyModalStyle.mobileRating}>
                            {props.rating?
                                <Typography sx={PolicyModalStyle.ratingText}>
                                    {props.rating}/5.0 <StarIcon />
                                </Typography> :
                                <Typography sx={PolicyModalStyle.ratingText}>
                                    No Rating <StarIcon />
                                </Typography>
                            }
                            {user && !user.anon && <IconButton onClick={()=>handleFavourite()}>
                                {favourite? <BookmarkIcon sx={PolicyModalStyle.favColour} /> : <BookmarkBorderIcon sx={PolicyModalStyle.favColour} />}
                            </IconButton>}
                        </Box>
                        <Box sx={PolicyModalStyle.mobileDate}>
                            <Typography gutterBottom {...PolicyModalStyle.dateHeading}>
                                Posted:&nbsp;
                            </Typography>
                            <Typography gutterBottom {...PolicyModalStyle.text}>
                                {props.updated.split('T')[0]}
                            </Typography>
                        </Box>
                        <Box sx={PolicyModalStyle.mobileDate}>
                            <Typography gutterBottom {...PolicyModalStyle.dateHeading}>
                                Published:&nbsp;
                            </Typography>
                            <Typography gutterBottom {...PolicyModalStyle.text}>
                                {props.pub_date}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={PolicyModalStyle.additionalInfo}>
                        <Typography {...PolicyModalStyle.text} gutterBottom>
                            <strong>Policy Type:</strong> {props.policy_type_name}
                        </Typography>
                    </Box>
                    <Box sx={PolicyModalStyle.additionalInfo}>
                        <Typography {...PolicyModalStyle.text} gutterBottom>
                            <strong>ISBN:</strong> {props.isbn}
                        </Typography>
                    </Box>
                    <Box sx={PolicyModalStyle.additionalInfo}>
                        <Typography {...PolicyModalStyle.text} gutterBottom>
                            <strong>Author(s):</strong> {props.author}
                        </Typography>
                    </Box>
                    {(props.tags.length > 0) &&
                    <><Box sx={PolicyModalStyle.additionalInfo}>
                        <Typography {...PolicyModalStyle.heading} gutterBottom>
                            Key Words
                        </Typography>
                    </Box>
                    <Box>
                        {props.tags.map(tag => <Chip key={tag} label={tag} sx={PolicyModalStyle.tags}/>)}
                    </Box></>}
                </DialogContent>
                <DialogActions>
                    {user && !user.anon && <Box sx={PolicyModalStyle.mobileRate}>
                        <RateModal type={'policy'} rated_by_user={props.rated_by_user} content_type={props.content_type} id={props.id} favourite={props.favourite}/>
                    </Box>}
                    <Button autoFocus onClick={()=>openResource()}>
                        View Resource&nbsp;<LaunchIcon />
                    </Button>
                    <Button autoFocus onClick={()=>handleClose()}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default PolicyModal