import podcastService from '../services/podcasts'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = null

// toolkit sets up the redux and state
const podcastSlice = createSlice({
    name: 'podcasts',
    initialState,
    reducers: {
        setPodcasts(state, action) {
            return action.payload
        },
        appendPodcast(state, action){
            if (state === null) return state
            state.push(action.payload)
        },
        destroyPodcast(state, action){
            if (state === null) return state
            return state.filter(podcast => podcast.id !== action.payload)
        },
        changePodcast(state, action){
            if (state === null) return state
            return state.map(podcast => podcast.id === action.payload.id ? action.payload : podcast)
        }
    }
})

export const { setPodcasts, appendPodcast, destroyPodcast, changePodcast } = podcastSlice.actions

export const createPodcast = (data, list, admin) => {
    return async dispatch => {
        try {
            const podcast = await podcastService.create(data)
            await podcastService.createChildren(list, podcast.id)
            if(admin) {
                dispatch(appendPodcast(podcast))
                dispatch(setSuccessNotification('Podcast created successfully'))
            } else {
                dispatch(setSuccessNotification('Your request has been submitted'))
            }
        } catch {
            if(admin) {
                dispatch(setErrorNotification('Error creating podcast, if persists please notify developer team'))
            } else {
                dispatch(setErrorNotification('Error submitting request, please try again later'))
            }
        }
    }
}

export const initializePodcasts = () => {
    return async dispatch => {
        try {
            const podcasts = await podcastService.retrieve()
            dispatch(setPodcasts(podcasts))
        } catch {
            dispatch(setErrorNotification('Error retrieving podcasts'))
        }
    }
}

export const deletePodcast = (id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await podcastService.destroy(id)
                dispatch(destroyPodcast(id))
                dispatch(setSuccessNotification('Podcast deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting podcast'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const deletePodcastChild = (id, child_id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await podcastService.destroyChild(child_id)
                const podcast = await podcastService.retrieveSingle(id)
                dispatch(changePodcast(podcast))
                dispatch(setSuccessNotification('Sub podcast deleted sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error deleting sub podcast'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updatePodcast = (id, data, podcasts, toDelete, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await podcasts.forEach(async podcast => {
                    if (podcast.id) {
                        await podcastService.updateChild(podcast.id, podcast)
                    } else {
                        await podcastService.createChildren([podcast], id)
                    }
                })

                await toDelete.forEach(async podcast => {
                    await podcastService.destroyChild(podcast.id)
                })

                const podcast = await podcastService.update(id, data)
                dispatch(changePodcast(podcast))
                dispatch(setSuccessNotification('Podcast updated successfully'))
            } catch {
                dispatch(setErrorNotification('Update of podcast failed'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const updatePodcastChild = (id, child_id, admin) => {
    return async dispatch => {
        if(admin) {
            try {
                await podcastService.updateChild(child_id)
                const podcast = await podcastService.retrieveSingle(id)
                dispatch(changePodcast(podcast))
                dispatch(setSuccessNotification('Sub podcast updated sucessfully'))
            } catch {
                dispatch(setErrorNotification('Error updating sub podcast'))
            }
        } else {
            dispatch(setErrorNotification('Permission Denined: You must be a staff to perform this action'))
        }
    }
}

export const searchPodcasts = (query) => {
    return async dispatch => {
        try {
            const podcasts = await podcastService.retrieve(query)
            dispatch(setPodcasts(podcasts))
        } catch {
            dispatch(setErrorNotification('Error fulfilling search'))
        }
    }
}

export const refreshPodcast = (id) => {
    return async dispatch => {
        try {
            const podcast = await podcastService.retrieveSingle(id)
            dispatch(changePodcast(podcast))
        } catch { }
    }
}

export default podcastSlice.reducer