import userService from '../services/user'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = []

// toolkit sets up the redux and state
const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers(state, action) {
            return action.payload
        },
        changeUsers(state, action) {
            if (state === null) return state
            return state.map(user => user.id === action.payload.id? action.payload : user)
        }
    }
})

export const { setUsers, changeUsers } = userSlice.actions

export const initializeUsers = () => {
    return async dispatch => {
        try {
            const users = await userService.retrieve()
            dispatch(setUsers(users))
        } catch {
            dispatch(setErrorNotification('Error retrieving users'))
        }
    }
}

export const updateUser = (data) => {
    return async dispatch => {
        try {
            const user = await userService.update(data.id, data)
            dispatch(changeUsers(user))
            dispatch(setSuccessNotification('User updated'))
        } catch {
            dispatch(setErrorNotification('Error updating user'))
        }
    }
}

export default userSlice.reducer