import axios from 'axios'
import tokenService from './token'

const baseUrl = '/api/search/'
const isbnUrl = '/api/search/isbn/'
const doiUrl = '/api/search/doi/'
const policyUrl = '/api/search/policy/'

const retrieve = async (query='') => {
    const res = await axios.get(`${baseUrl}?${query}`, tokenService.getAuthHeader())
    return res.data
}

const retrieveBook = async (isbn) => {
    const res = await axios.get(`${isbnUrl}?isbn=${isbn}`, tokenService.getAuthHeader())
    return res.data.items[0]
}

const retrievePaper = async (doi) => {
    const res = await axios.get(`${doiUrl}?doi=${doi}`, tokenService.getAuthHeader())
    return res.data
}

const retrievePolicy = async (title) => {
    const res = await axios.get(`${policyUrl}?title=${title}`, tokenService.getAuthHeader())
    return res.data.items[0]
}

export default { retrieve, retrieveBook, retrievePaper, retrievePolicy }
