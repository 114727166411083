import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LaunchIcon from '@mui/icons-material/Launch'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'

import { CourseListStyle } from '../styles'
import CourseModal from '../modals/CourseModal'

const CourseList = (props) => {
    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <>
            <Box sx={CourseListStyle.topContainer}>
                <Box sx={CourseListStyle.topContainerIconContainer}>
                    <SchoolIcon sx={CourseListStyle.icon} />
                    <Typography {...CourseListStyle.text} gutterBottom>
                        &nbsp;Published: {props.pub_date}
                    </Typography>
                </Box>
                {props.rating?
                    <Typography {...CourseListStyle.text}>
                        {props.rating}/5.0 <StarIcon />
                    </Typography> :
                    <Typography {...CourseListStyle.text}>
                        No Rating Yet
                    </Typography>
                }
            </Box>
            <Box>
                <Box sx={CourseListStyle.titleContainer}>
                    <Typography {...CourseListStyle.title} gutterBottom>
                        {props.title}
                    </Typography>
                </Box>
                {props.tags.map(tag => <Chip sx={CourseListStyle.chips} key={tag} label={tag} />)}
                <Box sx={CourseListStyle.secondary}>
                    <Typography {...CourseListStyle.text}>
                        Instructor(s): {props.author}
                    </Typography>
                </Box>
                <Box sx={CourseListStyle.actions}>
                    <CourseModal {...props} />
                    <Button {...CourseListStyle.view.props} sx={CourseListStyle.view.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                </Box>
            </Box>
            <Divider />
        </>
    )
}

export default CourseList