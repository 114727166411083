import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'

import PaperCard from '../cards/PaperCard'
import PaperList from '../lists/PaperList'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loading from '../loading/Loading'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ReorderIcon from '@mui/icons-material/Reorder'
import AppsIcon from '@mui/icons-material/Apps'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'

import Select from 'react-select'

import { initializePapers, searchPapers } from '../../reducers/papersReducer'
import { initializeTags } from '../../reducers/tagsReducers'
import Pagination from '@mui/material/Pagination'

import { buildQuery, buildSearchQuery } from '../../services/utils.js/query'
import { PAGES } from '../navigation/routes'
import { PAGE_SIZE } from '../websiteConfig'
import { SearchStyle } from '../styles'

const Papers = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const [types, setTypes] = useState(['RS', 'SPD', 'RV', 'DD', 'COM', 'ED', 'SYS', 'TUT', 'REP'])
    const [resourcesToShow, setResourcesToShow] = useState(null)
    const [list, setList] = useState(true)
    const [resourcesSorted, setResourcesSorted] = useState(null)
    const [sortRes, setSortRes] = useState(1)

    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [title, setTitle] = useState('')
    const [formTags, setFormTags] = useState('')

    const user = useSelector(state => state.user)
    const papers = useSelector(state => state.papers)
    const tags = useSelector(state => state.tags)
    const tagNames = tags.map(tag => { return { value: tag.name, label: tag.name }})
    const dispatch = useDispatch()

    const pageSize = PAGE_SIZE
    const [page, setPage] = useState(1)

    useEffect(() => {
        if(searchParams.toString()) {
            let formTagsValue = searchParams.get('tags')
            let startValue = searchParams.get('start_date')
            let endValue = searchParams.get('end_date')
            let titleValue = searchParams.get('title')

            if(formTagsValue) setFormTags(formTagsValue.split(',').map(tag => { return {label: tag, value: tag} }))
            if(startValue) setStart(startValue)
            if(endValue) setEnd(endValue)
            if(titleValue) setTitle(titleValue)

            const query = buildSearchQuery(startValue, endValue, titleValue, formTagsValue)

            dispatch(searchPapers(query))
        } else {
            dispatch(initializePapers())
        }
        dispatch(initializeTags())
        setPage(1)
    }, [searchParams, user])

    useEffect(() => {
        if(resourcesSorted !== null) {
            const r = (user && user.staff) ? resourcesSorted.filter(resource => resource.approved).filter(resource => types.includes(resource.paper_type)) : resourcesSorted.filter(resource => types.includes(resource.paper_type))
            setResourcesToShow(r)
            setPage(1)
        }
    }, [types, resourcesSorted])

    useEffect(() => {
        if(papers !== null) {
            const r = [...papers]
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return 1
                if (y.rating === null) return -1
                return y.rating - x.rating
            }))
        }
    }, [papers])

    const handleSort = (event) => {
        const r = [...papers]
        setSortRes(event.target.value)

        if (event.target.value == 1) {
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return 1
                if (y.rating === null) return -1
                return y.rating - x.rating
            }))
        } else if (event.target.value == 2) {
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return -1
                if (y.rating === null) return 1
                return x.rating - y.rating
            }))
        } else if (event.target.value == 3) {
            setResourcesSorted(r.sort((x, y) => new Date(y.pub_date) - new Date(x.pub_date)))
        } else {
            setResourcesSorted(r.sort((x, y) => new Date(x.pub_date) - new Date(y.pub_date)))
        }
    }

    const changeTypes = (type, val) => {
        if (val) {
            setTypes(types.concat(type))
        } else {
            setTypes(types.filter(resType => resType !== type))
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        let formTagsValue = null
        let startValue = null
        let endValue = null
        let titleValue = null
        if(formTags && formTags.length > 0) formTagsValue = formTags.map(tag => tag.value)
        if(start) startValue = start
        if(end) endValue = end
        if(title) titleValue = title

        const query = buildQuery(startValue, endValue, titleValue, formTagsValue)
        const currentQuery = buildSearchQuery(searchParams.get('start_date'), searchParams.get('end_date'), searchParams.get('title'), searchParams.get('tags'))

        if(query !== currentQuery) {
            setResourcesToShow(null)
            navigate(`${PAGES.papers.path}?${query}`)
        }
    }

    return (
        <Container sx={SearchStyle.container}>
            <Typography {...SearchStyle.title} gutterBottom >
                Papers
            </Typography>
            <form style={SearchStyle.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            sx={{width: '100%'}}
                            variant='outlined'
                            label='Resource Title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{width: '100%'}}
                                    variant='outlined'
                                    type='date'
                                    label='Start Date'
                                    InputLabelProps={{ shrink: true }}
                                    value={start}
                                    onChange={(e) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{width: '100%'}}
                                    variant='outlined'
                                    type='date'
                                    label='End Date'
                                    InputLabelProps={{ shrink: true }}
                                    value={end}
                                    onChange={(e) => setEnd(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={tagNames}
                            value={formTags}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    minHeight: '56px'
                                })
                            }}
                            onChange={(value) => setFormTags(value)}
                            placeholder='Select Key Words'
                        />
                    </Grid>
                </Grid>
                <Box sx={SearchStyle.buttonLocation}>
                    <Button
                        variant="contained"
                        disabled={resourcesToShow === null}
                        type='submit'
                    >
                    {resourcesToShow === null ?
                        <CircularProgress
                        size={24}
                        sx={{
                            color: '#FFF',
                        }}
                        /> : <>Search</>
                    }
                    </Button>
                </Box>
            </form>
            <Box sx={SearchStyle.filterHeadingContainer}>
                <Typography {...SearchStyle.filterHeading} gutterBottom>
                    Filter By Paper Type
                </Typography>
            </Box>
            <Grid container spacing={2} sx={SearchStyle.filtersContainer}>
                <Grid item xs={12} sm={6}>
                    <Box sx={SearchStyle.groupsContainer}>
                        <FormGroup sx={SearchStyle.formGroup}>
                            <Box sx={SearchStyle.verticalFormGroup}>
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('RS', e.target.checked)} />} label="Research" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('SPD', e.target.checked)} />} label="Software Package Descriptor" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('RV', e.target.checked)} />} label="Review" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('DD', e.target.checked)} />} label="Dataset Description" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('COM', e.target.checked)} />} label="Perspective/Commentary" />
                            </Box>
                            <Box sx={SearchStyle.verticalFormGroup}>
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('ED', e.target.checked)} />} label="Editorial" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('SYS', e.target.checked)} />} label="Systematic Review" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('TUT', e.target.checked)} />} label="Tutorial" />
                                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeTypes('REP', e.target.checked)} />} label="Report" />
                            </Box>
                        </FormGroup>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
                        <TextField
                                value={sortRes}
                                select
                                label="Sort By"
                                InputLabelProps={{ shrink: true }}
                                onChange={handleSort}
                                sx={{width: '50%'}}
                            >
                            <MenuItem value={1}>Rating Ascending</MenuItem>
                            <MenuItem value={2}>Rating Descending</MenuItem>
                            <MenuItem value={3}>Date Descending</MenuItem>
                            <MenuItem value={4}>Date Ascending</MenuItem>
                        </TextField>
                        <Box sx={SearchStyle.viewContainer}>
                            <IconButton sx={{ color: list? '#002A5C' : '#CECFCB'}} onClick={() => setList(true)}>
                                <ReorderIcon />
                            </IconButton>
                            <IconButton sx={{ color: !list? '#002A5C' : '#CECFCB'}} onClick={() => setList(false)}>
                                <AppsIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {resourcesToShow === null && <Loading />}
            {resourcesToShow !== null && ((resourcesToShow.length > 0)?
                <>
                    <Grid container spacing={2}>
                        {list? resourcesToShow.slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(paper =>
                            <Grid key={paper.id} item xs={12} >
                                <PaperList {...paper}/>
                            </Grid>
                        ) : resourcesToShow.slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(paper =>
                            <Grid key={paper.id} item xs={12} md={4} sx={SearchStyle.card}>
                                <PaperCard {...paper}/>
                            </Grid>
                        )}
                    </Grid>
                    <Pagination sx={SearchStyle.pagination} count={Math.ceil(resourcesToShow.length/pageSize)} page={page} onChange={(event, value) => setPage(value)} />
                </> :
                <Typography {...SearchStyle.noResources.props} gutterBottom sx={SearchStyle.noResources.style}>
                    No Resources Found
                </Typography>)
            }
            <Box sx={{textAlign: 'center', padding: '10px'}}>
                <Typography variant='overline' component='p'>
                    All resources provided are strictly owned and distributed by the creators indicated on the resource
                </Typography>
            </Box>
        </Container>
    )
}

export default Papers