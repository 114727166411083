import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import Select from 'react-select'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import LaunchIcon from '@mui/icons-material/Launch'

import { AdminBookModalStyle } from '../styles'
import { setErrorNotification } from '../../reducers/notificationReducer'
import { initializeResources } from '../../reducers/resourcesReducer'
import { deleteBook, updateBook } from '../../reducers/booksReducer'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const AdminBookModal = (props) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    // form state (no need to use redux for form state)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [nameHelper, setNameHelper] = useState('')
    const [author, setAuthor] = useState('')
    const [authorError, setAuthorError] = useState(false)
    const [authorHelper, setAuthorHelper] = useState('')
    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState(false)
    const [linkHelper, setLinkHelper] = useState('')
    const [date, setDate] = useState('')
    const [dateError, setDateError] = useState(false)
    const [dateHelper, setDateHelper] = useState('')
    const [formTags, setFormTags] = useState('')
    const [bookType, setBookType] = useState('TB')
    const [isbnBook, setIsbnBook] = useState('')
    const [isbnBookError, setIsbnBookError] = useState(false)
    const [isbnBookHelper, setIsbnBookHelper] = useState('')

    // init fields
    useEffect(() => {
        setName(props.title)
        setAuthor(props.author)
        setLink(props.link)
        setDate(props.pub_date)
        setBookType(props.book_type)
        setIsbnBook(props.isbn)
        if (props.tags && props.tags.length > 0) setFormTags(props.tags.map(tag => { return {label: tag, value: tag} }))
    }, [])

    // vaidate required fields
    const validate = (val, errorHandler, helperHandler) => {
        if (val.length === 0) {
            errorHandler(true)
            helperHandler('Required Field!')
            return false
        } else {
            errorHandler(false)
            helperHandler('')
            return true
        }
    }

    // reset fields to original values if user wants to
    const reset = () => {
        setName(props.title)
        setAuthor(props.author)
        setLink(props.link)
        setDate(props.date)
        setIsbnBook(props.isbn)
        setBookType(props.book_type)
        if (props.tags) setFormTags(props.tags.map(tag => { return {label: tag, value: tag} }))

        setNameError(false)
        setNameHelper('')
        setAuthorError(false)
        setAuthorHelper('')
        setLinkError(false)
        setLinkHelper('')
        setDateError(false)
        setDateHelper('')
        setIsbnBookError(false)
        setIsbnBookHelper('')
    }

    // delete resource
    const handleReject = () => {
        if (window.confirm('Are you sure? Note this resource cannot be retrieved after rejection.')) {
            dispatch(deleteBook(props.id, user.staff))
            handleClose()
            dispatch(initializeResources())
        }
    }

    // if user clicks approve/disapprove all I need to do is toggle approved state and update fields
    const handleSubmit = () => {
        if (validate(name, setNameError, setNameHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper)) {
            if (window.confirm('Are you sure? Note any changes you have made will be included.')) {
                const data = {
                    ...props,
                    title: name,
                    author: author,
                    link: link,
                    pub_date: date,
                    isbn: isbnBook,
                    book_type: bookType,
                    approved: !props.approved
                }
                if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
                else data.tags = []

                dispatch(updateBook(props.id, data, user.staff))
                handleClose()
                dispatch(initializeResources())
            }
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    // do not change approved state just update fields
    const handleSave = () => {
        if (validate(name, setNameError, setNameHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper) && validate(isbnBook, setIsbnBookError, setIsbnBookHelper)) {
            const data = {
                ...props,
                title: name,
                author: author,
                link: link,
                pub_date: date,
                isbn: isbnBook,
                book_type: bookType,
            }
            if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
            else data.tags = []

            dispatch(updateBook(props.id, data, user.staff))
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        dispatch(initializeResources())
        setOpen(false)
    }

    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <div>
            <Button {...AdminBookModalStyle.viewButton.props} onClick={()=>handleClickOpen()} sx={AdminBookModalStyle.viewButton.style}>
                <EditIcon /> &nbsp; Edit
            </Button>
            <BootstrapDialog
                onClose={()=>handleClose()}
                aria-labelledby={`book-modal-${props.id}`}
                open={open}
                fullWidth
                {...AdminBookModalStyle.modal}
            >
                <BootstrapDialogTitle id={`book-modal-${props.id}`} onClose={()=>handleClose()}>
                    <Box sx={AdminBookModalStyle.titleContainer}>
                        <MenuBookIcon sx={AdminBookModalStyle.icon} />
                        {props.title}
                    </Box>
                </BootstrapDialogTitle>
                    <DialogContent dividers sx={AdminBookModalStyle.content}>
                        <form onSubmit={handleSubmit} id={`book-form-${props.id}`}>
                            <Typography {...AdminBookModalStyle.subheading} gutterBottom>
                                Manage Resource
                            </Typography>
                            {props.proposer && <Typography {...AdminBookModalStyle.proposerText}>
                                Proposed By: {props.proposer.name} ({props.proposer.email})
                            </Typography>}
                            <Grid container spacing={2} sx={AdminBookModalStyle.formGrid}>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="book-type">Book Type</FormLabel>
                                            <RadioGroup row aria-labelledby="book-type" name="book-types" defaultValue={bookType}>
                                                <FormControlLabel value="TB" control={<Radio onClick={(e) => setBookType(e.target.value)} />} label="Textbook" />
                                                <FormControlLabel value="NF" control={<Radio onClick={(e) => setBookType(e.target.value)} />} label="Nonfiction" />
                                            </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        label="Name"
                                        required
                                        error={nameError}
                                        helperText={nameHelper}
                                        onBlur={()=>validate(name, setNameError, setNameHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={author}
                                        onChange={e => setAuthor(e.target.value)}
                                        label="Author"
                                        required
                                        error={authorError}
                                        helperText={authorHelper}
                                        onBlur={()=>validate(author, setAuthorError, setAuthorHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={link}
                                        type='url'
                                        onChange={e => setLink(e.target.value)}
                                        label="Link to Resource"
                                        required
                                        error={linkError}
                                        helperText={linkHelper}
                                        onBlur={()=>validate(link, setLinkError, setLinkHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={date}
                                        type='date'
                                        onChange={e => setDate(e.target.value)}
                                        label="Publish Date"
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        error={dateError}
                                        helperText={dateHelper}
                                        onBlur={()=>validate(date, setDateError, setDateHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        value={isbnBook}
                                        onChange={e => setIsbnBook(e.target.value)}
                                        label="Book ISBN"
                                        required
                                        error={isbnBookError}
                                        helperText={isbnBookHelper}
                                        onBlur={()=>validate(isbnBook, setIsbnBookError, setIsbnBookHelper)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={props.optTags}
                                        value={formTags}
                                        onChange={(value) => setFormTags(value)}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                minHeight: '56px'
                                            })
                                        }}
                                        isOptionDisabled={() => formTags.length >= 5}
                                        placeholder='Select Key Words'
                                    />
                                </Grid>
                            </Grid>
                            <Button {...AdminBookModalStyle.resetButton} type='button' onClick={() => reset()} sx={{marginTop: '20px'}}>
                                Reset Fields
                            </Button>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button type='button' autoFocus onClick={()=>openResource()}>
                            View Resource&nbsp;<LaunchIcon {...AdminBookModalStyle.launch}/>
                        </Button>
                        <Button type='button' autoFocus onClick={()=>handleSave()}>
                            Save Changes
                        </Button>
                        <Button type='submit' form={`book-form-${props.id}`} autoFocus>
                            {props.approved? 'Disapprove':'Approve'}
                        </Button>
                        <Button type='button' autoFocus onClick={()=>handleReject()}>
                            {props.approved? 'Delete':'Reject'}
                        </Button>
                    </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default AdminBookModal