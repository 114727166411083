import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Select from 'react-select'

import { RequestFormStyle } from '../styles'
import { setErrorNotification, setSuccessNotification } from '../../reducers/notificationReducer'

import resourceService from '../../services/resources'
import {createPolicy} from "../../reducers/policiesReducer";

const PolicyForm = ({tags, user}) => {
    const dispatch = useDispatch()

    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState(false)
    const [titleHelper, setTitleHelper] = useState('')
    const [country, setCountry] = useState('')
    const [countryError, setCountryError] = useState(false)
    const [countryHelper, setCountryHelper] = useState('')
    const [institution, setInstitution] = useState('')
    const [institutionError, setInstitutionError] = useState(false)
    const [institutionHelper, setInstitutionHelper] = useState('')
    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState(false)
    const [linkHelper, setLinkHelper] = useState('')
    const [dateCreated, setDateCreated] = useState('')
    const [dateCreatedError, setDateCreatedError] = useState(false)
    const [dateCreatedHelper, setDateCreatedHelper] = useState('')
    const [dateLastAmended, setDateLastAmended] = useState('')
    const [dateLastAmendedError, setDateLastAmendedError] = useState(false)
    const [dateLastAmendedHelper, setDateLastAmendedHelper] = useState('')
    const [dateEnacted, setDateEnacted] = useState('')
    const [dateEnactedError, setDateEnactedError] = useState(false)
    const [dateEnactedHelper, setDateEnactedHelper] = useState('')
    const [formTags, setFormTags] = useState('')
    const [searchTitle, setSearchTitle] = useState('')
    const [searchTitleError, setSearchTitleError] = useState(false)
    const [searchTitleHelper, setSearchTitleHelper] = useState('')


    const validate = (val, errorHandler, helperHandler) => {
        if (val.length === 0) {
            errorHandler(true)
            helperHandler('Required Field!')
            return false
        } else {
            errorHandler(false)
            helperHandler('')
            return true
        }
    }

    const reset = () => {
        setTitle('')
        setTitleError(false)
        setTitleHelper('')
        setCountry('')
        setCountryError(false)
        setCountryHelper('')
        setInstitution('')
        setInstitutionError(false)
        setInstitutionHelper('')
        setLink('')
        setLinkError(false)
        setLinkHelper('')
        setDateCreated('')
        setDateCreatedError(false)
        setDateCreatedHelper('')
        setDateLastAmended('')
        setDateLastAmendedError(false)
        setDateLastAmendedHelper('')
        setDateEnacted('')
        setDateEnactedError(false)
        setDateEnactedHelper('')
        setFormTags('')
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (validate(title, setTitleError, setTitleHelper) && validate(country, setCountryError, setCountryHelper) && validate(institution, setInstitutionError, setInstitutionHelper) && validate(link, setLinkError, setLinkHelper) && validate(dateCreated, setDateCreatedError, setDateCreatedHelper) && validate(dateLastAmended, setDateLastAmendedError, setDateLastAmendedHelper) && validate(dateEnacted, setDateEnactedError, setDateEnactedHelper)) {
            const data = {
                title: title,
                country: country,
                institution: institution,
                link: link,
                created: dateCreated,
                updated: dateLastAmended,
                enacted: dateEnacted
            }

            if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
            else data.tags = []

            dispatch(createPolicy(data, user.staff))
            reset()
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    const handleSearch = async () => {
        if (searchTitle === '') {
            setSearchTitleError(true)
            setSearchTitleHelper('Please fill this field first!')
            return
        }
        setSearchTitleError(false)
        setSearchTitleHelper('')

        try {
            const res = await resourceService.retrievePolicy(searchTitle)
            setTitle(res.title)
            setCountry(res.country)
            setInstitution(res.institution)
            setLink(res.link)
            setDateCreated(res.created)
            setDateLastAmended(res.updated)
            setDateEnacted(res.enacted)
            dispatch(setSuccessNotification('Policy Retrieved Successfully'))
        }
        catch {
            dispatch(setErrorNotification('Policy Not Found'))
        }

        setSearchTitle('')
    }

    return (
        <>
            <Container {...RequestFormStyle.container}>
                <Typography {...RequestFormStyle.title.props} gutterBottom sx={RequestFormStyle.title.style}>
                    Policy Form
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                label="Name"
                                required
                                error={titleError}
                                helperText={titleHelper}
                                onBlur={()=>validate(title, setTitleError, setTitleHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                                label="Country"
                                required
                                error={countryError}
                                helperText={countryHelper}
                                onBlur={()=>validate(country, setCountryError, setCountryHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={institution}
                                onChange={e => setInstitution(e.target.value)}
                                label="Institution"
                                required
                                error={institutionError}
                                helperText={institutionHelper}
                                onBlur={()=>validate(institution, setInstitutionError, setInstitutionHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={link}
                                type='url'
                                onChange={e => setLink(e.target.value)}
                                label="Link to Resource"
                                required
                                error={linkError}
                                helperText={linkHelper}
                                onBlur={()=>validate(link, setLinkError, setLinkHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={dateCreated}
                                type='date'
                                onChange={e => setDateCreated(e.target.value)}
                                label="Date Created"
                                required
                                error={dateCreatedError}
                                helperText={dateCreatedHelper}
                                onBlur={()=>validate(dateCreated, setDateCreatedError, setDateCreatedHelper)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={dateLastAmended}
                                type='date'
                                onChange={e => setDateLastAmended(e.target.value)}
                                label="Date Last Amended"
                                required
                                error={dateLastAmendedError}
                                InputLabelProps={{ shrink: true }}
                                helperText={dateLastAmendedHelper}
                                onBlur={()=>validate(dateLastAmended, setDateLastAmendedError, setDateLastAmendedHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={dateEnacted}
                                type='date'
                                onChange={e => setDateEnacted(e.target.value)}
                                label="Date Enacted"
                                required
                                error={dateEnactedError}
                                InputLabelProps={{ shrink: true }}
                                helperText={dateEnactedHelper}
                                onBlur={()=>validate(dateEnacted, setDateEnactedError, setDateEnactedHelper)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={tags}
                                value={formTags}
                                onChange={(value) => setFormTags(value)}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        minHeight: '56px'
                                    })
                                }}
                                isOptionDisabled={() => formTags.length >= 5}
                                placeholder='Select Tags'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Button sx={RequestFormStyle.button.style} {...RequestFormStyle.button.props} type='submit'>{user.staff? 'Create Policy' : 'Request Book'}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    )
}

export default PolicyForm