import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LaunchIcon from '@mui/icons-material/Launch'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import StarIcon from '@mui/icons-material/Star'

import { BookListStyle } from '../styles'
import BookModal from '../modals/BookModal'

const BookList = (props) => {
    const openResource = () => {
        window.open(props.link, '_blank', 'noopener,noreferrer')
        return false;
    }

    return (
        <>
            <Box sx={BookListStyle.topContainer}>
                <Box sx={BookListStyle.topContainerIconContainer}>
                    <MenuBookIcon sx={BookListStyle.icon} />
                    <Typography {...BookListStyle.text} gutterBottom>
                        &nbsp;Published: {props.pub_date}
                    </Typography>
                </Box>
                {props.rating?
                    <Typography {...BookListStyle.text}>
                        {props.rating}/5.0 <StarIcon />
                    </Typography> :
                    <Typography {...BookListStyle.text}>
                        No Rating Yet
                    </Typography>
                }
            </Box>
            <Box>
                <Box sx={BookListStyle.titleContainer}>
                    <Typography {...BookListStyle.title} gutterBottom>
                        {props.title}
                    </Typography>
                </Box>
                <Box sx={BookListStyle.titleContainer}>
                    <Typography {...BookListStyle.description} gutterBottom>
                        {props.description}
                    </Typography>
                </Box>
                {props.tags.map(tag => <Chip sx={BookListStyle.chips} key={tag} label={tag} />)}
                <Box sx={BookListStyle.secondary}>
                    <Typography variant='p'>
                        Author(s): {props.author}
                    </Typography>
                </Box>
                <Box sx={BookListStyle.secondary}>
                    <Typography variant='p'>
                        Genre: {props.book_type_name}
                    </Typography>
                </Box>
                <Box sx={BookListStyle.secondary}>
                    <Typography variant='p'>
                        ISBN: {props.isbn}
                    </Typography>
                </Box>
                <Box sx={BookListStyle.actions}>
                    <BookModal {...props} />
                    <Button {...BookListStyle.view.props} sx={BookListStyle.view.style} onClick={() => openResource()}>View Resource&nbsp;<LaunchIcon fontSize='small' /></Button>
                </Box>
            </Box>
            <Divider />
        </>
    )
}

export default BookList