import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import { createRating, updateRating, deleteRating } from '../../reducers/ratingsReducer'
import { setErrorNotification } from '../../reducers/notificationReducer'

const RateModal = (props) => {
    const [open, setOpen] = useState(false)
    const [rating, setRating] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.rated_by_user) setRating(props.rated_by_user.rating)
    }, [props.rated_by_user])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    // rate the resource
    const handleRate = (event) => {
        event.preventDefault()
        if(rating) {
            let fav = props.favourite? props.favourite : null

            // if rated before must update not create
            if(props.rated_by_user) {
                dispatch(updateRating(props.rated_by_user.id, {
                    object_id: props.id,
                    content_type: props.content_type,
                    rating: rating
                }, props.type, props.id, fav))
            } else {
                dispatch(createRating({
                    object_id: props.id,
                    content_type: props.content_type,
                    rating: rating
                }, props.type, props.id, fav))
            }
            handleClose()
        } else {
            if (props.rated_by_user) {
                dispatch(deleteRating(props.rated_by_user.id))
            } else {
                dispatch(setErrorNotification('Please specify a rating before submitting'))
            }
        }
    }

    const handleDelete = () => {
        if(props.rated_by_user) {
            dispatch(deleteRating(props.rated_by_user.id))
            handleClose()
        } else {
            dispatch(setErrorNotification('You have not rated this resource yet'))
        }
    }

    return (
        <div>
            <Button onClick={() => handleClickOpen()}>
                Rate Resource
            </Button>
            <Dialog open={open} onClose={() => handleClose()}>
                <DialogTitle>Rate {props.title}</DialogTitle>
                    <form onSubmit={handleRate}>
                        <DialogContent>
                            <DialogContentText gutterBottom>
                                To rate this resource please fill the form below. If you have rated this resource before you will see
                                your old rating as the initial value, you may update it if you please.
                            </DialogContentText>
                            <TextField
                                value={rating}
                                label="Rating"
                                onChange={(e) => setRating(e.target.value)}
                                fullWidth
                                select
                                sx={{marginTop: '10px', marginBottom: '10px'}}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {handleClose(); return false;}}>Cancel</Button>
                            {props.rated_by_user && <Button type='button' onClick={()=>handleDelete()}>Delete Rating</Button>}
                            <Button type='submit'>Rate!</Button>
                        </DialogActions>
                    </form>
            </Dialog>
        </div>
    )
}

export default RateModal