import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Link } from 'react-router-dom'
import { PAGES } from '../navigation/routes'
import { ForgotStyle } from '../styles'

import { setErrorNotification } from '../../reducers/notificationReducer'
import { resetPasswordConfirm } from '../../reducers/userReducer'

const ConfirmForgotPassword = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const user = useSelector(state => state.user)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [passwordHelper, setPasswordHelper] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [confirmPasswordHelper, setConfirmPasswordHelper] = useState('')
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [token, setToken] = useState('')

    useEffect(() => {
        if(searchParams.get('token')) {
            setToken(searchParams.get('token'))
        } else {
            dispatch(setErrorNotification('Invalid password reset link!'))
            navigate(PAGES.home.path)
        }
    }, [])

    const validateConfirmPassword = () => {
        if((password !== confirmPassword && confirmPassword.length > 0) || (password.length > 0 && password !== confirmPassword)) {
            setConfirmPasswordHelper('Passwords Do Not Match!')
            setConfirmPasswordError(true)
            return false
        } else {
            setConfirmPasswordHelper('')
            setConfirmPasswordError(false)
            return true
        }
    }

    const validatePassword = () => {
        validateConfirmPassword()
        if(password === '') {
            setPasswordHelper('Required Field!')
            setPasswordError(true)
            return false
        } else if(password.length < 8) {
            setPasswordHelper('Minimum Length 8!')
            setPasswordError(true)
            return false
        } else {
            setPasswordHelper('')
            setPasswordError(false)
            return true
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if(validateConfirmPassword() && validatePassword()) {
            dispatch(resetPasswordConfirm({token: token, password: password}))
            setPasswordError(false)
            setPasswordHelper('')
            setPasswordVisible(false)
            setConfirmPassword('')
            setConfirmPasswordError(false)
            setConfirmPasswordHelper('')
            setConfirmPasswordVisible(false)
        } else {
            dispatch(setErrorNotification('Please fix the form errors before submission'))
        }
    }

    if(user && !user.anon) {
        return (
            <Navigate to={PAGES.home.path} />
        )
    }

    return (
        <Grid container spacing={2} sx={ForgotStyle.container}>
            <Grid item xs={12} md={5} lg={6}>
                <Container {...ForgotStyle.margins}>
                    <Typography sx={ForgotStyle.title.style} {...ForgotStyle.title.props} gutterBottom>
                        {PAGES.confirm_forgot_password.title}
                    </Typography>
                    <Typography sx={ForgotStyle.secondary.style} {...ForgotStyle.secondary.props} gutterBottom>
                        Enter your new password here
                    </Typography>
                    <form onSubmit={handleSubmit} style={ForgotStyle.form}>
                            <TextField
                                label='Password'
                                value={password}
                                onBlur={() => validatePassword()}
                                onChange={(e) => setPassword(e.target.value)}
                                helperText={passwordHelper}
                                error={passwordError}
                                required
                                type={passwordVisible? 'text' : 'password'}
                                sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                                edge="end"
                                            >
                                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            <TextField
                                label='Confirm Password'
                                value={confirmPassword}
                                onBlur={() => validateConfirmPassword()}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                helperText={confirmPasswordHelper}
                                error={confirmPasswordError}
                                required
                                type={confirmPasswordVisible? 'text' : 'password'}
                                sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                                edge="end"
                                            >
                                                {confirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        <Button sx={ForgotStyle.button.style} {...ForgotStyle.button.props} type='submit'>Reset Password</Button>
                    </form>
                    <Box sx={ForgotStyle.register}>
                        <Typography {...ForgotStyle.registerText.props} sx={ForgotStyle.registerText.style}>
                            Not Registered Yet?&nbsp;
                        </Typography>
                        <Link to={PAGES.sign_up.path} style={ForgotStyle.registerLink}>
                            <Typography {...ForgotStyle.createAccount.props} sx={ForgotStyle.createAccount.style}>
                                Create an Account
                            </Typography>
                        </Link>
                    </Box>
                </Container>
            </Grid>
            <Grid item md={7} lg={6} sx={ForgotStyle.imageSection}>
                <Container {...ForgotStyle.imageSectionContainer.props} sx={ForgotStyle.imageSectionContainer.style}>
                    <img src='/static/logo.svg' style={ForgotStyle.image}/>
                    <Typography {...ForgotStyle.imageText.props} sx={ForgotStyle.imageText.style} gutterBottom>
                        Temerty Centre for Artificial Intelligence Research and Education in Medicine
                    </Typography>
                </Container>
            </Grid>
        </Grid>
    )
}

export default ConfirmForgotPassword