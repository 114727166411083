import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { HomeStyle } from '../styles'
import Button from '@mui/material/Button'

const Home = () => {
    return (
        <>
            <Box sx={HomeStyle.landingContainer}>
                <Grid sx={HomeStyle.landingGrid} container spacing={0}>
                    <Grid item lg={7} sx={HomeStyle.landingTextContainer}>
                        <Typography sx={HomeStyle.lgTitle.style} {...HomeStyle.lgTitle.props} gutterBottom>
                            Welcome to the <span style={HomeStyle.tcairemWrap}>T-CAIREM</span> Resources Hub
                        </Typography>
                        <Typography sx={HomeStyle.xlTitle.style} {...HomeStyle.xlTitle.props} gutterBottom>
                            Welcome to the <span style={HomeStyle.tcairemWrap}>T-CAIREM</span> Resources Hub
                        </Typography>
                        <Typography {...HomeStyle.landingText} gutterBottom>
                            T-CAIREM's Resources Hub has been curated specially for AI in Medicine learners. Experts in the field
                            have selected some of the most important and helpful books, research papers, tutorials, videos, and
                            podcasts for learners at all levels.
                        </Typography>
                        <br/>
                        <Grid container spacing={2} margin='0 auto'>
                            <Box sx={{ ...HomeStyle.learnButton, marginTop: '5rem' }}>
                                <Link href='/start-here/' {...HomeStyle.link.props} sx={{ ...HomeStyle.link.style, fontSize: '2rem' }}>
                                    Start Here
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item lg={5} sx={{background: `url(${"/static/landing_image.jpg"})`, height: '100%', width: '100%', backgroundSize: 'cover'}}>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{display: {xs: 'flex',lg: 'none'}, height: '100vh', overflowX: 'hidden', background: `url(${"/static/landing_image.jpg"})`,backgroundSize: 'cover'}}>
                <Box sx={HomeStyle.mobileTextContainer}>
                    <Typography gutterBottom sx={HomeStyle.mobileText.style} {...HomeStyle.mobileText.props}>
                        Welcome to the <span style={HomeStyle.tcairemWrap}>T-CAIREM</span> Resources Hub
                    </Typography>
                </Box>
            </Box>
            <Box sx={HomeStyle.aboutContainer}>
                <Container {...HomeStyle.margins}>
                    <Grid sx={HomeStyle.aboutGrid} container spacing={1}>
                        <Grid item lg={6} sx={HomeStyle.aboutGrid}>
                            <Typography {...HomeStyle.aboutTitle} gutterBottom>
                                About T-CAIREM
                            </Typography>
                            <Typography {...HomeStyle.aboutParagraph} gutterBottom>
                                Based at the University of Toronto, T-CAIREM works toward transforming health through AI. We seek to
                                establish world-class educational programs in AI in medicine, fund research opportunities, and house
                                datasets for applied AI learning and research. If you have any questions or suggestions, please contact us:
                                tcairem.comms@utoronto.ca.
                            </Typography>
                            <Box sx={HomeStyle.learnButton}>
                                <Link href='https://tcairem.utoronto.ca/' {...HomeStyle.link.props} sx={HomeStyle.link.style}>
                                    Learn More About T-CAIREM
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item lg={2} sx={HomeStyle.aboutImageContainer}>
                            <img src='/static/logo.svg' style={HomeStyle.aboutImage}/>
                        </Grid>
                        <Grid item lg={4} sx={HomeStyle.aboutImageTextContainer}>
                            <Typography {...HomeStyle.aboutImageText} gutterBottom>
                                Temerty Centre for Artificial Intelligence Research and Education in Medicine
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Home