import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'

import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from 'react-chartjs-2'
import CountUp from 'react-countup'

import { getResourceChart, getPieChartOptions, getUserCountryChart, getUserInstitutionChart, getTagCloud } from './chartConfigs'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MUITooltip from '@mui/material/Tooltip'

import BookCard from '../cards/BookCard'
import PaperCard from '../cards/PaperCard'
import PolicyCard from '../cards/PolicyCard'
import CourseCard from '../cards/CourseCard'
import VideoCard from '../cards/VideoCard'
import PodcastCard from '../cards/PodcastCard'
import Loading from '../loading/Loading'

import { TagCloud } from 'react-tagcloud'

import { PAGES } from '../navigation/routes'

import { setErrorNotification } from '../../reducers/notificationReducer'
import { setUserOnRefresh } from '../../reducers/userReducer'
import { initializeResources } from '../../reducers/resourcesReducer'
import { initializeUsers } from '../../reducers/usersReducer'
import { AdminStyle } from '../styles'

// need to register what you use to chart js
Chart.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels)

const Admin = () => {
    const user = useSelector(state => state.user)
    const users = useSelector(state => state.users)
    const resources = useSelector(state => state.resources)
    const [disapproved, setDisapproved] = useState(null)
    const [approved, setApproved] = useState(null)
    const [sorted, setSorted] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // force user update
    useEffect(() => {
        dispatch(setUserOnRefresh())
    }, [])

    useEffect(() => {
        if(resources) {
            const a = resources.filter(resource => resource.approved)
            setDisapproved(resources.filter(resource => !resource.approved))
            setApproved(a)
            // sort resources
            setSorted(a.filter(resource => resource.rating).sort((x, y) => x.rating - y.rating))
        }
    }, [resources])

    // if user is not staff then force them out should not be here
    useEffect(() => {
        if(user) {
            if (user.staff) {
                dispatch(initializeResources())
                dispatch(initializeUsers())
            } else {
                dispatch(setErrorNotification('You are not authorized to view this page'))
                navigate(PAGES.home.path)
            }
        }
    }, [user])

    const customRenderer = (tag, size, color) => (
        <span key={tag.value}>
            <MUITooltip title={`Appearance: ${tag.count}`}>
                <Box
                    sx={{
                        fontSize: size,
                        display: 'inline-block',
                        color: color,
                        transition: 'ease 500ms',
                        '&:hover': {
                            textShadow: '1px 1px 2px #999999'
                        }
                    }}
                >
                    {tag.value}
                </Box>
            </MUITooltip>
        </span>
    )

    // if loading return loading component
    if(disapproved === null || approved === null || sorted === null) {
        return (
            <Container {...AdminStyle.loading.props} sx={AdminStyle.loading.style}>
                <Loading />
            </Container>
        )
    }

    // page below has admin operations and analytics
    return (
        <>
            <Container {...AdminStyle.container.props} sx={AdminStyle.container.style}>
                <Typography {...AdminStyle.title}  gutterBottom>
                    Administration
                </Typography>
                <Box sx={AdminStyle.secondary}>
                    <Typography {...AdminStyle.secondaryText} gutterBottom>
                        Welcome to the Admin Portal
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item sx={{display: {xs: 'none', md: 'flex'}}} md={1}></Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Link to={PAGES.request.path} style={AdminStyle.navLink}>
                            <Box sx={AdminStyle.navContainer}>
                                <Typography variant='p'>
                                    Create Resource
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Link to={PAGES.manage_requests.path} style={AdminStyle.navLink}>
                            <Box sx={AdminStyle.navContainer}>
                                <Typography variant='p'>
                                    {PAGES.manage_requests.title}
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Link to={PAGES.manage_resources.path} style={AdminStyle.navLink}>
                            <Box sx={AdminStyle.navContainer}>
                                <Typography variant='p'>
                                    {PAGES.manage_resources.title}
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Link to={PAGES.manage_tags.path} style={AdminStyle.navLink}>
                            <Box sx={AdminStyle.navContainer}>
                                <Typography variant='p'>
                                    {PAGES.manage_tags.title}
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Link to={PAGES.manage_admin.path} style={AdminStyle.navLink}>
                            <Box sx={AdminStyle.navContainer}>
                                <Typography variant='p'>
                                    {PAGES.manage_admin.title}
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
            <Container {...AdminStyle.analytics.props} sx={AdminStyle.analytics.style}>
                <Typography sx={AdminStyle.analyticsTitle.style} {...AdminStyle.analyticsTitle.props} gutterBottom>
                    Site Analytics
                </Typography>
                <Grid container spacing={4} sx={AdminStyle.analyticsGrid}>
                    <Grid item xs={12} md={4} sx={AdminStyle.analyticsNumContainer}>
                        <Box sx={AdminStyle.analyticsNum}>
                            <Typography {...AdminStyle.analyticsNumText} gutterBottom>
                                <CountUp end={users.length} /> Users
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sx={AdminStyle.analyticsNumContainer}>
                        <Box sx={AdminStyle.analyticsNum}>
                            <Typography {...AdminStyle.analyticsNumText} gutterBottom>
                                <CountUp end={approved.length} /> Approved Resources
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sx={AdminStyle.analyticsNumContainer}>
                        <Box sx={AdminStyle.analyticsNum}>
                            <Typography {...AdminStyle.analyticsNumText} gutterBottom>
                                <CountUp end={disapproved.length} /> Pending Requests
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={AdminStyle.graphContainer}>
                            <Doughnut data={getUserCountryChart(users)} options={getPieChartOptions(`Users' Country Breakdown`)} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={AdminStyle.graphContainer}>
                            <Doughnut data={getUserInstitutionChart(users)} options={getPieChartOptions(`Users' Institution Breakdown`)} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={AdminStyle.graphContainer}>
                            {approved.length > 0? <Doughnut data={getResourceChart(approved)} options={getPieChartOptions(`Approved Resources (Total: ${approved.length})`)} />:<Typography variant='h4' gutterBottom>There are no resources currently!</Typography>}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={AdminStyle.graphContainer}>
                            {disapproved.length > 0? <Doughnut data={getResourceChart(disapproved)} options={getPieChartOptions(`Pending Resources (Total: ${disapproved.length})`)}/>:<Typography variant='h4' gutterBottom>There are no requests currently!</Typography>}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={AdminStyle.rating}>
                            <Typography {...AdminStyle.ratingTitle} gutterBottom>Top 10 Rated Resources</Typography>
                            {sorted.length > 0? <Grid container spacing={2}>
                                {sorted.reverse().slice(0, 10).map(resource => {
                                    if (resource.type === 'Book') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <BookCard {...resource}/>
                                            </Grid>
                                        )
                                    } else if (resource.type === 'Course') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <CourseCard {...resource}/>
                                            </Grid>
                                        )
                                    } else if (resource.type === 'Paper') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4}
                                                  sx={AdminStyle.ratingCardContainer}>
                                                <PaperCard {...resource}/>
                                            </Grid>
                                        )
                                    } else if (resource.type === 'Policy') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4}
                                                  sx={AdminStyle.ratingCardContainer}>
                                                <PolicyCard {...resource}/>
                                            </Grid>
                                        )
                                    } else if (resource.type === 'Podcast') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <PodcastCard {...resource}/>
                                            </Grid>
                                        )
                                    } else {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <VideoCard {...resource}/>
                                            </Grid>
                                        )
                                }})}</Grid> : <Typography {...AdminStyle.noRatingTitle} gutterBottom>There are no rated resources currently!</Typography>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={AdminStyle.rating}>
                            <Typography {...AdminStyle.ratingTitle} gutterBottom>Lowest 10 Rated Resources</Typography>
                            {sorted.length > 0? <Grid container spacing={2}>
                                {sorted.slice(0, 10).reverse().map(resource => {
                                    if (resource.type === 'Book') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <BookCard {...resource}/>
                                            </Grid>
                                        )
                                    } else if (resource.type === 'Course') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <CourseCard {...resource}/>
                                            </Grid>
                                        )
                                    } else if (resource.type === 'Paper') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <PaperCard {...resource}/>
                                            </Grid>
                                        )
                                    } else if (resource.type === 'Policy') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <PolicyCard {...resource}/>
                                            </Grid>
                                        )
                                    } else if (resource.type === 'Podcast') {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <PodcastCard {...resource}/>
                                            </Grid>
                                        )
                                    } else {
                                        return (
                                            <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={AdminStyle.ratingCardContainer}>
                                                <VideoCard {...resource}/>
                                            </Grid>
                                        )
                                }})}</Grid> : <Typography {...AdminStyle.noRatingTitle} gutterBottom>There are no rated resources currently!</Typography>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={AdminStyle.rating}>
                            <Typography {...AdminStyle.ratingTitle} gutterBottom>Tag Presence Across Hub</Typography>
                            <Box>
                                <TagCloud
                                    minSize={15}
                                    maxSize={100}
                                    tags={getTagCloud(resources)}
                                    colorOptions={{
                                        luminosity: 'dark',
                                        format: 'rgba',
                                        alpha: 0.5
                                    }}
                                    renderer={customRenderer}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Admin