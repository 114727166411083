import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'

import BookCard from '../cards/BookCard'
import PaperCard from '../cards/PaperCard'
import CourseCard from '../cards/CourseCard'
import VideoCard from '../cards/VideoCard'
import PodcastCard from '../cards/PodcastCard'
import PolicyCard from '../cards/PolicyCard'

import BookList from '../lists/BookList'
import PaperList from '../lists/PaperList'
import CourseList from '../lists/CourseList'
import VideoList from '../lists/VideoList'
import PodcastList from '../lists/PodcastList'
import PolicyList from '../lists/PolicyList'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loading from '../loading/Loading'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import ReorderIcon from '@mui/icons-material/Reorder'
import AppsIcon from '@mui/icons-material/Apps'
import MenuItem from '@mui/material/MenuItem'

import Select from 'react-select'

import { initializeResources, searchResources } from '../../reducers/resourcesReducer'
import { initializeTags } from '../../reducers/tagsReducers'

import { buildQuery, buildSearchQuery, searchBarQuery } from '../../services/utils.js/query'
import { PAGES } from '../navigation/routes'
import { PAGE_SIZE } from '../websiteConfig'
import { SearchStyle } from '../styles'

const Resources = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const [types, setTypes] = useState(['Book', 'Course', 'Paper', 'Podcast', 'Policy', 'Video'])
    const [resourcesToShow, setResourcesToShow] = useState(null)
    const [resourcesSorted, setResourcesSorted] = useState(null)
    const [sortRes, setSortRes] = useState(1)
    const [list, setList] = useState(true)

    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [title, setTitle] = useState('')
    const [formTags, setFormTags] = useState('')

    const user = useSelector(state => state.user)
    const resources = useSelector(state => state.resources)
    const tags = useSelector(state => state.tags)
    const tagNames = tags.map(tag => { return { value: tag.name, label: tag.name }})
    const dispatch = useDispatch()

    useEffect(() => {
        if(searchParams.toString()) {
            let search = searchParams.get('search')
            if (search) {
                const query = searchBarQuery(search)
                dispatch(searchResources(query))
            } else {
                let formTagsValue = searchParams.get('tags')
                let startValue = searchParams.get('start_date')
                let endValue = searchParams.get('end_date')
                let titleValue = searchParams.get('title')

                if(formTagsValue) setFormTags(formTagsValue.split(',').map(tag => { return {label: tag, value: tag} }))
                if(startValue) setStart(startValue)
                if(endValue) setEnd(endValue)
                if(titleValue) setTitle(titleValue)

                const query = buildSearchQuery(startValue, endValue, titleValue, formTagsValue)
                dispatch(searchResources(query))
            }
        } else {
            dispatch(initializeResources())
        }
        dispatch(initializeTags())
        setPage(1)
    }, [searchParams, user])

    useEffect(() => {
        if(resourcesSorted !== null) {
            const r = (user && user.staff) ? resourcesSorted.filter(resource => resource.approved).filter(resource => types.includes(resource.type)) : resourcesSorted.filter(resource => types.includes(resource.type))
            setResourcesToShow(r)
            setPage(1)
        }
    }, [types, resourcesSorted])

    useEffect(() => {
        if(resources !== null) {
            const r = [...resources]
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return 1
                if (y.rating === null) return -1
                return y.rating - x.rating
            }))
        }
    }, [resources])

    const handleSort = (event) => {
        const r = [...resources]
        setSortRes(event.target.value)

        if (event.target.value == 1) {
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return 1
                if (y.rating === null) return -1
                return y.rating - x.rating
            }))
        } else if (event.target.value == 2) {
            setResourcesSorted(r.sort((x, y) => {
                if (x.rating === null) return -1
                if (y.rating === null) return 1
                return x.rating - y.rating
            }))
        } else if (event.target.value == 3) {
            setResourcesSorted(r.sort((x, y) => new Date(x.pub_date) - new Date(y.pub_date)))
        } else {
            setResourcesSorted(r.sort((x, y) => new Date(y.pub_date) - new Date(x.pub_date)))
        }
    }

    const changeTypes = (type, val) => {
        if (val) {
            setTypes(types.concat(type))
        } else {
            setTypes(types.filter(resType => resType !== type))
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        let formTagsValue = null
        let startValue = null
        let endValue = null
        let titleValue = null
        if(formTags && formTags.length > 0) formTagsValue = formTags.map(tag => tag.value)
        if(start) startValue = start
        if(end) endValue = end
        if(title) titleValue = title

        const query = buildQuery(startValue, endValue, titleValue, formTagsValue)
        const currentQuery = buildSearchQuery(searchParams.get('start_date'), searchParams.get('end_date'), searchParams.get('title'), searchParams.get('tags'))

        if(query !== currentQuery) {
            setResourcesToShow(null)
            navigate(`${PAGES.resources.path}?${query}`)
        }
    }

    const pageSize = PAGE_SIZE
    const [page, setPage] = useState(1)

    return (
        <Container sx={SearchStyle.container}>
        <Typography {...SearchStyle.title} gutterBottom >
            Resources
        </Typography>
        <form style={SearchStyle.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        sx={{width: '100%'}}
                        variant='outlined'
                        label='Resource Title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                sx={{width: '100%'}}
                                variant='outlined'
                                type='date'
                                label='Start Date'
                                InputLabelProps={{ shrink: true }}
                                value={start}
                                onChange={(e) => setStart(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                sx={{width: '100%'}}
                                variant='outlined'
                                type='date'
                                label='End Date'
                                InputLabelProps={{ shrink: true }}
                                value={end}
                                onChange={(e) => setEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={tagNames}
                        value={formTags}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                minHeight: '56px'
                            })
                        }}
                        onChange={(value) => setFormTags(value)}
                        placeholder='Select Key Words'
                    />
                </Grid>
            </Grid>
            <Box sx={SearchStyle.buttonLocation}>
                <Button
                    variant="contained"
                    disabled={resourcesToShow === null}
                    type='submit'
                >
                {resourcesToShow === null ?
                    <CircularProgress
                    size={24}
                    sx={{
                        color: '#FFF',
                    }}
                    /> : <>Search</>
                }
                </Button>
            </Box>
        </form>
        <Box sx={SearchStyle.filterHeadingContainer}>
            <Typography {...SearchStyle.filterHeading} gutterBottom>
                Filter By Resource Type
            </Typography>
        </Box>
        <Grid container spacing={2} sx={SearchStyle.filtersContainer}>
            <Grid item xs={12} sm={6}>
                <Box sx={SearchStyle.groupsContainer}>
                    <FormGroup sx={SearchStyle.formGroup}>
                    <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Book', e.target.checked)}/>} label="Books" />
                        <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Course', e.target.checked)}/>} label="Courses" />
                        <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Paper', e.target.checked)}/>} label="Papers" />
                        <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Podcast', e.target.checked)}/>} label="Podcasts" />
                        <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Policy', e.target.checked)}/>} label="Policies" />
                        <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>changeTypes('Video', e.target.checked)}/>} label="Videos" />
                    </FormGroup>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
                    <TextField
                            value={sortRes}
                            select
                            label="Sort By"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleSort}
                            sx={{width: '50%'}}
                        >
                        <MenuItem value={1}>Rating Ascending</MenuItem>
                        <MenuItem value={2}>Rating Descending</MenuItem>
                        <MenuItem value={3}>Date Ascending</MenuItem>
                        <MenuItem value={4}>Date Descending</MenuItem>
                    </TextField>
                    <Box sx={SearchStyle.viewContainer}>
                        <IconButton sx={{ color: list? '#002A5C' : '#CECFCB'}} onClick={() => setList(true)}>
                            <ReorderIcon />
                        </IconButton>
                        <IconButton sx={{ color: !list? '#002A5C' : '#CECFCB'}} onClick={() => setList(false)}>
                            <AppsIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
        </Grid>
        {resourcesToShow === null && <Loading />}
        {resourcesToShow !== null && ((resourcesToShow.length > 0)?
            <>
                <Grid container spacing={2}>
                    {resourcesToShow.slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(resource => {
                        if(list) {
                            if (resource.type === 'Book') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                        <BookList {...resource}/>
                                    </Grid>
                                )
                            } else if (resource.type === 'Course') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                        <CourseList {...resource}/>
                                    </Grid>
                                )
                            } else if (resource.type === 'Paper') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                        <PaperList {...resource}/>
                                    </Grid>
                                )
                            } else if (resource.type === 'Podcast') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                        <PodcastList {...resource}/>
                                    </Grid>
                                )
                            } else if (resource.type === 'Policy') {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                        <PolicyList {...resource}/>
                                    </Grid>
                                )
                            } else {
                                return (
                                    <Grid key={`${resource.type} ${resource.id}`} item xs={12}>
                                        <VideoList {...resource}/>
                                    </Grid>
                                )
                            }
                        }

                        if (resource.type === 'Book') {
                            return (
                                <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <BookCard {...resource}/>
                                </Grid>
                            )
                        } else if (resource.type === 'Course') {
                            return (
                                <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <CourseCard {...resource}/>
                                </Grid>
                            )
                        } else if (resource.type === 'Paper') {
                            return (
                                <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <PaperCard {...resource}/>
                                </Grid>
                            )
                        } else if (resource.type === 'Podcast') {
                            return (
                                <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <PodcastCard {...resource}/>
                                </Grid>
                            )
                        } else if (resource.type === 'Policy') {
                            return (
                                <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <PolicyCard {...resource}/>
                                </Grid>
                            )
                        } else {
                            return (
                                <Grid key={`${resource.type} ${resource.id}`} item xs={12} md={4} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <VideoCard {...resource}/>
                                </Grid>
                            )
                        }
                    })}
                </Grid>
                <Pagination sx={SearchStyle.pagination} count={Math.ceil(resourcesToShow.length/pageSize)} page={page} onChange={(event, value) => setPage(value)} />
            </> :
            <Typography {...SearchStyle.noResources.props} gutterBottom sx={SearchStyle.noResources.style}>
                No Resources Found
            </Typography>)
        }
        <Box sx={{textAlign: 'center', padding: '10px'}}>
            <Typography variant='overline' component='p'>
                All resources provided are strictly owned and distributed by the creators indicated on the resource
            </Typography>
        </Box>
    </Container>
    )
}

export default Resources