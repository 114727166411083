import resourceService from '../services/resources'
import bookService from '../services/books'
import courseService from '../services/courses'
import paperService from '../services/papers'
import policyService from '../services/policies'
import podcastService from '../services/podcasts'
import videoService from '../services/videos'
import { createSlice } from '@reduxjs/toolkit'

import { setSuccessNotification, setErrorNotification } from './notificationReducer'

const initialState = null

// toolkit sets up the redux and state
const resourceSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        setResources(state, action) {
            return action.payload
        },
        changeResource(state, action) {
            if (state === null) return state
            return state.map((resource) => (resource.id === action.payload.id && resource.type === action.payload.type)? action.payload : resource)
        },
        destroyResource(state, action){
            if (state === null) return state
            return state.filter(resource => (resource.id !== action.payload.id || resource.type !== action.payload.type))
        }
    }
})

export const { setResources, changeResource, destroyResource } = resourceSlice.actions

export const initializeResources = () => {
    return async dispatch => {
        try {
            const resources = await resourceService.retrieve()
            dispatch(setResources(resources))
        } catch {
            dispatch(setErrorNotification('Error retrieving resources'))
        }
    }
}

export const searchResources = (query) => {
    return async dispatch => {
        try {
            const resources = await resourceService.retrieve(query)
            dispatch(setResources(resources))
        } catch {
            dispatch(setErrorNotification('Error fulfilling search'))
        }
    }
}

export const refreshResource = (id, type) => {
    return async dispatch => {
        try {
            if (type === 'book') {
                const book = await bookService.retrieveSingle(id)
                book.type = 'Book'
                dispatch(changeResource(book))
            } else if (type === 'course') {
                const course = await courseService.retrieveSingle(id)
                course.type = 'Course'
                dispatch(changeResource(course))
            } else if (type === 'paper') {
                const paper = await paperService.retrieveSingle(id)
                paper.type = 'Paper'
                dispatch(changeResource(paper))
            } else if (type === 'policy') {
                const policy = await policyService.retrieveSingle(id)
                policy.type = 'Policy'
                dispatch(changeResource(policy))
            } else if (type === 'podcast') {
                const podcast = await podcastService.retrieveSingle(id)
                podcast.type = 'Podcast'
                dispatch(changeResource(podcast))
            } else if (type === 'video') {
                const video = await videoService.retrieveSingle(id)
                video.type = 'Video'
                dispatch(changeResource(video))
            }
        } catch { }
    }
}

export const deleteResource = (id, type) => {
    return async dispatch => {
        dispatch(destroyResource({
            id: id,
            type: type
        }))
    }
}

export const toggleApproveResource = (res) => {
    return async dispatch => {
        dispatch(changeResource(res))
    }
}

export default resourceSlice.reducer