import React, {useState} from 'react'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import Collapse from '@mui/material/Collapse'
import useTheme from '@mui/material/styles/useTheme'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'

import { Link } from 'react-router-dom'

import { PAGES, MOBILE_NAV_ROUTES } from './routes'
import { MobileNavStyle } from '../styles'

const MobileNav = ({logoutHandler}) => {
    const theme = useTheme()
    const small_screen = theme.breakpoints.values.sm
    const [show, setShow] = useState(false)

    const user = useSelector(state => state.user)
    const toggleShow = () => setShow(!show)

    // If mobile the drop down style changes
    const dropDownStyle = () => {
        if (window.innerWidth < small_screen) {
            return MobileNavStyle.dropDownXS
        }
        return MobileNavStyle.dropDownS
    }

    return (
        <>
            <Box sx={MobileNavStyle.navIconBox}>
                <IconButton {...MobileNavStyle.navIcon} onClick={() => toggleShow()}>
                    {show? <CloseIcon />:<MenuIcon />}
                </IconButton>
            </Box>
            <Box sx={MobileNavStyle.headerContainer}>
                <Link to={PAGES.home.path} style={MobileNavStyle.reactLink} >
                    <Icon sx={MobileNavStyle.headerIcon}>
                        <img src='/static/logo.svg'/>
                    </Icon>
                </Link>
                <Link to={PAGES.home.path} style={MobileNavStyle.reactLink} >
                    <Typography noWrap {...MobileNavStyle.header.props} sx={MobileNavStyle.header.style} >
                        {PAGES.home.mobile_title}
                    </Typography>
                </Link>
            </Box>

            <Box sx={dropDownStyle()}>
                <Collapse in={show}>
                    <Box sx={MobileNavStyle.listContainer}>
                        <List {...MobileNavStyle.list.props} sx={MobileNavStyle.list.style} >
                            {MOBILE_NAV_ROUTES.filter(route => route.user === false).map(route => (
                                <Box key={route.path}>
                                    <Divider light />
                                    <Link to={route.path} style={MobileNavStyle.reactLink} >
                                        <ListItemButton onClick={() => toggleShow()} sx={MobileNavStyle.listButton}>
                                            <ListItemIcon sx={MobileNavStyle.listIcon}>
                                                <route.icon />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={MobileNavStyle.listText} primary={route.title} />
                                        </ListItemButton>
                                    </Link>
                                </Box>
                            ))}
                            {user? MOBILE_NAV_ROUTES.filter(route => (route.user && !route.staff)).map(route => (
                                <Box key={route.path}>
                                    <Divider light />
                                    <Link to={route.path} style={MobileNavStyle.reactLink} >
                                        <ListItemButton onClick={() => toggleShow()} sx={MobileNavStyle.listButton}>
                                            <ListItemIcon sx={MobileNavStyle.listIcon}>
                                                <route.icon />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={MobileNavStyle.listText} primary={route.title} />
                                        </ListItemButton>
                                    </Link>
                                </Box>
                            )) :
                            <Box>
                                <Divider light/>
                                <Link to={PAGES.login.path} style={MobileNavStyle.reactLink} >
                                    <ListItemButton onClick={() => toggleShow()} sx={MobileNavStyle.listButton}>
                                        <ListItemIcon sx={MobileNavStyle.listIcon}>
                                            <LoginIcon />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={MobileNavStyle.listText} primary={PAGES.login.title} />
                                    </ListItemButton>
                                </Link>
                            </Box>
                            }
                            {user && user.staff && MOBILE_NAV_ROUTES.filter(route => route.staff).map(route => (
                                <Box key={route.path}>
                                    <Divider light />
                                    <Link to={route.path} style={MobileNavStyle.reactLink} >
                                        <ListItemButton onClick={() => toggleShow()} sx={MobileNavStyle.listButton}>
                                            <ListItemIcon sx={MobileNavStyle.listIcon}>
                                                <route.icon />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={MobileNavStyle.listText} primary={route.title} />
                                        </ListItemButton>
                                    </Link>
                                </Box>
                            ))}
                            {user && <Box>
                                <Divider light/>
                                <ListItemButton onClick={() => logoutHandler(false)} sx={{paddingLeft: '36px'}}>
                                    <ListItemIcon sx={MobileNavStyle.listIcon}>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primaryTypographyProps={MobileNavStyle.listText} primary={'Logout'} />
                                </ListItemButton>
                            </Box>}
                            <Divider light/>
                        </List>
                    </Box>
                </Collapse>
            </Box>
        </>
    )
}

export default MobileNav