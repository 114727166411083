export const sections = [
  {
    title: "AI and Healthcare",
    content: [
      {
        title: "Implementing machine learning in medicine",
        url: "https://www.cmaj.ca/content/193/34/E1351",
        description: "This article presents a case example and principles of how to effectively design and implement an AI-based tool to enhance patient care"
      },
      {
        title: "How data science is transforming the healthcare industry",
        url: "https://www.datacamp.com/podcast/how-data-science-is-transforming-the-healthcare-industry",
        description: "This podcast discusses how healthcare is changing in the age of big data and AI"
      }
    ]
  },
  {
    title: "Statistics and Machine Learning Primer",
    content: [
      {
        title: "Introduction to Statistics",
        url: "https://www.coursera.org/learn/stanford-statistics",
        description: "This online course provides a fundamental understanding of probability and statistics"
      },
      {
        title: "Mathematics for Machine Learning",
        url: "https://www.google.ca/books/edition/Mathematics_for_Machine_Learning/pFjPDwAAQBAJ?hl=en&gbpv=0",
        description: "This book provides a general mathematical background, including calculus and statistics, which are required to understand how most machine learning algorithms work on a granular level."
      },
      {
        title: "Probabilistic Machine Learning",
        url: "https://books.google.ca/books?id=8iycEAAAQBAJ&dq=isbn:0262048434&hl=&source=gbs_api",
        description: "This book covers more complex machine learning methods"
      },
      {
        title: "Deep Learning",
        url: "https://www.google.ca/books/edition/Deep_Learning/omivDQAAQBAJ?hl=en&gbpv=0",
        description: "This book covers the theoretical fundamentals of deep learning"
      }
    ]
  },
  {
    title: "Implementing Machine Learning Models",
    content: [
      {
        title: "Data Science from Scratch: First Principles with Python",
        url: "https://www.google.ca/books/edition/Data_Science_from_Scratch/7iLNrQEACAAJ?hl=en",
        description: "This book reviews how to process, analyse, and create machine learning models with various datasets in Python. This book includes code examples."
      },
      {
        title: "Hands-On Machine Learning with Scikit-Learn, Keras, and Tensorflow",
        url: "https://www.google.ca/books/edition/Hands_On_Machine_Learning_with_Scikit_Le/HnetDwAAQBAJ?hl=en&gbpv=0",
        description: "This book reviews how to implement a wide range of machine learning algorithms in Python, with code examples"
      },
      {
        title: "D2L.ai: Interactive Deep Learning Book with Multi-Framework Code, Math, and Discussions",
        url: "https://github.com/d2l-ai/d2l-en?tab=readme-ov-file",
        description: "This resource includes code, and reviews the fundamentals of various machine learning concepts and architectures."
      },
      {
        title: "Deep Learning for Medical Image Analysis",
        url: "https://shop.elsevier.com/books/deep-learning-for-medical-image-analysis/zhou/978-0-323-85124-4",
        description: "This book provides a comprehensive overview of deep learning, specifically for medical images"
      }
    ]
  },
  {
    title: "Natural language processing: An introduction",
    content: [
      {
        title: "Natural language processing: An introduction",
        url: "https://doi.org/10.1136/amiajnl-2011-000464",
        description: "This article provides an overview of the key concepts and considerations of NLP, with a focus on the medical field"
      },
      {
        title: "Speech and Language Processing",
        url: "https://web.stanford.edu/~jurafsky/slp3/",
        description: "This book provides a thorough review of traditional natural language processing techniques"
      },
      {
        title: "Natural Language Processing with Transformers",
        url: "https://transformersbook.com/",
        description: "This book provides an introduction to designing algorithms that use transformers, the state-of-the-art architecture for natural language processing"
      }
    ]
  },
  {
    title: "Validating and Evaluating a Model",
    content: [
      {
        title: "Evaluation of machine learning solutions in medicine",
        url: "https://www.cmaj.ca/content/193/36/E1425",
        description: "This article summarizes the steps involved in effective validation of a predictive algorithm."
      },
      {
        title: "TRIPOD+AI statement: Updated guidance for reporting clinical prediction models that use regression or machine learning methods",
        url: "https://www.bmj.com/content/385/bmj-2023-078378",
        description: "TRIPOD+AI provides guidance to authors who wish to report replicable, transparent, and well-conducted studies involving machine learning or regression."
      },
      {
        title: "APPRAISE-AI Tool for Quantitative Evaluation of AI Studies for Clinical Decision Support",
        url: "https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2809841",
        description: "APPRAISE-AI is a tool with which to measure the quality of reporting and study design of studies involving AI for clinical decision support"
      }
    ]
  },
  {
    title: "Bias and Bias Reduction",
    content: [
      {
        title: "Artificial intelligence and algorithmic bias: Implications for health systems",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6875681/",
        description: "This statement describes algorithmic bias and presents some general mitigation strategies."
      },
      {
        title: "Considerations for addressing bias in artificial intelligence for health equity",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10497548/",
        description: "This statement presents an AI product lifecycle and describes sources of bias originating from each stage, as well as potential mitigation strategies."
      }
    ]
  },
  {
    title: "Ethics of AI and Healthcare",
    content: [
      {
        title: "Machine learning in medicine: Addressing ethical challenges",
        url: "https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002689",
        description: "This paper provides guiding principles to address ethical concerns related to AI in healthcare"
      }
    ]
  },
  {
    title: "Legal Landscape of AI and Healthcare",
    content: [
      {
        title: "Artificial Intelligence - Revolutionizing the Healthcare Industry",
        url: "https://capra.ca/en/blog/artificial-intelligence-revolutionizing-the-healthcare-industry-2023-10-27",
        description: "This statement summarizes the current legal landscape of AI regulation in the Canadian healthcare system"
      },
      {
        title: "Regulating the Safety of Health-Related Artificial Intelligence",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9170055/",
        description: "This paper summarizes current safety concerns and gaps in legislation that may pose a risk to patients and healthcare institutions when using AI-based technologies."
      }
    ]
  },
  {
    title: "Examples of Well-Conducted Studies",
    content: [
      {
        title: "A real-world demonstration of machine learning generalizability in the detection of intracranial hemorrhage on head computerized tomography",
        url: "https://www.nature.com/articles/s41598-021-95533-2",
        description: "This study presents a computer vision model trained on a large dataset and externally validated on a large, independent dataset with only a minimal decrease in performance. Model architecture and training workflow are well-reported."
      },
      {
        title: "Accurate structure prediction of biomolecular interactions with AlphaFold3",
        url: "https://www.nature.com/articles/s41586-024-07487-w",
        description: "This study demonstrates how machine learning can be used to enhance our understanding of biological systems, by predicting 3D protein structure based on amino acid sequences. AlphaFold is a landmark algorithm that helped bridge the gap between deep learning and bioinformatics."
      }
    ]
  },
  {
    title: "Teaching AI to Healthcare Professionals",
    content: [
      {
        title: "Artificial Intelligence in Undergraduate Medical Education: A Scoping Review",
        url: "https://pubmed.ncbi.nlm.nih.gov/34348374/",
        description: "This paper covers the current state of AI education in medicine",
      },
      {
        title: "What do medical students actually need to know about artificial intelligence?",
        url: "https://www.nature.com/articles/s41746-020-0294-7",
        description: "This review discusses important principles to consider when teaching healthcare professionals about AI",
      }
    ]
  }
];