import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import EmailIcon from '@mui/icons-material/Email'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import { TeamCardStyle } from '../styles'

const TeamCard = ({name, title, linkedin, email, image}) => {
    // renders 2 cards one for sm screens and other for larger screens
    return (
        <>
            <Card sx={TeamCardStyle.mobile.card.card} variant={TeamCardStyle.mobile.card.variant}>
                <Box sx={TeamCardStyle.mobile.icon.box}>
                    <img src={image} alt={name} style={TeamCardStyle.mobile.icon.icon} />
                </Box>
                <Box sx={TeamCardStyle.mobile.body.container}>
                    <CardContent sx={TeamCardStyle.mobile.body.meta}>
                        <Box sx={TeamCardStyle.mobile.body.text}>
                            <Typography {...TeamCardStyle.mobile.body.title} >
                                {name}
                            </Typography>
                        </Box>
                        <Typography {...TeamCardStyle.mobile.body.tcairemtitle.props} sx={TeamCardStyle.mobile.body.tcairemtitle.style}>
                            {title}
                        </Typography>
                        <Link href={`mailto:${email}`} {...TeamCardStyle.mobile.body.link.props} sx={TeamCardStyle.mobile.body.link.style}>
                            <EmailIcon />
                        </Link>
                        <Link href={linkedin} {...TeamCardStyle.mobile.body.link.props} sx={TeamCardStyle.mobile.body.link.style}>
                            <LinkedInIcon />
                        </Link>
                    </CardContent>
                </Box>
            </Card>
            <Card sx={TeamCardStyle.desktop.card.card} variant={TeamCardStyle.desktop.card.variant}>
                <Box sx={TeamCardStyle.desktop.icon.box}>
                    <img src={image} alt={name} style={TeamCardStyle.desktop.icon.icon} />
                </Box>
                <CardContent>
                    <Box sx={TeamCardStyle.desktop.body.text}>
                        <Typography {...TeamCardStyle.desktop.body.title.props} sx={TeamCardStyle.desktop.body.title.style}>
                        {name}
                        </Typography>
                    </Box>
                    <Typography {...TeamCardStyle.desktop.body.tcairemtitle.props} sx={TeamCardStyle.desktop.body.tcairemtitle.style}>
                        {title}
                    </Typography>
                </CardContent>
                <Box sx={TeamCardStyle.desktop.body.linkContainer}>
                    <Link href={`mailto:${email}`} {...TeamCardStyle.desktop.body.link.props} sx={TeamCardStyle.desktop.body.link.style}>
                        <EmailIcon />
                    </Link>
                    <Link href={linkedin} {...TeamCardStyle.desktop.body.link.props} sx={{...TeamCardStyle.desktop.body.link.style, marginRight: 0}}>
                        <LinkedInIcon />
                    </Link>
                </Box>
            </Card>
        </>
    )
}

export default TeamCard