import axios from 'axios'
import tokenService from './token'

const baseUrl = '/api/books/'

const retrieve = async (query='') => {
    const res = await axios.get(`${baseUrl}?${query}`, tokenService.getAuthHeader())
    return res.data
}

const retrieveSingle = async (id) => {
    const res = await axios.get(`${baseUrl}${id}/`, tokenService.getAuthHeader())
    return res.data
}

const create = async (obj) => {
    const res = await axios.post(baseUrl, obj, tokenService.getAuthHeader())
    return res.data
}

const update = async (id, obj) => {
    const res = await axios.put(`${baseUrl}${id}/`, obj, tokenService.getAuthHeader())
    return res.data
}

const destroy = async (id) => {
    const res = await axios.delete(`${baseUrl}${id}/`, tokenService.getAuthHeader())
    return res
}

export default { retrieve, retrieveSingle, create, update, destroy }