import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Loading from '../loading/Loading'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { AdminManage } from '../styles'

import { PAGES } from '../navigation/routes'
import { PAGE_SIZE } from '../websiteConfig'

import { setErrorNotification } from '../../reducers/notificationReducer'
import { setUserOnRefresh } from '../../reducers/userReducer'
import { initializeTags, createTag, deleteTag } from '../../reducers/tagsReducers'

const ManageTags = () => {
    const [filter, setFilter] = useState('')
    const [name, setName] = useState('')
    const [sort, setSort] = useState(1)
    const [tagsToShow, setTagsToShow] = useState(null)

    const tags = useSelector(state => state.tags)
    const [searchTags, setSearchTags] = useState(null)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const pageSize = PAGE_SIZE
    const [page, setPage] = useState(1)

    // force user update
    useEffect(() => {
        dispatch(setUserOnRefresh())
    }, [])

    useEffect(() => {
        if(tags) sortNames(sort)
    }, [tags])

    useEffect(() => {
        if(tagsToShow) setSearchTags(tagsToShow.filter(tag => tag.name.toLowerCase().includes(filter.toLowerCase())))
    }, [tagsToShow])

    // if user is not staff then force them out should not be here
    useEffect(() => {
        if(user) {
            if (user.staff) {
                dispatch(initializeTags())
            } else {
                dispatch(setErrorNotification('You are not authorized to view this page'))
                navigate(PAGES.home.path)
            }
        }
    }, [user])

    // reset fields to original values if user wants to
    const reset = () => setName('')

    const handleCreate = (event) => {
        event.preventDefault()
        const data = {
            name: name,
            slug: name.toLowerCase().replace(/ /g, '-')
        }

        dispatch(createTag(data, user.staff))
        reset()
    }

    const handleDelete = (tag) => dispatch(deleteTag(tag.id, user.staff))

    const sortNames = (type) => {
        const arr = [...tags]
        if(type) {
            setTagsToShow(arr.sort((a, b) => a.name.localeCompare(b.name)))
        } else {
            setTagsToShow(arr.sort((a, b) => -a.name.localeCompare(b.name)))
        }
        setSort(!type)
    }

    if(searchTags === null || tagsToShow === null) {
        return (
            <Container {...AdminManage.loading.props} sx={AdminManage.loading.style}>
                <Loading />
            </Container>
        )
    }

    return (
        <Container sx={AdminManage.container}>
            <Typography {...AdminManage.title} gutterBottom>
                {PAGES.manage_tags.title}
            </Typography>
            <Typography {...AdminManage.subheading} sx={AdminManage.subheadingMT} gutterBottom>Create Tag</Typography>
            <Box sx={AdminManage.tagForm}>
                <form onSubmit={handleCreate}>
                    <Grid container spacing={2} sx={{alignItems: 'center'}}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{width: '500px'}}
                                value={name}
                                onChange={e => setName(e.target.value)}
                                label="Tag"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button type='submit'  variant='contained' autoFocus>
                                Create Tag
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Typography {...AdminManage.subheading} gutterBottom>Search Tags</Typography>
            <Typography {...AdminManage.subheading2} gutterBottom>Filters</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        sx={{marginBottom: '20px'}}
                        fullWidth
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        label="Filter By Tag"
                    />
                </Grid>
            </Grid>
            <TableContainer sx={AdminManage.table} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <IconButton onClick={() => sortNames(sort)}>
                                    {!sort ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                </IconButton>
                                Name
                            </TableCell>
                            <TableCell {...AdminManage.columnAlign}>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchTags.slice((page-1)*pageSize, (page-1)*pageSize+pageSize).map(tag => (
                            <TableRow
                                key={`${tag.id}`}
                                sx={AdminManage.tableRow}
                            >
                                <TableCell {...AdminManage.tableCell.props} sx={AdminManage.tableCell.style}>
                                    {tag.name}
                                </TableCell>
                                <TableCell {...AdminManage.columnAlign}>
                                    <Button {...AdminManage.reject.props} sx={AdminManage.reject.style} onClick={()=>handleDelete(tag)}>
                                        Delete Tag
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination sx={AdminManage.pagination} count={Math.ceil(searchTags.length/pageSize)} page={page} onChange={(event, value) => setPage(value)} />
        </Container>
    )
}

export default ManageTags